import DETH_ABI from '../ABI/DETH_ABI.json';

export const network =
{
    ETH: {

        currencySymbol: 'ETH',
        tokenSymbol: 'WETH',
        tokenABI: { WETH: DETH_ABI },
        tokenAddr: { WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2".toLowerCase() },
        singleContract: "0x45aF8d34e9bd4bCd283A9dad54941A11fdb1d81E".toLowerCase(),
        multipleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),
        TradeContract: "0xc3b7719CF89af4700BA4c303186bF0222F6b9184",
        BNBPROVIDER: "https://mainnet.infura.io/v3/be5bdb23c61c4b92b174cb569e57f7b2",
        networkVersion: '1',
        chainId: '0x1',
        Chainid: 1,
        trade: ("0x1081E292e0Bda2446a2773C0549d2d1D6bCecFdD").toLowerCase(),
        multipleContract: "0x689B803Ae597A35CfDBe1f753E249857F99e05de".toLowerCase(),


        //              avax

        // currencySymbol: 'avax',
        // tokenSymbol: 'CAKE',
        // tokenAddr: { CAKE: "0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase() },
        // tokenABI: { CAKE: DETH_ABI },
        // TradeContract: "0xfAF6F127193c9527cCBd2254E9720C890B63d6A6".toLowerCase(),

        // singleContract: "0xe75FE03C07E45D9D7240A7E6ECEd71283f04d2A6".toLowerCase(),////////polygon
        // multipleContract: "0x5679b9b8f6Cef253Fc18507f5Fe5743f521A4Eae".toLowerCase(),////////polygon
        // BNBPROVIDER: "https://api.avax-test.network/ext/bc/C/rpc",
        // networkVersion: '43113',
        // chainId: '0x43113',
        // Chainid: 43113,
    },
    BSC: {



////////////////////////////binance/////////////////////////////

        // currencySymbol: 'BNB',
        // tokenSymbol: 'CAKE',
        // tokenAddr: { CAKE: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase() },
        // tokenABI: { CAKE: DETH_ABI },
        // TradeContract: "0xE7D77dD9F2511d814EE72817556B7C864C869f79".toLowerCase(),

        // singleContract: "0x8526A6853a6a6ef0a2f1B4d438a6Fc60Ac179971".toLowerCase(),////////polygon
        // multipleContract: "0xd48fa845B28f3587701834Cc3644daDD1935DCce".toLowerCase(),////////polygon
        // BNBPROVIDER: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        // networkVersion: '97',
        // chainId: '0x97',
        // Chainid: 97,

///////////////////////////avax(onlyy for mint testing)////////////////////////

// avax: {

// currencySymbol: 'avax',
//         tokenSymbol: 'CAKE',
//         tokenAddr: { CAKE: "0xeb4ffed6b324b5fdc4d72ad3da75bf1fb2bb004a".toLowerCase() },
//         tokenABI: { CAKE: DETH_ABI },
//         TradeContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),

//         singleContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),////////polygon
//         multipleContract: "0xc6E0aaf9973b441361c1fC049D8dFfB1394ff142".toLowerCase(),////////polygon
//         BNBPROVIDER: "https://api.avax-test.network/ext/bc/C/rpc",
//         networkVersion: '43113',
//         chainId: '0x43113',
//         Chainid: 43113,
     
    // }

//////////////////////new binance//////////////////

        currencySymbol: 'BNB',
        tokenSymbol: 'WBNB',
        tokenAddr: { WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c".toLowerCase() },
        tokenABI: { WBNB: DETH_ABI },
        TradeContract: "0xc3b7719CF89af4700BA4c303186bF0222F6b9184".toLowerCase(),
        singleContract: "0xCD3eBeC243dFC28A3918cD3DCA7Ef56A20426a9d".toLowerCase(),////////polygon
        multipleContract: "0x80dc293cd13F9d5f2f516A4BeaD02AF839c534b5".toLowerCase(),////////polygon
        BNBPROVIDER: "https://bsc-dataseed1.binance.org/",
        networkVersion: '56',
        chainId: '0x38',
        Chainid: 56,

        // BNBPROVIDER: "https://bsc-dataseed1.binance.org/",
        // networkVersion: '56',
        // chainId: '0x38',
        // Chainid: 56,
    }

}