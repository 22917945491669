import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams, useHistory } from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import {
  userNFTList, CollectiblesList_Home, getUserCollectiondata
} from '../actions/v1/token';
import HeaderLinks from "components/Header/HeaderLinks.js";
import Loader from './Loader'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import collectionimsg from "../assets/images/collections_02.png"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getAllNFTFromContract, addUserCollection } from '../actions/v1/user';
import LazyLoad from 'react-lazyload';
import LazyLoader from "./lazyloader";
// mysid
import TokenCard from '../views/separate/TokenCard'
import isEmpty from "lib/isEmpty";
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
import config from 'lib/config';
import { useSelector } from "react-redux";
import { network } from "./network"

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Ico(props) {

  const { ...rest } = props;
  const history = useHistory();
  var { param, customurl, collAddr } = useParams();
  const [CollectionInfo, setCollectionInfo] = useState([]);

  const Wallet_Details = useSelector(state => state.wallet_connect_context);




  const [showingLoader, setshowingLoader] = React.useState(true);
  const [disabled1, setdisabled1] = useState(false)

  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [CollectionList, Set_Collection_List] = useState([]);
  const [item, Set_item] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [Collectiondata, setCollectiondata] = useState([])
  const [NFTCount, setNFTCount] = useState("")
  const [collectionName, setCollectionName] = useState("")
  const [UserCollectionCount2, setUserCollectionCount2] = useState(0)
  const [owner, set_owner] = useState('');
  const [admin, set_admin] = useState('');
  const [CatName, setCatName] = useState('All');
  const [convertVal, setConvertVal] = React.useState(0);
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [cursor, set_cursor] = useState(null)
  const [UserCollectionCount, setUserCollectionCount] = useState(0)



  const onLoadMore = () => {

    Get_All_Nft_From_Contract({ collectionAddress: collAddr, limit: 10, cursor: cursor })
  }


  useEffect(() => {
    //console.log("DBFHdfbnh", param, customurl, collAddr);
    if (collAddr !== '') {
      Get_All_Nft_From_Contract({ collectionAddress: collAddr, limit: 10, cursor: null })
    }
    getInit();

  }, [])

  async function getInit() {
    TokenListCall();
    CollectionProfile()

  }

  async function TokenListCall() {
    var payload = {
      currAddr: param,
      customurl: customurl,
      from: 'collection'
    }
    // //console.log("TokenList call function", payload)
    var resp = await CollectiblesList_Home(payload);
    //console.log("After NFT", resp, resp.data);
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      //console.log("After NFT", resp.data);
      Set_Collection_List(resp.data.list)
    }
    else {
      Set_Collection_List([])
    }
  }



  async function CollectionProfile() {
    var payload = {
      userAddress: param,
      customurl: customurl
    }
    // //console.log("fgjnhfgjm",payload);
    var collectionInfo = await getUserCollectiondata(payload);
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {
      // //console.log("Collection Informations", collectionInfo);
      setCollectionInfo(collectionInfo.data.data.collections)
    }
  }

  const Get_All_Nft_From_Contract = async (data) => {

    setdisabled1(true)
    var allNft = await getAllNFTFromContract(data)
    //console.log("All Nft calling123", allNft)
    if (allNft && allNft.data && allNft.data.list) {
      setshowingLoader(false)
      setCollectiondata(Collectiondata.concat(allNft.data.list))
      if (allNft?.data?.data?.owner) {
        set_owner(allNft.data.data.owner)
        set_admin(allNft.data.data.admin)
        setNFTCount(allNft.data.data.NFTCount)
      }
      if (allNft && allNft.data && allNft.data.list[0] && allNft.data.list[0].title) {
        setCollectionName(allNft.data.list[0].title)
      }

      if (allNft?.data?.cursor)
        set_cursor(allNft.data.cursor)

      //console.log("alnfttttt", allNft);

      if (allNft?.data?.count && allNft?.data?.list != '') {
        setUserCollectionCount(Collectiondata.length + 10)
        setUserCollectionCount2(allNft.data.count)

      }

      //console.log("rthsrtjhtyj", Collectiondata.length + 10, allNft.data.count);
      setdisabled1(false)
    }
  }


  async function FindOwner(item) {
      if (Collectiondata && Collectiondata.length > 0) {
      var reqdata = {
        counts:item.tokenCounts,
        type:item.type,
        colladdr:item.colladdress,
        from: 'FindOwner',
        myAdd: Wallet_Details.UserAccountAddr

      }
      var tokenOwner = await addUserCollection(reqdata)
      if (tokenOwner?.message?.data?.owner) {
        var owner = (tokenOwner.message.data.owner).toString().toLowerCase()
        //("cgnjhdgfhjm",owner)
        var TokenOwner = owner.toLowerCase()

        const state = { 'amount': item?.amount, 'imgurl': item?.image, 'title': item?.title, 'name': item?.name, 'type': item?.type, 'from': 'userCollection', 'metfile': item.metadata, 'ipfshash': item.ipfshash , 'meta' : item.meta
      }
        const url = { 'pathname': "/info/" + TokenOwner + "/" + item?.colladdress + "/" + item?.tokenCounts}


        // pathname: "/info/" + owner + "/" + item?.colladdress + "/" + item?.tokenCounts, state: { 'amount': item?.amount, 'imgurl': item?.image, 'title': item?.title, 'name': item?.name, 'type': item?.type, 'from': 'userCollection', 'metfile': item.metadata, 'ipfshash': item.ipfshash , 'meta' : item.meta


        //console.log("SHGDFHhad", state, url)

        //  history.push("/info/" +TokenOwner+ "/" + collAddr  + "/" +counts )
        history.push(url, state,)

      }
      else {
        toast.warn('There are no owners in this collection');
      }


    }
  }







  return (
    showingLoader == true ?
      <Loader /> :
      <div>

        <div className="home_header">




          <Header
            color="transparent"
            routes={dashboardRoutes}
            brand={<Link to="/">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "white"
            }}
            {...rest}
          />

          <section className="navtabs pb-2 explore">

            <div className="items_bg mt-5">


              {!isEmpty(CollectionInfo) && CollectionInfo[0].coverimage ?
                <img src={`${config.Back_URL}/coverphoto/${param}/${CollectionInfo[0].coverimage}`} alt="profile" className="img-fluid" />
                :
                <img src={require("../assets/images/banner.jpg")} className="img-fluid" alt="logo" />
              }


            </div>
            <div className="container-fluid container-theme container-lg ico pb-5">
              <div className="d-flex align-items-center">

                <div className="d-block w-100 ico_border image_title text-center">
                  <div className="title m-0">

                    <div className="d-block responsinge">
                      <div className="image user_collect_img_tab">


                        {!isEmpty(CollectionInfo) && CollectionInfo[0].imageName ?
                          <img src={`${config.Back_URL}/collectionLogo/${param}/${CollectionInfo[0].imageName}`} className="user_image" alt="DitmaxEye" />
                          :
                          <img src={require("../assets/images/no_profile2.png")} className="img-fluid" alt="logo" />
                        }


                      </div>

                      {CollectionInfo && CollectionInfo.length > 0 ?
                        <>




                          {Wallet_Details.UserAccountAddr == CollectionInfo[0].userAddress ?
                            <button className='btn newbtn'>
                              <span class="gradbtn grad-bordbtn">

                                {/* <Link className="">View Profile</Link> */}
                                <Link className="" to={"/addCollectionss" + '/' + CollectionInfo[0].collectionName + "/" + "usercollection"}>Edit Collection</Link>


                              </span>
                            </button>
                            :
                            <button className='btn newbtn'>
                              <span class="gradbtn grad-bordbtn">

                                {/* <Link className=""to ={"/user" + '/' + param}>View Profile</Link> */}
                                <Link className="" to={"/user" + '/' + param}>View Profile</Link>


                              </span>
                            </button>}
                        </>
                        :
                        <p></p>
                      }
                      <div className="para ml-3">
                        {CollectionInfo && CollectionInfo.length > 0 ?
                          <p className="linear">{CollectionInfo[0].collectionName}</p> :
                          <p className="linear">no name</p>}
                        <Link to={"/user" + '/' + param} >
                          <p class="sub-linear">{param}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {console.log('slkfskfsfs',CollectionList?.length)}
                  <div className="no_of_nft ml-auto mt-xl-4 mt-lg-4 mt-3">
                    {(CollectionList && CollectionList?.length && (collAddr == network?.ETH?.singleContract || collAddr == network?.ETH?.multipleContract || collAddr == network?.BSC?.singleContract || collAddr == network?.BSC?.multipleContract)) ?
                      <p className="heading">No. of NFT'S:&emsp;{CollectionList.length}</p>
                      :
                      <p className="heading">No. of NFT'S:&emsp;{UserCollectionCount2}</p>

                    }
                  </div>
                </div>

              </div>
              <div className="tab-content explore_tab_content mt-0 pt-5" id="nav-tabContent">
                <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="mt-3">
                    <div className=" m-0">
                    {console.log('CollectionList',CollectionList,collAddr,Wallet_Details)}
                      {(Wallet_Details?.Allcontract?.length > 0 && !(Wallet_Details?.Allcontract?.includes(collAddr))) ?



                        <>

                          {(Collectiondata && Collectiondata.length > 0) ? (

                            <div className="row">
                              {

                                Collectiondata.map((item, index) => {

                                  return (
                                    (item?.image != '' && collAddr != network?.ETH?.singleContract && collAddr != network?.ETH?.multipleContract && collAddr != network?.BSC?.singleContract && collAddr != network?.BSC?.multipleContract) ?
                                      <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                        <div className="tokens">

                                          <div className="baner_image">
                                            <div className="ethactios">
                                              <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                                                {(item && item.image) &&
                                                  (((item.image).includes('ipfs://') == true || (item.image).includes('ipfs/') == true || (item.image).includes('ipfs:/') == true)
                                                    ? (

                                                      <object type="image/webp" data={"https://ipfs.io/ipfs/" + (((item.image).split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()} onClick={() => FindOwner(item)}
                                                      >
                                                      </object>

                                                    )
                                                    :

                                                    (item.image != "" &&

                                                      <object type="image/webp" data={item.image}
                                                        onClick={() => FindOwner(item)}

                                                      >
                                                      </object>

                                                    ))}
                                              </LazyLoad>
                                            </div>
                                            <div className="auctioncards">
                                              <div className="starreiaf">
                                                <h3 className="mb-3" onClick={() => FindOwner(item)}>{item.name}</h3>


                                              </div>


                                              <div className="abaucdetail">

                                                <div className="banercontentauction">
                                                  <img src={collectionimsg} />
                                                  <img src={collectionimsg} />
                                                  <div className="banercontentDet">
                                                    <h2>@{(item.colladdress).slice(0, 10).concat("....")}</h2>

                                                  </div>
                                                </div>

                                              </div>
                                            </div>

                                          </div>


                                        </div>
                                      </div>
                                      :
                                      ("")
                                  )
                                })
                              }

                            </div>

                          ) : ("")}

                          {
                            (UserCollectionCount < UserCollectionCount2 || UserCollectionCount == UserCollectionCount2) &&
                            <div className="loadmorebtndssec">
                              <Button className="loadmorebtnds" disabled={disabled1} onClick={() => onLoadMore()}>
                                Load More
                                {/* <i class="fa fa-spinner ml-2 spinner_icon spin_sm" aria-hidden="true"></i> */}
                              </Button>
                            </div>
                          }
                        </>

                        :
                        <div className="eploregrid">
                          <div className="row">
                            {/* token card */}
                            
                            {
                              (CollectionList
                                && CollectionList.length > 0)
                                ? (CollectionList.map((item) => {
                                  return (
                                    (isEmpty(item.tokenowners_current) !== true) ?


                                      <div className="col-lg-3 col-md-4 col-sm-6 pb-5">


                                        <TokenCard
                                          item={item}
                                          Set_item={Set_item}
                                          LikedTokenList={LikedTokenList}
                                          setLikedTokenList={setLikedTokenList}

                                          Set_Bids={Set_Bids}
                                          Bids={item.myBid}
                                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                          Set_MyTokenBalance={Set_MyTokenBalance}
                                          Set_MyTokenDetail={Set_MyTokenDetail}
                                          Set_AllowedQuantity={Set_AllowedQuantity}
                                          Set_YouWillPay={Set_YouWillPay}
                                          Set_YouWillPayFee={Set_YouWillPayFee}
                                          Set_YouWillGet={Set_YouWillGet}

                                          setConvertVal={setConvertVal}
                                          convertVal={convertVal}
                                        />



                                        {/* col-lg-3 col-md-6 col-sm-6 */}



                                      </div>
                                      : ("")
                                  )
                                })) : ('')
                              //    <div className="text-center py-5 col-12 notfound">
                              //    <div className="text-center py-3  no_items_row">
                              //      <p className="not_found_text">No items found</p>
                              //      <Link to ={`/explore/All`}>
                              //      <p className="not_found_text_sub">Come back soon or maybe Explore more here</p>
                              //      </Link>
                              //      <div className="mt-3">
                              //        {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                              //      </div>
                              //    </div>
                              //  </div>
                            }
                          </div>
                        </div>


                      }
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div className="ico-button m-auto text-center pb-4 ico_button">

            </div>
            {(CatBasedTokenList && CatBasedTokenList.loader === false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list.length >= 10 && CatBasedTokenList[CatName].onmore === true) ? (
              <div className="text-center mt-4 w-100 ">

                <div id="spinButs" className="overflow-hidden">
                  <Button className="loadmorebtnds" id="onmore" style={{ display: "flex" }} onClick={onLoadMore} >
                    Load More <span>

                    </span>

                  </Button>
                </div>
              </div>) : ('')}
          </section>


          <div id="logo_overlay" className="logo_ovelay">

            <Footer />
          </div>
        </div>
      </div>
  );
}
