import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState
} from 'react';
import Web3 from 'web3';
import config from '../../lib/config';
import DETH_ABI from '../../ABI/DETH_ABI.json';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import isEmpty from "../../lib/isEmpty";
import { Button } from '@material-ui/core';
import Convert from '../separate/Convert'
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import {
  BidApply_ApproveAction,
  acceptBId_Action,
  CancelBid_Action,
  checkOtherPlatformDetais1155,
  PurchaseNow_Complete_Action_Meta,
  PurchaseNow_Price_Action
} from '../../actions/v1/token';
import {
  getCurAddr,

  toFixedNumber
} from '../../actions/v1/user';
import Single from '../../ABI/userContract721.json'
import { toast } from 'react-toastify';
import axios from 'axios';
import { getReceipt } from '../../actions/v1/getReceiptFunc'
import Trade from '../../ABI/Trade.json'

import Select from 'react-select'
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import Market from "../../ABI/market"
toast.configure();
let toasterOption = config.toasterOption;
export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {

  const dispatch = useDispatch();
  const history = useHistory()

  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
  // console.log("ehfrguheidghefirfhgirewhf",NoOfToken_NeedToSend);
  const [accept_btn, Set_accept_btn] = React.useState("start");
  const [token_owner_detail, set_token_owner_detail] = useState({})
  const [CurBid_val_myuse, setCurBid_val_myuse] = useState({})
  const [YouWillGetWith, set_YouWillGetWith] = useState(0)
  const [YouWillPayTest, set_YouWillPayTest] = useState(0)
  const [ApprovalCallStatus, setApprovalCallStatus] = React.useState('init');
  const [MintCallStatus, setMintCallStatus] = React.useState('init');
  const [priceoption, Set_priceoption] = React.useState([]);
  const [NewTokenUSValue, setNewTokenUSValue] = React.useState(0);
  const [quantyputonsale, Setquantyputonsale] = useState(1)
  const [acceptValid, setAcceptValid] = useState({})
  const [CoinName, setCoinNames] = useState('');
  const [NewTokenAddress, setNewTokenAddresss] = React.useState('');
  const [NewTokenDecimal, setNewTokenDecimal] = React.useState(0);
  const [PurchaseBalance, Set_PurchaseBalance] = React.useState(0);
  const [SelectedNet, set_SelectedNet] = useState(0)
  // console.log("kahfjdgfjdbsfjkefsc",quantyputonsale);
  const [submitdisable, setsubmitdisable] = useState(true)
  const [ownerBalance, setownerBalance] = useState([])
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
      const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');

  var {
    Set_item,
    Set_tokenBidAmt,
    Set_NoOfToken,
    Set_ValidateError,
    Set_YouWillPay,
    Set_YouWillPayFee,
    Set_YouWillGet,
    Set_BidApply_ApproveCallStatus,
    Set_AccepBidSelect,
    item,
    Set_item,

    AccepBidSelect,
    tokenBidAmt,
    NoOfToken,
    ValidateError,
    YouWillPay,
    YouWillPayFee,
    YouWillGet,
    BidApply_ApproveCallStatus,
    AllowedQuantity,
    Bids,
    MyItemAccountAddr_Details
  } = props;
  const location = useLocation();
  var location_name = location.pathname;




  async function PriceCalculate_this(data = {}) {
    console.log("priceeeeeeeCalculation", NewTokenDecimal);

    // if(NewTokenDecimal!='' || NewTokenDecimal!= 0&& NewTokenAddress!='' || NewTokenAddress!= 0)
    // {
    // setsubmitdisable(false)
    // }
    // else{
    //   setsubmitdisable(true)
    // }

    var web3 = new Web3(Wallet_Details.providerss)
    var price = (typeof data.tokenBidAmt != 'undefined') ? data.tokenBidAmt : tokenBidAmt;
    var quantity = (typeof data.NoOfToken != 'undefined') ? data.NoOfToken : NoOfToken;
    if (price === '') { price = 0; }
    if (quantity === '') { quantity = 0; }
    if (isNaN(price) !== true && isNaN(quantity) !== true) {
      if (item.type === 721) {
        var totalPrice = price * 1000000;
      }
      else {
        var totalPrice = (price * 1000000) * quantity;
      }
      var toMid = totalPrice
      console.log("dsfgnjdgjdg", Wallet_Details.Service_Fee_buyer, config.decimalvalues, toMid)
      var serfee = (toMid) * ((Wallet_Details.Service_Fee_buyer / config.decimalvalues) * 1000000) / 100000000
      console.log("Sdrhsrth", serfee);
      var totfee = serfee + toMid
      var tot2cont = web3.utils.toWei(String(totfee))
      console.log("decimaaaaaaallll", NewTokenDecimal);
      if (NewTokenDecimal == 18 && NewTokenDecimal != 0) {
        Set_YouWillPay(web3.utils.toWei(String(Number(totfee) / 1000000)));
      }
      else if (NewTokenDecimal != 0) {
        ////("In deci other ",web3.utils.toWei(String(Number(totfee)/1000000)))
        Set_YouWillPay((Number(totfee) / 1000000) * (10 ** (NewTokenDecimal)));
      }






      // Set_YouWillPay(web3.utils.toWei(String(Number(totfee) / 1000000)));
      set_YouWillPayTest(web3.utils.toWei(String(Number(totfee) / 1000000)))
    }
    else {
      Set_YouWillPay(0);
    }
  }
  async function priceListFunction() {
    //console.log("POPup starting")
    window.$('#accept_price_item_modal').modal('hide');
    window.$('#accept_modal').modal('show');
  }
  const Validation_PlaceABid = (chk) => {
    // console.log("vgdfhbkjdfhvbkjfdh",chk);
    if (chk) {
      var ValidateError = {};
      if (NoOfToken === '') {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      }
      else if (isNaN(NoOfToken) === true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      }
      else if (NoOfToken === 0) {
        ValidateError.NoOfToken = '"Quantity" is required';
      }
      else if (NoOfToken > AllowedQuantity) {
        ValidateError.NoOfToken = '"Quantity" must be less than or equal to ' + AllowedQuantity;
      }
      // if(  quantyputonsale > ownerBalance)
      // {
      //   ValidateError.tokenBidAmt = '"Bid " must be less than'+ownerBalance
      // }

      if (tokenBidAmt === '') {
        ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
      }
      else if (isNaN(tokenBidAmt) === true) {
        ValidateError.tokenBidAmt = '"Bid amount" must be a number';
      }
      else if (tokenBidAmt === 0) {
        ValidateError.tokenBidAmt = '"Bid amount" is required';
      }

      else if (tokenBidAmt <= (Bids && Bids.pending && (Bids.pending).length > 0 && Bids.pending[0].tokenBidAmt)) {
        ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
      }
      // else if (tokenBidAmt < 0.009) {
      //   ValidateError.tokenBidAmt = 'Bid amount using wenlambo Must not be below 0.001';
      // }
      else if (item.minimumBid > tokenBidAmt) {
        ValidateError.tokenBidAmt = '"Bid amount" must be higher than or equal to ' + item.minimumBid;
      }
      else if (YouWillPay / 1e18 > Wallet_Details.Wen_Bln) {
        ValidateError.tokenBidAmt = 'Insufficient balance, Check your wallet balance';
      }
      else if (Wallet_Details.Wen_Bln === 0) {
        ValidateError.tokenBidAmt = 'Insufficient balance, Check your wallet balance';
      }
      else if (YouWillPayTest === 0) {
        ValidateError.tokenBidAmt = 'Must be 2 digits required after decimal point';
      }
      Set_ValidateError(ValidateError);
      return ValidateError;
    }
  }





  async function BalanceCalculation(Coin) {

    console.log("balanceeeeeeeeeeeeeeeee");

    if (Wallet_Details.providerss) {
      try {
        //console("Balance Calculation")
        var web3 = new Web3(Wallet_Details.providerss)
        if (
          web3
        ) {
          console.log("dhdrtderhs", Wallet_Details.networkConfiguration.singleContract, Wallet_Details.providerss, Coin);
          var web3 = new Web3(Wallet_Details.providerss);
         
          const MultiContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
         
          console.log("Contractdfhgsftjhstfrj Address1", Coin)
          var tokenAddress = await MultiContract.methods.getTokenAddress(Coin).call()
    console.log("Contractfgjgfdjd Address", tokenAddress, Wallet_Details.networkConfiguration.tokenABI.CAKE)
try {
  
console.log("GFnjhsfjxgh",Wallet_Details.networkConfiguration.tokenABI.CAKE);
          const TokenObj = new web3.eth.Contract(Wallet_Details.networkConfiguration.tokenABI.CAKE, tokenAddress);
          console.log("tokenObj", TokenObj);
        
          var TokenBalance = Number(await TokenObj.methods.balanceOf(Wallet_Details.UserAccountAddr).call())
          console.log("TokenBalance", TokenBalance);
          var TokenDecimal = Number(await TokenObj.methods.decimals().call())
      
          console.log("TokenBaldfhgdrhydrhance", TokenDecimal);
          Set_PurchaseBalance(TokenBalance / (10 ** (TokenDecimal)))
          console.log("Contract Address", TokenBalance, TokenDecimal, tokenAddress)
          setNewTokenDecimal(TokenDecimal)
          console.log("setNewTokenDecimal", TokenDecimal);
          setNewTokenAddresss(tokenAddress)
          console.log("tokenAddressssssssssss", tokenAddress);
        } catch (error) {
  console.log( "FDHbfgnswh",error );
        }

        }
      }
      catch (e) {
        console.log("fgmjdgfhmgfhdm",e);
        return false
      }

    }
  }






  const accepvalidation = () => {
    var acceptval = {}

    if (quantyputonsale > ownerBalance) {
      acceptval.msg = '"Available Quantity is " ' + ownerBalance;
      acceptval.status = false
      console.log("check cval ", acceptval)
      setAcceptValid(acceptval)
      return acceptval
    }

    if (quantyputonsale === '') {
      acceptval.msg = '"Quantity" is not allowed to be empty';
      acceptval.status = false
      console.log("check cval ", acceptval)
      setAcceptValid(acceptval)
      return acceptval
    }

    else if (quantyputonsale > CurBid_val_myuse.NoOfToken) {
      acceptval.msg = '"Quantity" not allowed to be greater than user bid quantity';
      acceptval.status = false
      console.log("check cval ", acceptval)
      setAcceptValid(acceptval)
      return acceptval
    } else {
      acceptval.msg = "";
      acceptval.status = true
      console.log("acceptval", acceptval);
      setAcceptValid(acceptval)
      return acceptval
    }

  }

  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      switch (e.target.name) {
        case 'tokenBidAmt':
          Set_tokenBidAmt(value);
          PriceCalculate_this({ tokenBidAmt: value });
          break;
        case 'NoOfToken':
          Set_NoOfToken(value);
          PriceCalculate_this({ NoOfToken: value });
          break;
      }
    }
  }

  async function FormSubmit_PlaceABid(e) {
    Set_BidformSubmit(true);
    var errors = await Validation_PlaceABid(true);
    var errorsSize = Object.keys(errors).length;
    if (errorsSize !== 0) {
      toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
      return false;
    }
    console.log("dfhgbzdfhszd");
    window.$('#place_bid_modal').modal('hide');
    window.$('#proceed_bid_modal').modal('show');
  }
  //console.log('>>>>>approvecall');
  async function ApproveCall() {
    if (Wallet_Details.providerss == null) {
      var receiptt = null;
      var handlee = null;
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    var currAddr = Wallet_Details.UserAccountAddr

    if (currAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    setApprovalCallStatus('processing');
    try {
      var MultiContract = new web3.eth.Contract(
        (props.item.type === 721 ? SINGLE : MULTIPLE),
        (props.item.contractAddress)
      );
      //console.log("props.item.type",props.item.type,props.item.contractAddress, config.trade,props.item.tokenOwner,MultiContract,web3)
      await MultiContract.methods.setApprovalForAll(
        Wallet_Details.networkConfiguration.TradeContract,
        true
      ).send({
        from: Wallet_Details.UserAccountAddr,
      }).on('transactionHash', async (transactionHash) => {
        if (transactionHash !== "") {
          handlee = setInterval(async () => {
            receiptt = await getReceipt(web3, transactionHash)
            clr();
          }, 8000)
        }
      })
    }
    catch (error) {
      toast.error("Approve failed", toasterOption);
      setApprovalCallStatus('tryagain');
    }
    async function clr() {
      if (receiptt != null) {
        clearInterval(handlee)
        toast.success("Approve Successfully", toasterOption);
        setApprovalCallStatus('done');
      }
    }
  }


  async function BidApply_ApproveCall() {
    if (!Wallet_Details.providerss) {
      toast.warning("OOPS!... connect Your Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    Set_BidApply_ApproveCallStatus('processing');
    var CoursetroContract = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
    var getAllowance = null;
    var ContractCall = null;
    var sendVal = null;
    var handle = null;
    var receipt = null;

    try {
   
      getAllowance = await CoursetroContract
        .methods
        .allowance(
          Wallet_Details.UserAccountAddr,
          Wallet_Details.networkConfiguration.TradeContract
        ).call()


      if (NewTokenDecimal == 18) {
        sendVal = web3.utils.toWei(String(Number(web3.utils.fromWei(String(YouWillPay))) + Number(web3.utils.fromWei(String(getAllowance)))))
      }
      else {

        var sendVal = (((Number(YouWillPay)) / 10 ** NewTokenDecimal) + ((Number(getAllowance)) / (10 ** (NewTokenDecimal)))) * 10 ** NewTokenDecimal

      }
      ContractCall = await CoursetroContract
        .methods
        .approve(
           Wallet_Details.networkConfiguration.TradeContract,
          //item.contractAddress,
          (String(sendVal))
        )
        .send({ from: Wallet_Details.UserAccountAddr })
        .on('transactionHash', async (transactionHash) => {
          handle = setInterval(async () => {
            receipt = await getReceipt(web3, transactionHash)
            clr1();
          }, 8000)
        })

    }
    catch (e) {
      toast.error("Approve failed", toasterOption);
      console.log("check bid test", e)
      Set_BidApply_ApproveCallStatus('tryagain');
    }
    async function clr1() {

      if (receipt != null) {
        clearInterval(handle);
        if (receipt.status === true) {
          var BidData = {
            tokenCounts: item.tokenCounts,
            tokenBidAddress: Wallet_Details.UserAccountAddr,
            tokenBidAmt: tokenBidAmt,
            NoOfToken: item.type === 721 ? 1 : NoOfToken,
            owner: token_owner_detail.tokenOwner,
            coinName: CoinName
          }

          var Resp = await BidApply_ApproveAction(BidData);
          if (Resp.data && Resp.data.type) {
            Set_BidApply_ApproveCallStatus('done');
            toast.success("Approve Successfully", toasterOption);
            // window.location.reload();
            setTimeout(() => {
              window.$('#proceed_bid_modal').modal('hide')
              history.push("/")
            }
            , 600);
            


          }
        }
      }

    }
    // await ContractCall
  }



  async function CancelBid_Proceed(curBid_val) {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress,
      owner: token_owner_detail.tokenOwner,
      coinName: token_owner_detail.CoinName
      // owner:item.tokenowners_current[0]&&item.tokenowners_current[0].tokenOwner

    }
    var Resp = await CancelBid_Action(payload);
    if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
      if (Resp.data.toast.type === 'error') {
        toast.error(Resp.data.toast.message, toasterOption);
      }
      else if (Resp.data.toast.type === 'success') {
        toast.success(Resp.data.toast.message, toasterOption);
      }
      setTimeout(() => {
        window.$('.modal').modal('hide')
        history.push("/")
      }, 600);

    }



    else {
      history.push("/")
    }
  }

  async function orderApprovecheck(item) {
    if (Wallet_Details.providerss == null) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    try {
      var MultiContract = new web3.eth.Contract(
        (item.type === 721 ? SINGLE : MULTIPLE),
        item.contractAddress
      )
      var status = await MultiContract.methods.isApprovedForAll(
        Wallet_Details.UserAccountAddr,
        Wallet_Details.networkConfiguration.TradeContract
      ).call();
      //console.log("OrderApprove Check", status);
      return status
    }
    catch (e) {
      console.log("OrderApprove Check", e);

      return false

    }

  }
  async function AcceptBid_Proceed() {
    console.log("dhstfrhsrth");
    var returnstate = accepvalidation()
    console.log("nkgfsdngojjroflgjole", acceptValid.status, returnstate.status);
    if (returnstate.status) {
      var curAddr = await getCurAddr();
      if (Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss);
        var CoursetroContract = null;
        var contractCall = null
        var handle = null;
        var receipt = null;
        var CHcekDethBlnOfBid = new web3.eth.Contract(DETH_ABI, NewTokenAddress);
        var getBlnofBidder = await CHcekDethBlnOfBid.methods.balanceOf(AccepBidSelect.tokenBidAddress).call();
        console.log("dsgvsdbgasdb", getBlnofBidder, NewTokenDecimal);

        if (NewTokenDecimal == 18) {
          var tokenBalance = getBlnofBidder / config.decimalvalues;
        }
        else {
          var tokenBalance = getBlnofBidder / 10 ** (18 - NewTokenDecimal)

        }


        var passAmt = (Number(YouWillPayFee) + Number(YouWillGet));
        var getAllowance = 0;



        getAllowance = await CHcekDethBlnOfBid.methods.allowance(AccepBidSelect.tokenBidAddress, Wallet_Details.networkConfiguration.TradeContract).call();

        if (Number(NewTokenDecimal) != 18) {
          var deci = 18 - Number(NewTokenDecimal);
          getAllowance = getAllowance * 10 ** deci;
        }

        console.log("hrerahsrth", getAllowance);
        if (Number(NewTokenDecimal) != 18) {
          var getAllowancecal = ((Number(getAllowance)) / (10 ** (NewTokenDecimal)))

        }
        else {
          var getAllowancecal = web3.utils.fromWei(String(getAllowance));

        }


        if (
          (tokenBalance) > (passAmt)
         
        ) {

          if (
            (Number(getAllowancecal)) >= (passAmt)
           
          ) {
            if (quantyputonsale) {
          
              
                //ids[0] - tokenId, ids[1] - amount, ids[2] -  nooftoken, ids[3] - nftType
                var ids = [item.tokenCounts, web3.utils.toWei(String(passAmt)), quantyputonsale, item.type]
                console.log("Dfhnzfgnjsxfg",  CoinName,
                AccepBidSelect.tokenBidAddress,
                ids,
                item.tokenowners_current[0].contractAddress);
                try {
                  Set_accept_btn('process')
                  CoursetroContract = new web3.eth.Contract(Market, Wallet_Details.networkConfiguration.TradeContract);
                  contractCall = await CoursetroContract
                    .methods
                    .acceptBId(
                      CoinName,
                      AccepBidSelect.tokenBidAddress,
                      ids,
                      item.tokenowners_current[0].contractAddress
                    )
                    .send({ from: Wallet_Details.UserAccountAddr })
                    .on('transactionHash', async (transactionHash) => {
                      handle = setInterval(async () => {
                        receipt = await getReceipt(web3, transactionHash)
                        clr1();
                      }, 8000)
                    })

                } catch (err) {
                  console.log("sdegvsadrbg", err);
                }
              

              async function clr1() {
                if (receipt != null) {
                  clearInterval(handle);
                  // alert(1)
                  if (receipt.status === true) {
                    if (((String(item.contractAddress).toLowerCase()) !== Wallet_Details.networkConfiguration.singleContract) ||
                      ((String(item.contractAddress).toLowerCase()) !== Wallet_Details.networkConfiguration.multipleContract)
                      //  ||
                      // ((String(item.contractAddress).toLowerCase()) !== config.old_singleContract) ||
                      // ((String(item.contractAddress).toLowerCase()) !== config.old_multipleContract)
                    ) {
                      var postMetaData = {
                        tokenOwner: Wallet_Details.UserAccountAddr, // old owner
                        tokenCounts: item.tokenCounts,
                        NoOfToken: item.type === 721 ? 1 : quantyputonsale,
                        contractAddress: item.contractAddress,
                      }
                      var resultt = await PurchaseNow_Complete_Action_Meta(postMetaData);
                    }
                    var acceptBId_Payload = {
                      tokenCounts: item.tokenCounts,
                      NoOfToken: quantyputonsale, //AccepBidSelect.NoOfToken,
                      tokenBidAddress: AccepBidSelect.tokenBidAddress,
                      UserAccountAddr_byaccepter: Wallet_Details.UserAccountAddr,
                      transactionHash: receipt.transactionHash,
                      owner: token_owner_detail.tokenOwner,
                      coinName: Wallet_Details.networkConfiguration.tokenSymbol,
                      USDvalue: NewTokenUSValue,
                      SelectedNetwork: Wallet_Details.networkConfiguration.Chainid

                    }
                    var Resp = await acceptBId_Action(acceptBId_Payload);

                    var postPrice = {
                      tokenOwner: curAddr, // old owner
                      UserAccountAddr: AccepBidSelect.tokenBidAddress, // new owner
                      tokenPrice: NewTokenUSValue
                    }
                    //console.log("kjgfbhkifjdhnvbkj", postPrice);
                    var Responce = await PurchaseNow_Price_Action(postPrice);
                    // console.log("kjgfbhkifjdhnvbkj",Responce,postPrice);

                    // if (item.clocktime != null && item.endclocktime != null) {
                    //   var nullTimeAUction = await null_time_auction(acceptBId_Payload);
                    // }
                    Set_accept_btn('done')
                    history.push("/my-items")
                    // }
                    setTimeout(() => window.$('.modal').modal('hide'), 600);
                  }
                }
              }
            }
          }
          else {
            toast.error("This Bidder Doesn't have enough allowance,Pleae try  another bidder")
            Set_accept_btn('error1')
          }
        }

        else {
          toast.error("This Bidder Doesn't have enough balance,Pleae try  another bidder")
          Set_accept_btn('error')
        }

      }
    }
    else {
      console.log("check false condn", acceptValid.status)
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      async PlaceABid_Click(item, itemCur, Bids) {
        console.log("DBHsdfbhsgfdb",Wallet_Details,Wallet_Details.UserAccountAddr);
        if (Wallet_Details.UserAccountAddr !== "" ||Wallet_Details.UserAccountAddr != null) {

        console.log("sdfrhbafebh", itemCur);
        var chainid = item.tokenowners_current[0].SelectedNetwork
        var accountDetailsss = ''
        console.log("dfbhsfdbn", item, Wallet_Details.networkConfiguration.Chainid, item.tokenowners_current[0].SelectedNetwork);
        console.log("dfhbsdfhbd", Wallet_Details.networkConfiguration.Chainid == item.tokenowners_current[0].SelectedNetwork);
        if (Wallet_Details.networkConfiguration.Chainid == item.tokenowners_current[0].SelectedNetwork) {
          accountDetailsss = true
        }
        else {
          console.log("zsdbgdzfhbdf", item.tokenowners_current[0].SelectedNetwork);
          if (item?.tokenowners_current[0]?.SelectedNetwork) {
            chainid = item.tokenowners_current[0].SelectedNetwork
          }
          console.log("sbhgsdrbhg", chainid);
          var accountDetailsss = await switchNetwork(chainid)
        }
console.log("gfnjdgjngh",accountDetailsss,chainid,Wallet_Details.networkConfiguration.Chainid);
        // setTimeout(async() => {
        console.log("XDBdafbsfd", chainid, Wallet_Details.networkConfiguration.Chainid, chainid === Wallet_Details.networkConfiguration.Chainid);
        if (chainid === Wallet_Details.networkConfiguration.Chainid) {
          console.log("sdgbdfbg");
          Set_item(item)
          setCoinNames(itemCur.CoinName)

          set_token_owner_detail(itemCur)
          ////////////console.log("uywtquyetwqtewq",item,itemCur)
         
            Set_BidformSubmit(false);
            ////console.log("BidsBids",Bids)
            if (Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
              // Set_Bids(Bids)

              Set_tokenBidAmt(Bids.myBid.tokenBidAmt);
              Set_NoOfToken(Bids.myBid.NoOfToken);

            }
            console.log("dhbdfgnh");
            window.$('#place_bid_modal').modal('show');
          }
          else {
            // ////////////console.log("callend place a bid")
            window.$('#place_bid_modal').modal('hide');
            window.$('#connect_modal').modal('show')
          }
        }
        // }, 1000);


      },
      async PriceCalculate(data = {}) {
        PriceCalculate_this(data);
      },
      async AcceptBid_Select(item, curBid_val) {
        if (Wallet_Details?.providerss ||Wallet_Details.UserAccountAddr != null) {

        var chainid = item.tokenowners_current[0].SelectedNetwork
        console.log("item, curBid_val", item, curBid_val);

        var currOwner = item.tokenowners_current.filter((item) => (item.tokenOwner) === (Wallet_Details.UserAccountAddr))
        setownerBalance(currOwner[0].balance)
        console.log("currOwner", currOwner[0].balance);

        var accountDetailsss = ''
        console.log("dfbhsfdbn", item, Wallet_Details.networkConfiguration.Chainid, item.tokenowners_current[0].SelectedNetwork);

        if (Wallet_Details.networkConfiguration.Chainid == item.tokenowners_current[0].SelectedNetwork) {
          accountDetailsss = true
        }
        else {
          console.log("item.tokenowners_current[0]", item.tokenowners_current[0].SelectedNetwork);
          if (item?.tokenowners_current[0]?.SelectedNetwork) {
            chainid = item.tokenowners_current[0].SelectedNetwork
          }
          console.log("sbhgsdrbhg", chainid);
          var accountDetailsss = await switchNetwork(chainid)
        }

        // setTimeout(async() => {

        if (chainid === Wallet_Details.networkConfiguration.Chainid) {
          setCurBid_val_myuse(curBid_val)
          setCoinNames(curBid_val.CoinName)
          console.log("sdafbhsdfb", curBid_val);
          // if (Wallet_Details.providerss) {
            var web3 = new Web3(Wallet_Details.providerss)
            var BuyOwnerDetail = {
              tokenOwner: Wallet_Details.UserAccountAddr,
              tokenCounts: curBid_val.tokenCounts,
              contractAddress: item.contractAddress
            }


            var tokenAdd = (Wallet_Details.tokenAddress).filter((item) => item.label === curBid_val.CoinName)
            console.log("SDgsdgdf", tokenAdd);
            setNewTokenAddresss(tokenAdd[0].Address)
            var data = {
              Singleaddress: Wallet_Details.networkConfiguration.singleContract,
              multipleContract: Wallet_Details.networkConfiguration.multipleContract
            }
            var balance = await checkOtherPlatformDetais1155(item, BuyOwnerDetail, item.type, web3, data);
            console.log('csxbalzxcvancexzc>>>>cvfbvf>>>fgnh>>', balance)
            if (balance === 0) {
              toast.warning("You won't buy at this moment please refresh you data", toasterOption);
              setTimeout(() => {
                history.push("/")
              }, 1000);
              return false;
            }
            else {
              // console.log("bidddingammount is commming",quantyputonsale);
              if (curBid_val && curBid_val.tokenBidAmt) {
                // window.$('#accept_modal').modal('show');
                // console.log("bidding quantity",curBid_val,MyTokenBalance,curBid_val.pending,curBid_val.tokenBidAmt);
                Set_AccepBidSelect(curBid_val);
                // if (item.contractAddress === Wallet_Details.networkConfiguration.singleContract ||
                //   item.contractAddress === Wallet_Details.networkConfiguration.multipleContract
             
                // ) {

                var check = await orderApprovecheck(item);
                  if (check) {
                    window.$('#accept_modal').modal('show');

                    setApprovalCallStatus('done')
                  }
                  else{
                    if(Wallet_Details.UserAccountAddr==="")
                    {
                  window.$('#accept_price_item_modal').modal('show');
                    }
                  }

                // }
                // else {
                //   //console.log("Item Checking",item)
                //   var check = await orderApprovecheck(item);
                //   if (check) {
                //     //console.log("Checking status123",check)
                //     setApprovalCallStatus('done')
                //   }
                //   //console.log("Checking status",check)
                //   window.$('#accept_price_item_modal').modal('show');
                // }

              }

            }

          }
          else {
            if(Wallet_Details.UserAccountAddr==="")
            {
            window.$('#connect_modal').modal('show')
            }
          }
        }


        // }, 1000);

      },
      async CancelBid_Select(curBid_val) {
        if (Wallet_Details.providerss) {
          if (
            curBid_val
            && curBid_val.pending > 0
            &&
            (
              curBid_val.status === 'pending'
              || curBid_val.status === 'partiallyCompleted'
            )
          ) {
            Set_AccepBidSelect(curBid_val);
            window.$('#cancel_modal').modal('show');
          }
          else {
            window.$('.modal').modal('hide')
          }
        }

        else {
          window.$('#connect_modal').modal('show')
        }
      }

    }),
  )




  async function switchNetwork(configdata) {
    var type = ""
    var networkConfiguration = {}
    if (configdata) {
      if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

        type = "MetaMask"
      }
      else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
        type = "WalletConnect"
      }

      console.log("connecttype............", type)
      var accountDetails = await connectWallet(type, configdata)
      console.log("accountDetailsin create page......................", accountDetails)
      //  const id=toast.loading("Wallet Connecting...")
      var web3 = new Web3(window.ethereum);
      console.log("dfghrtfh", web3);
      if (window.ethereum.isMetaMask == true) {
        const chainId = await web3.eth.getChainId();
        console.log("fghdtgj", chainId);

        if (chainId === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (chainId === network.BSC.Chainid) {
          networkConfiguration = network.BSC
        }


      }
      console.log("networkConfiguration", networkConfiguration);
      // setPriceoption()

      if (accountDetails != '' &&accountDetails?.web3) {
        console.log("dfbghdfb", accountDetails.web3);
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: accountDetails.accountAddress,
            UserAccountBal: accountDetails.coinBalance,
            WalletConnected: "true",
            Wen_Bln: accountDetails.tokenBalance,
            Accounts: accountDetails.accountAddress,
            providerss: accountDetails.web3._provider,
            networkConfiguration: networkConfiguration
          }
        })
      }
    }



    //   var ReqData = {
    //     addr: String(accountDetails.accountAddress).toLowerCase()
    // }
    // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
    //     return Resp.data.data.User
    // } else {
    //     return null
    // }





    return accountDetails
  }




  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    let formData = { ...quantyputonsale, ...{ [id]: value } }
    // console.log("dbhdfsgbnhfs", formData.putonsalequantity);
    Setquantyputonsale(formData.putonsalequantity)


    var totalAmt = 0;

    totalAmt = Number(formData.putonsalequantity) * (Number(CurBid_val_myuse.tokenBidAmt) * 1000000);
    var toMid = Number(totalAmt)
    var USDconvert = totalAmt / 1e6
    TokenValue(USDconvert, CurBid_val_myuse.coinname)
    // console.log("fgnjsfgmj", toMid, Wallet_Details.Service_Fee_seller, config.decimalvalues);
    var ServiceFee_val = (toMid) * ((Wallet_Details.Service_Fee_seller / config.decimalvalues) * 1000000) / 100000000
    // console.log("fgjsdftjd", ServiceFee_val);
    var YouWillGet_Val = (toMid - ServiceFee_val);
    // console.log("sgsdfhgd", YouWillGet_Val);
    Set_YouWillPayFee(ServiceFee_val / 1e6);
    Set_YouWillGet((YouWillGet_Val) / 1e6);
    var royalFee = (toMid) * ((item.tokenRoyality) * 1000000) / 100000000
    var YouWillGet_Val1 = ((toMid - (ServiceFee_val + royalFee)) / 1000000);
    // console.log("STHetrj", YouWillGet_Val1)
    set_YouWillGetWith(YouWillGet_Val1)
  }


  const TokenValue = async (price, coin) => {
    var curpri = toFixedNumber((Wallet_Details.networkConfiguration.tokenSymbol != Wallet_Details.networkConfiguration.currencySymbol ? Wallet_Details.tokenAddress.BiddingToken_USD * price : Wallet_Details.tokenAddress.XDC_Currency_USD * price))
    setNewTokenUSValue(Number(curpri))
  }


  const priceoptionfunc = (e) => {

    setCoinNames(e.label)

    //PriceCalculate_this({ tokenBidAmt: tokenBidAmt });
    //BalanceCalculation(e.label)
    //Set_FormSubmitLoading('start')
  };







  useEffect(() => {
    // console.log("dfszbhsfdgnh", CoinName);
    if (CoinName) {
      BalanceCalculation(CoinName)
    }
  }, [CoinName, Wallet_Details.UserAccountAddr])

  useEffect(() => {

    // console.log("dfrhbzsdfhzsd", Wallet_Details?.tokenAddress);
    if (Wallet_Details && Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.length > 0) {
      var bnbs = (Wallet_Details.tokenAddress).filter((item) => item.label != Wallet_Details?.networkConfiguration?.currencySymbol)
      // console.log("dghderyh", bnbs);
      Set_priceoption(bnbs)
    }

    Validation_PlaceABid(BidformSubmit);
  }, [
    tokenBidAmt,
    NoOfToken,
    quantyputonsale
  ])

  useEffect(() => {
    PriceCalculate_this({ tokenBidAmt: tokenBidAmt });
  }, [NewTokenDecimal, NewTokenAddress])


  return (
    <div>
      <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)} ></div>
      {/* place_bid Modal */}
      <div className="modal fade primary_modal" id="place_bid_modal" tabIndex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_modalLabel">{item.tokenName}</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2 "><span className="text_red mr-2">name</span>by
                <span className="text_red ml-2"
                  title={"Bidder : " + (!isEmpty(MyItemAccountAddr_Details) ? (MyItemAccountAddr_Details.name !== "" ? MyItemAccountAddr_Details.name : Wallet_Details.UserAccountAddr) : Wallet_Details.UserAccountAddr)} >
                  {!isEmpty(MyItemAccountAddr_Details)
                    && MyItemAccountAddr_Details.name !== ""
                    ? MyItemAccountAddr_Details.name
                    : String(Wallet_Details.UserAccountAddr).slice(0, 12).concat('....')}
                </span></p>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <form className="px-4 bid_form">
                <label htmlFor="bid">Your bid</label>
                <div className="input-group mb-3 input_grp_style_1 ">
                  <input
                    type="text"
                    maxLength=""
                    name="tokenBidAmt"
                    id="tokenBidAmt"
                    className="form-control"
                    placeholder="Enter your bid amount"
                    aria-label="bid"
                    aria-describedby="basic-addon2"
                    onChange={inputChange}
                  // value={tokenBidAmt}
                  />
                  {/* <div className="input-group-append"> */}
                  {/* <span className="input-group-text pl-0" id="basic-addon2">{Wallet_Details.networkConfiguration.tokenSymbol}</span> */}


                  {new Date(token_owner_detail?.endclocktime) < Date.now() ?
                    <div className="input-group-append">
                      <Select
                        className="yes1 form-control primary_inp select1 selxet_app"
                        id="basic-addon2"
                        name="coinname"

                        onChange={priceoptionfunc}
                        options={priceoption}
                        label="Select price"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </div>
                    :
                    <div class="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        {CoinName}
                      </span> </div>

                  }





                  {/* </div> */}
                </div>
                {ValidateError.tokenBidAmt && <span className="text-danger">{ValidateError.tokenBidAmt}</span>}
                {item.type === config.multipleType && <label htmlFor="qty">Enter quantity <span className="label_muted pl-2">( {AllowedQuantity} available)</span></label>}
                {item.type === config.multipleType && <div className="mb-3 input_grp_style_1">
                  <input
                    type="text"
                    name="NoOfToken"
                    id="NoOfToken"
                    className="form-control"
                    placeholder="Enter your bid quantity"
                    onChange={inputChange}
                  // value={NoOfToken}
                  />
                </div>}
                {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{Number(Wallet_Details.UserAccountBal / 1e18)} {Wallet_Details.networkConfiguration.currencySymbol}</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{PurchaseBalance} {CoinName}</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">{(Number(Wallet_Details.Service_Fee_buyer) + Number(0)) / config.decimalvalues}% <span>{CoinName}</span></p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">
                      <Convert
                        item={Number(YouWillPayTest) / 1e18}
                        coinName={Wallet_Details.networkConfiguration.tokenSymbol}
                        convertVal={1}
                      />

                    </p>
                  </div>
                </div>

                {(NewTokenDecimal != 0) &&
                  <div className="text-center">

                    {/*  data-bs-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}

                    <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()} >Place a bid</Button>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div className="modal fade primary_modal" id="proceed_bid_modal" tabIndex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button
                    className={"create_btn btn-block "}
                    disabled={(BidApply_ApproveCallStatus === 'processing' || BidApply_ApproveCallStatus === 'done')}
                    onClick={BidApply_ApproveCall}
                  >
                    {BidApply_ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {BidApply_ApproveCallStatus === 'init' && 'Approve'}
                    {BidApply_ApproveCallStatus === 'processing' && 'In-progress...'}
                    {BidApply_ApproveCallStatus === 'done' && 'Done'}
                    {BidApply_ApproveCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div className="modal fade primary_modal" id="accept_modal" tabIndex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content model_acceptbid">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Accept bid</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {
                  item && item.image &&
                  (String(item.image).split('.').pop() === "mp4" ||
                    (String(item.image).split('.').pop() === "webm") ||
                    (String(item.image).split('.').pop() === "WEBM") ||
                    (String(item.image).split('.').pop() === "ogv") ||
                    (String(item.image).split('.').pop() === "OGV")
                  )
                  &&
                  // <video src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" autoPlay controls playsInline loop />
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay controls playsInline loop
                    preload="auto"
                  // width="640"
                  // height="264"
                  // poster={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                  // data-setup="{}"
                  >

                    <source
                      src={item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                      //  src={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} 
                      type="video/mp4" />

                  </video>}


                {
                  item && item.image && item.image !== ""
                  && ((String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac")) &&
                  <>
                    {/* <img src={config.AudioImg}   className="img-fluid"/> */}

                    {

                      <>
                        <audio controls
                          muted
                          alt='audio'
                          className="img-fluid"
                          playsInline loop
                          type="audio/mp3"
                          autostart="off"
                          id="audio_play"
                          src={item.ipfsimage !== "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                        >
                        </audio>
                      </>
                    }
                  </>
                }
                {
                  item && item.image && item.image !== ""
                  && ((String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac")) &&

                  <img
                    src={item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                    // src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/compressedImage${item.tokenCreator}/${item.image}`} 
                    alt="Collections" className="img-fluid " />
                }
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold word_braek_txt_new pl-2">{AccepBidSelect.tokenBidAddress}</span>
              </p>
              <p className="info_title text-center">

                <Convert
                  item={AccepBidSelect.tokenBidAmt}
                  coinName={CoinName}
                  convertVal={1}
                />{CoinName} for {quantyputonsale} edition(s)</p>
{
//(CurBid_val_myuse?.NoOfToken >1)&&
              <div>
                <label className="primary_label" htmlFor="price_new">Quantity</label>
                <input type="text"
                  className="form-control primary_inp"
                  id="putonsalequantity"
                  placeholder="Enter your quantity"
                  onChange={onChange}
                />
                {acceptValid.msg && <span className="text-danger">{acceptValid.msg}</span>}
              </div>
              }
              <div className="row mx-0 pb-3 pt-2">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee in % </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">{(Number(Wallet_Details.Service_Fee_seller)) / config.decimalvalues}%</p>
                </div>
              </div>

              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Royalty fee in %

                  </p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {item.tokenRoyality} %

                  </p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">
                    {/* <Convert
                      item={(YouWillGetWith)}
                      coinName={CoinName}
                      convertVal={1}
                    /> */} {YouWillGetWith}
                    {CoinName}</p>
                </div>
              </div>
              <form className="">
                <div className="text-center">

                  <Button className="primary_btn btn-block"
                    onClick={(accept_btn === "start" || accept_btn === "try") && AcceptBid_Proceed}
                    disabled={(accept_btn === "process" || accept_btn === "done")}
                  >{accept_btn === "start" && 'Accept Bid'}
                    {accept_btn === "try" && 'Try Again'}
                    {accept_btn === "process" && 'In-Progress'}
                    {accept_btn === "done" && 'Done'}
                    {accept_btn === "error" && 'Something went wrong'}
                    {accept_btn === "error1" && "Bidder doesn't have enough allowance"}
                  </Button>

                  <Button className="primary_btn btn-block btn_outline_red"
                    disabled={(accept_btn === "process")}

                     data-bs-dismiss="modal" aria-label="Close">CANCEL</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      {/* accept bid Modal */}
      <div className="modal fade primary_modal" id="cancel_modal" tabIndex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Cancel bid</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="img_accept text-center">
                {
                  item && item.image &&
                  (String(item.image).split('.').pop() === "mp4" ||
                    (String(item.image).split('.').pop() === "webm") ||
                    (String(item.image).split('.').pop() === "WEBM") ||
                    (String(item.image).split('.').pop() === "ogv") ||
                    (String(item.image).split('.').pop() === "OGV")
                  )
                  &&
                  // <video src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" autoPlay controls playsInline loop />
                  <video
                    id="my-video"
                    className="img-fluid"
                    autoPlay controls playsInline loop
                    preload="auto"
                  // width="640"
                  // height="264"
                  // poster={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                  // data-setup="{}"
                  >

                    <source
                      src={item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                      //  src={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} 
                      type="video/mp4" />

                  </video>}

                {
                  item && item.image && item.image !== ""
                  && ((String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac")) &&
                  <>
                    {/* <img src={config.AudioImg}   className="img-fluid"/> */}

                    {

                      <>
                        <audio controls
                          muted
                          alt='audio'
                          className="img-fluid"
                          playsInline loop
                          type="audio/mp3"
                          autostart="off"
                          id="audio_play"
                          src={item.ipfsimage !== "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                        >
                        </audio>
                      </>
                    }
                  </>
                }
                {
                  item && item.image && item.image !== ""
                  && ((String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac")) &&

                  <img
                    src={item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                    // src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/compressedImage${item.tokenCreator}/${item.image}`} 
                    alt="Collections" className="img-fluid " />
                }
              </div>
              <p className="text-center accept_desc p-3">
                <span className="buy_desc_sm">You are about to cancel bid for</span>
                <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
              </p>
              <p className="info_title text-center">
                <Convert
                  item={AccepBidSelect.tokenBidAmt}
                  coinName={CoinName}
                  convertVal={1} />{CoinName} for 1 edition(s)</p>
              <form className="px-4">
                <div className="text-center">
                  <Button className="primary_btn btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>Cancel bid</Button>

                  <Button className="primary_btn btn-block btn_outline_red"  data-bs-dismiss="modal" aria-label="Close">CANCEL</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

      <div className="modal fade primary_modal" id="accept_price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>

              <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">

                  {ApprovalCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {ApprovalCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {ApprovalCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {ApprovalCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                    disabled={(ApprovalCallStatus === 'processing' || ApprovalCallStatus === 'done')}
                    onClick={ApproveCall}
                  >  {ApprovalCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApprovalCallStatus === 'init' && 'Approve'}
                    {ApprovalCallStatus === 'processing' && 'In-progress...'}
                    {ApprovalCallStatus === 'done' && 'Done'}
                    {ApprovalCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>
                <div className="media approve_media">
                  {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Accept the Bid</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className={"primary_btn btn-block"}
                    disabled={(ApprovalCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done')}
                    onClick={priceListFunction}
                  >

                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
export default PlaceAndAcceptBidRef;
