import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Howtocreate(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">How to Mint NFT's</h2>

              </div>
             
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div className="accordion" id="accordionExample">
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Introduction</button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Creating (also known as Minting) your own NFT artwork, whether it be a GIF or an image, on
EdaFace Marketplace is a relatively straightforward process and doesn’t require extensive
knowledge of the crypto industry.
</p>
     
   

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Step-by-Step Process
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>The following are simple steps that you can take to create your NFTs on EdaFace Marketplace:</p>
    
      <div className="accordionparalist">
                    
                          <p><span>Step 1 : </span>&nbsp;Decide on which blockchain you want to create your NFTs. The two most common
blockchains are Ethereum Smart Chain (ESC) and Binance Smart Chain (BSC)</p>
                          <p>Ethereum smartchain has the largest NFT ecosystem but high charges (gas fees). Hence, it will be far
cheaper to mint your NFT via BSC. </p>
                          <p>Indeed, because of BSC cheap gas fees, speed and ease of use, many people are minting their NFTs
through it and it will soon overtake the ESC ecosystem in NFT minting volume.</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 2:</span>&nbsp;Open a Crypto Wallet</p>
                          <p>A good number of custodial and non-custodial wallets you can use are available. Common ones are
Metamask, Trust wallet, Binance Chain Wallet, MyEtherWallet, etc.</p>
                          <p>For guidance on how to set up your non-custodial crypto wallet</p>
</div>
      <div className="accordionparalist">
                      <p><span>Step 3:</span>&nbsp;Fund your wallet
</p>
                          <p>Depending on which blockchain to mint your NFT, you will need to have some amount of ETH or BNB
in your wallet to pay the blockchain gas fees.</p>
                          <p>Gas fees are generally more expensive on Ethereum blockchain than on BNB Smart chain, which may
be low as few cents.
</p>
                          
</div>
<div className="accordionparalist">
                      <p><span>Step 4:</span>&nbsp;Login to EdaFace NFT Marketplace</p>
                          <p>Go to the website of EdaFace NFT Marketplace and login:<span><a href=" www.nft.edaface.com"> www.nft.edaface.com</a></span>
</p>
                          <p>EdaFace Marketplace is one of the foremost NFT marketplaces on Ethereum blockchain and BNB
Smart Chain. 
</p>
                          <p>Transactions on the platform are incredibly fast, within seconds, and it is much cheaper; it costs
about 0.03BNB to create an NFT on BNB Smart chain.
</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 5:</span> Click on “Connect Wallet” on the EdaFace NFT Marketplace website and select your wallet. If
your wallet is open, it will be connected.
</p>
                          <p>What you have done is simply connecting your wallet to the EdaFace NFT Marketplace.</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 6:</span> Click on “Create” button. </p>
                         

                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 7:</span> Select the NFT format you want to create</p>
                      <p>There are three formats in which you can create your NFTs:
</p>
          <ul>
            <li>Single – for creating one NFT at a time</li>
            <li>Multiple – for creating multiple forms of the NFT</li>
            <li>Collectible – for creating collections of different NFT</li>
            </ul>               

                          
</div>
<div className="accordionparalist">
                      <p><span>Step 8:</span> Fill in your artwork details, which include artwork type, name, social media, portfolio link,
and brief introduction.</p>
</div>
<div className="accordionparalist">
                      <p><span>Step 9:</span> Upload the artwork image by clicking the + sign.</p>
</div>

<div className="accordionparalist">
                      <p><span>Step 10:</span> Check the agreement box.</p>
                      <p>Note the terms of service. If you agree with the terms, you can proceed. The terms, which are meant
to uphold originality, read this way:
</p>
<i>“I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the
artwork can be removed anytime if detected.” </i>
</div>
<div className="accordionparalist">
                      <p><span>Step 11:</span> Click “Mint” and approve the transaction.</p>
                      <p>Congrats, you have created your NFT on EdaFace Marketplace.</p>
</div>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
      Single Minting
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>In Single Minting, you can mint a single NFT each time. To do this, follow these steps:</p>

<div className="accordionparalist">

<p><span>Step 1:</span> Upload file
Supported Files are: JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4.
The maximum file size is 30 megabytes.
Note you can preview the uploaded file in the box provided.
To cancel and replace the uploaded file, simply upload a new file.</p>
</div>
<div className="accordionparalist">

<p><span>Step 2:</span> Select blockchain network for the minting
Supported blockchains are BNB Smartchain and Ethereum Smartchain</p>
</div>
<div className="accordionparalist">

<p><span>Step 3:</span> Put on Sale
Decide how you want to sell your minted NFT by turning on the button.

You will need to choose one of the three Sale Options:
<ul>
<li>Fixed Price
This means you will only receive one price offer from purchasers</li>

<li>Timed Auction
This means you will receive:
<ul>
<li>different bid price offers from purchasers</li> 
<li>a bid offer is valid at or above the Minimum Bid price set by the creator</li>
<li>a bid offer is valid within a specified time frame set by the creator.</li>
</ul>
The highest bid offer after the auction ends is the winning bid.
For more information, click: “Learn more how timed auctions work.”</li>


Unlimited Auction
This means there is no Minimum Bid and no Specified Duration for the auction to take place. As the minter of the NFT, you determine the Minimum Bid and the Specified Duration of the auction.
</ul>
</p>
</div>

<div className="accordionparalist">

<p><span>Step 4:</span> Unlock Once Purchased
Content will be unlocked after successful transaction.</p>
</div>
<div className="accordionparalist">

<p><span>Step 5:</span> Name your NFT</p>
</div>
<div className="accordionparalist">

<p><span>Step 6:</span> Description (Optional)
You have an option to give brief description of your NFT</p>
</div>
<div className="accordionparalist">

<p><span>Step 7:</span> Select Collection</p>
</div>
<div className="accordionparalist">

<p><span>Step 8:</span> Category
Select the category and the subcategory of your NFT. Note that EdaFace groups all NFTs into 3 categories – Art, Collectibles, and Gaming. Each of these three categories have a separate market arena.
Thus, EdaFace Marketplace has three arenas where you can display your minted NFT – Art, Collectibles, and Gaming.
Also, note that each of the three main Categories of NFT are further divided into Subcategory. This arrangement allows for buyers and users of the platform to easily identify and locate your NFT.</p>
</div>
<div className="accordionparalist">

<p><span>Step 9:</span> Royalty
The creator may choose to be receiving royalty for each sale of the NFT.
Note that you may choose not to receive royalty. As such, you type in 0% as royalty.</p>
</div>
<div className="accordionparalist">

<p><span>Step 10:</span> Check the Ownership Confirmation box
“I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.”</p>
</div>
<div className="accordionparalist">

<p><span>Step 11:</span> Check the agreement box 
I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA</p>
</div>
<div className="accordionparalist">

<p><span>Step 12:</span> Create Item
When you have filled in all the parameters, click on “Create Item.”
A page will pop out for you to click on 2 different buttons as follows:
<ul>
<li><span>Upload Meta Data</span>
By clicking on this button, you will be uploading the meta contents of your NFT to IPFS.
IFPS refers to Inter Planetary File System. This is where your NFT file will be stored for easy access.</li> 

<li><span>Upload files & Mint token</span>
This button allows you to mint the NFT on the selected blockchain. Note that the blockchain will request you to pay a gas fee for minting your NFT.
Note that EdaFace Marketplace does not charge any fee for minting.</li>
</ul>
Congratulations for minting your NFT. To view your minted NFT, click on “My Item.”</p>
</div>
































      </div>
    </div>
  </div>


  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
      UniMultiple Minting

      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Here, you can mint many copies of one NFT at once. To do this, follow these steps:</p>
    
      <div className="accordionparalist">
                    
                          <p><span>Step 1:</span> Upload file
Supported files are: JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4. 
The maximum file size is 30 megabytes.
Note you can preview the uploaded file in the box provided.
To cancel and replace the uploaded file, simply upload a new file.

</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 2:</span> Select blockchain network for the minting
Supported blockchains are BNB Smartchain and Ethereum Smartchain
</p>
</div>
      <div className="accordionparalist">
                      <p>
                      <span>Step 3:</span> Put on Sale
Decide how you want to sell your minted NFT by turning on the button.

You will need to choose one of the two Sale Options:
<ul>
<li> Fixed Price
This means you will only receive one price offer from purchasers.</li>

<li>Unlimited Auction
This means there is no Minimum Bid and no Specified Duration for the auction to take place. As the minter of the NFT, you determine the Minimum Bid and the Specified Duration of the auction.</li>
</ul>
</p>
                          
</div>
<div className="accordionparalist">
                      <p><span>Step 4:</span> Unlock Once Purchased
Content will be unlocked after successful transaction.

</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 5:</span> Name your NFT
</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 6:</span> Description (Optional)
You have an option to give brief description of your NFT
</p>
                         

                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 7:</span> Select Collection
</p>
                         

                          
</div>
<div className="accordionparalist">
                      <p><span>Step 8:</span> Category
Select the category and the subcategory of your NFT. Note that EdaFace groups all NFTs into 3 categories – Art, Collectibles, and Gaming. Each of these three categories have a separate market arena.
Thus, EdaFace Marketplace has three arenas where you can display your minted NFT – Art, Collectibles, and Gaming.
Also, note that each of the three main Categories of NFT are further divided into Subcategory. This arrangement allows for buyers and users of the platform to easily identify and locate your NFT.
</p>
</div>
<div className="accordionparalist">
                      <p><span>Step 9:</span> Royalty
The creator may choose to be receiving royalty for each sale of the NFT.
Note that you may choose not to receive royalty. As such, you type in 0% as royalty.
</p>
</div>

<div className="accordionparalist">
                      <p><span>Step 10:</span> Properties (Optional)
You may choose to provide in the properties of the NFT for the buyer to get to know more about it.
</p>
                      
</div>
<div className="accordionparalist">
                      <p><span>Step 11:</span> Number of copies
Specify the number of copies of the NFT you want to mint.
</p>
</div>



<div className="accordionparalist">
                      <p><span>Step 12:</span> Check the Ownership Confirmation box
“I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.”

</p>
</div>


<div className="accordionparalist">
                      <p><span>Step 13:</span> Check the agreement box 
I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA
</p>
</div>


<div className="accordionparalist">
                      <p><span>Step 14:</span> Create Item
When you have filled in all the parameters, click on “Create Item.”
A page will pop out for you to click on 2 different buttons as follows:
<ul>
<li><span>Upload Meta Data</span>
By clicking on this button, you will be uploading the meta contents of your NFT to IPFS.
IFPS refers to Inter Planetary File System. This is where your NFT file will be stored for easy access.</li> 

<li><span>Upload files & Mint token</span>
This button allows you to mint the NFT on the selected blockchain. Note that the blockchain will request you to pay a gas fee for minting your NFT.
Note that EdaFace Marketplace does not charge any fee for minting.</li>
</ul>
Congratulations for minting your NFT. To view your minted NFT, click on “My Item.”

</p>
</div>






      </div>
    </div>
  </div>


  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseThree">
      PolyMultiple Minting

      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Here you can mint different kinds of NFT at once. This is bulk minting, which is different for UniMultiple Minting. 
Do not get confused between UniMultiple and PolyMultiple Mintings. The difference is that at each time you can mint many copies of just one kind of NFT for UniMultiple Minting while you can mint two or more kinds of NFTs at the same time with PolyMultiple Minting.</p>
    <p>To mint NFT using PolyMultiple Minting, follow these steps:</p>
      <div className="accordionparalist">
                    
                          <p><span>Step 1:</span> Upload folder
The folder should contain at least two files. There is no limitation on the number of NFT files in a folder you want to upload.
Note you can preview the uploaded files by clicking on the uploaded folder.
To cancel and replace the uploaded folder, simply upload a new folder.
Four Options to Upload:
Note that you can upload your NFTs as a folder in any of the four different ways:
<ul>
<li><span>Image Option</span> 
Here, can upload the bulk of the folder of images. 
Supported image format is JPG, JPEG, PNG, WEBP, GIF</li>

<li><span>Audio Option</span>
Here you can upload the bulk of the folder that contains audio files. You will need to provide unique image folder along with the audio folder.
Supported audio format is MP3, FLAC, or AAC.</li>

<li><span>Video Option </span>
Here, you can the bulk of the folder of video files. There is no need for you to provide unique image folder along with video folder.
Supported video format is: MP4, OGV, or WEPM.</li>

<li><span>Layer-by-Layer Option</span></li>
</ul>
Here, you can upload the bulk of the image or audio files layer by layer. There are two sub categories:
<ul>
<span>Sub category1</span>
<li>You will need to upload layers of image files that will be combined randomly by the blockchain to generate different kinds of NFTs. 
For example, if you upload 50 layers of an image, the output generates combination or probability of the images. </li>

<span>Sub category 2</span> 
<li>You will need to upload folder of audio files along with folder that contains image files in layers. The number of audio files will be same number as image files. For example, if you upload 50 audio, you need to upload 50 unique images as well,</li>
</ul>
</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 2: </span>Type of Minting
Please, select either ERC 721 or ERC 1155.

</p>
</div>
      <div className="accordionparalist">
                      <p>
                      <span>Step 3:</span> Select blockchain network for the minting
Supported blockchains are BNB Smartchain and Ethereum Smartchain


</p>
                          
</div>
<div className="accordionparalist">
                      <p><span>Step 4:</span> Put on Sale

Decide how you want to sell your minted NFT by turning on the button.
You will need to choose one of the two Sale Options:
<ul>
<span> Fixed Price</span>
<li>This means you will only receive one price offer from purchasers.</li>

<span>Unlimited Auction</span>
<li>This means there is no Minimum Bid and no Specified Duration for the auction to take place. As the minter of the NFT, you determine the Minimum Bid and the Specified Duration of the auction.</li>
</ul>
</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 5:</span> Unlock Once Purchased

Content will be unlocked after successful transaction.

</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 6:</span> Name your NFT
</p>
                         

                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 7:</span> Description (Optional)
You have an option to give brief description of your NFT

</p>
                         

                          
</div>
<div className="accordionparalist">
                      <p><span>Step 8:</span> Select Collection.

</p>
</div>
<div className="accordionparalist">
                      <p><span>Step 9:</span> Category
Select the category and the subcategory of your NFT. Note that EdaFace groups all NFTs into 3 categories – Art, Collectibles, and Gaming. Each of these three categories have a separate market arena.
Thus, EdaFace Marketplace has three arenas where you can display your minted NFT – Art, Collectibles, and Gaming.
Also, note that each of the three main Categories of NFT are further divided into Subcategory. This arrangement allows for buyers and users of the platform to easily identify and locate your NFT.

</p>
</div>

<div className="accordionparalist">
                      <p><span>Step 10:</span> Royalty
The creator may choose to be receiving royalty for each sale of the NFT.
Note that you may choose not to receive royalty. As such, you type in 0% as royalty.

</p>
                      
</div>
<div className="accordionparalist">
                      <p><span>Step 11:</span> NFT Quantity
Please, indicate how many NFTs you are minting.

</p>
</div>



<div className="accordionparalist">
                      <p><span>Step 12:</span> Social Media Links (Optional)
Please, indicate your social media links.


</p>
</div>


<div className="accordionparalist">
                      <p><span>Step 13:</span> Check the Ownership Confirmation box
“I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.”

</p>
</div>


<div className="accordionparalist">
                      <p><span>Step 14:</span> Check the agreement box 
I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA

</p>
</div>


<div className="accordionparalist">
                      <p><span>Step 15:</span> Create Item
When you have filled in all the parameters, click on “Create Item.”
A page will pop out for you to click on 3 different buttons as follows:
<ul>
 <span>Create NFT</span>
<li>This will generate the various kinds of NFTs for minting.</li>

<span>IPFS Upload</span>

<li>IFPS refers to InterPlanetary File System. This is where your NFT file will be stored for easy access. </li>

<span>IPFS Upload – Mint NFT</span>
</ul>
This button allows you to mint the NFT on the selected blockchain. Note that the blockchain will request you to pay a gas fee for minting your NFT.
Note that EdaFace Marketplace does not charge any fee for minting.

Congratulations for minting your NFT. To view your minted NFT, click on “My Item.”



</p>
</div>

      </div>
    </div>
  </div>


  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseThree">
      Create Collection  
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>This is a platform where the user pools together his different NFTs to one account.
The advantage of collecting NFTs to one account is to increase market visibility and concretise user’s brand.
By dropping your collections on EdaFace Marketplace, your community can mint them. You will need to pay gas fees to deploy your smart contract and will need to deploy your metadata outside of EdaFace Marketplace.
Once you have multiple collections, you'll be able to see them here as well.


The creator can collect different kinds of NFT at once.

</p>
<p>To create a collection, follow these steps:</p>
    
      <div className="accordionparalist">
                    
                          <p><span>Step 1:</span> Start Collection
On EdaFace Marketplace, click on “Create” button and select Create Collection.

</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 2:</span> Collection Category

</p>
</div>
      <div className="accordionparalist">
                      <p>
                      <span>Step 3:</span> Enter the Collection Name

</p>
                          
</div>
<div className="accordionparalist">
                      <p><span>Step 4:</span> Collection Description (Optional)
This step is not available.


</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 5:</span> Enter your custom url.

</p>
                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 6:</span> Profile
Upload a Profile image

</p>
                         

                          
</div>
      <div className="accordionparalist">
                      <p><span>Step 7:</span> Upload a Cover image

</p>
                         

                          
</div>
<div className="accordionparalist">
                      <p><span>Step 8:</span> Logo Image (optional)
</p>
</div>
<div className="accordionparalist">
                      <p><span>Step 9:</span> Social Media Links (optional)
You can add social media connections and links related to your project.

</p>
</div>

<div className="accordionparalist">
                      <p><span>Step 10:</span> Check the Ownership Confirmation box
“I declare that this is an original artwork. I understand that no plagiarism is allowed, and that the artwork can be removed anytime if detected.”

</p>
                      
</div>
<div className="accordionparalist">
                      <p><span>Step 11:</span> Check the agreement box 
I have read and I agree to all the NFT Terms & Conditions and all legal requirements of EDA

</p>
</div>



<div className="accordionparalist">
                      <p><span>Step 12:</span> Create Item
When you have filled in all the parameters, click on “Add Collection.”


</p>
</div>
      </div>
    </div>
  </div>

</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
