import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Probabilitynft(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Profitability of NFT Investment</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div class="accordion" id="accordionExample">
  
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Introduction
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Indeed, the rise of NFTs is already birthing the third wave of millionaires in the Crypto Market.
However, to acquire wealth from an NFT, the NFT has to be profitable. </p>
   

      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Factors that Influence NFT Profitability
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>The profitability of an NFT depends on these three factors:</p>
      
   
<div className="accordionparalist">
                      <h2>Popularity</h2>
                          <p>The NFT or the creator of your NFT has to be popular or famous and have a worldwide brand that a
number of people know about.</p>
                          <p>You can understand why popular soccer players like Lionel Messi made millions from their NFT sale.</p>
                          
</div>
<div className="accordionparalist">
                      <h2>Scarcity</h2>
                          <p>The NFT has to be rare and limited in quantity. According to the economic law of demand and
supply, the scarcer an item is, the higher the price of the item. </p>
                          
                          <p>Thus, the rarer an NFT, the higher its market price, especially if it is an NFT that is also popular</p>
                          
                         
</div>
<div className="accordionparalist">
                      <h2>Use Case</h2>
                          <p>Any NFT that has a well-defined use case will undoubtedly become profitable. The present euphoria
associated with NFTs may die off as NFTs with more specific use cases appear more and more in the
Crypto Market.</p>
                       
                          <p>Therefore, don’t just buy any NFT and hope it will produce profits for you. Always check these three
criteria before you invest in any NFT.</p>
                          
                         
</div>

      </div>
    </div>
  </div>
 
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
