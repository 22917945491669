import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terminology(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">Some Terminologies of NFTs</h2>
              </div>
              <p className="text-center mt-3">You need to understand the following terminologies associated with NFTs:</p>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div className="accordion" id="accordionExample">
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Creator versus Owner</button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>A creator of an NFT is often the first owner of that NFT. However, once the NFT is sold, the
ownership right passes on to the buyer. </p>
      <p>In such situations, the creator ceases to be the owner of the NFT. If the buyer sells off the NFT to
another person, the ownership is transferred to the new buyer.</p>
   

      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      NFT Smart Contracts
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Besides the creator’s digital signature, an NFT can also have other terms and conditions built into it
by the creator; these inbuilt terms collectively form the NFT Smart Contracts. Such terms and
conditions often stipulate the requirements for transacting with the NFT. </p>
      <p>Notable terms and conditions built into NFTs are the royalties.</p>
      


      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
      Discontinual Royalty versus Perpetual Royalty
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>In the traditional financial system, once an author sells a copy of his book, he receives royalty for
that transaction and at the same time ceases to have any ownership claim of that body copy. </p>
      <p>This means if the buyer decides to resell the book, the author cannot claim any royalty from this
secondary sell. This is what EdaFace School of Cryptocurrencies refers to as Discontinual Royalty.
</p>
      <p>However, NFT royalties are evolving in an opposite manner to what is obtained in the traditional
financial system. Crafted into the NFT smart contracts are terms that allow the creator of the NFT to
keep receiving royalties from the NFT any time such NFT is sold, no matter how many times it is sold.
</p>
      <p>That is, once an NFT is created, the creator will enjoy royalties from the NFT throughout the lifetime
of the NFT. This is referred to as Perpetual Royalty. It is a new concept in the financial world, and it is
rapidly gaining acceptance in the Crypto Market.
</p>
      


      </div>
    </div>
  </div>
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
