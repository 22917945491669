import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
//import Config from "../../lib/config";
import DETH_ABI from '../../ABI/DETH_ABI'

import SINGLE from "../../ABI/SINGLE"
import { network } from "views/network";
import market from "../../ABI/market"


export const connectWallet = async (type,configchainid) => {

  var Config = await configChange(configchainid) 
//console.log("dsbgdtneh",type,configchainid);
  var accountDetails = {}
  var web3Obj = {}
  
  if (type == "MetaMask" || type == 'BinanceWallet' || type == 'Coinbase') {
    //console.log("dfbhsdfnhbdfgndjgfmndgh",type,configchainid);
    web3Obj = await MetamaskWallet(type,configchainid)
  }
  if( type == 'WalletConnect'){
     web3Obj = await WalletConnect(type,configchainid)
  }
  //console.log("web3Obj",web3Obj);
  if (web3Obj) {
    try {
      //console.log("accounts try");
      const accounts = await web3Obj?.eth?.getAccounts();
      //console.log("accounts",accounts);
      accountDetails.accountAddress = accounts[0].toString().toLowerCase();
      //console.log("accountDetails.accountAddress",accountDetails.accountAddress);
      try
      {
        //console.log("accountDetails.accountAddress",web3Obj.eth,accountDetails.accountAddress);

      accountDetails.coinBalance = await web3Obj.eth.getBalance(accountDetails.accountAddress);
      //console.log("DETH_ABI, Config.tokenAddr",DETH_ABI, Config.tokenAddr);
      var conObj = new web3Obj.eth.Contract(
        DETH_ABI, Config.tokenAddr[Config.tokenSymbol]
      )

      accountDetails.tokenBalance = await conObj.methods.balanceOf(accountDetails.accountAddress).call()
      //console.log("accountDetails.tokenBalance",accountDetails.tokenBalance);
      }catch(err)
      {
        //console.log("sdfbgadsgf",err);
      }
      accountDetails.web3 = web3Obj;
      //console.log("accountDetails..fgdrtfhjutedyjedt........",accountDetails.web3._provider);
      return accountDetails;
    }
    catch (e) {
      //console.log("errrrrrr",e);
      return accountDetails;
    }
  }

}
export const MetamaskWallet = async (type,configchainid,config) => {

//console.log("typeeeeeee",type,configchainid)
  var web3  
  var Config = config
  try {
    //console.log("dfghdfbhdsfhb",window ,type == 'MetaMask');
    if (window.ethereum && type == 'MetaMask') {
      web3 = new Web3(window.ethereum);
      //console.log("dfghrtfh",web3);
      if(window.ethereum.isMetaMask == true){
        const chainId = await web3.eth.getChainId();
        //console.log("accountDetails type id@che",chainId,configchainid)
        if (parseInt(chainId) != parseInt(configchainid)) {
          chainIdCheck(configchainid)
          localStorage.setItem('walletConnectType', type)
          return web3
        }
     
          await window.ethereum.enable().then(async () => {

            const accounts = await web3.eth.getAccounts();
            //console.log("accountssss",accounts);
            const account = accounts[0].toString().toLowerCase();
            //console.log("bfsdgbnsdfgndfgndghn",account,type);
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)

          }).catch((err)=>
          {
            //console.log("ghfnfghmjghjm",err);
          })
       
      }
      else{
        alert("Please Uninstall CoinbaseWallet or Connect to Coinbase")
        return false;
      }
    }
    else if(window.BinanceChain && type == 'BinanceWallet'){
      web3 = new Web3(window.BinanceChain);
      const chainId = await web3.eth.getChainId();
      ////console("accountDetails type id",chainId,web3)
      if (parseInt(chainId) != parseInt(Config.CHAIN_ID)) {
        chainIdCheck()
        return true
      }
        await window.BinanceChain.request({}).then(async () => {
          // User has allowed account access to DApp...
          const accounts = await web3.eth.getAccounts();
          const account = accounts[0].toString().toLowerCase();
          localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
        });
    }
    else if(window.ethereum && type == 'Coinbase'){
      web3 = new Web3(window.ethereum);
        const chainId = await web3.eth.getChainId();
        console("accountDetails type id",chainId,web3)
        if (parseInt(chainId) != parseInt(configchainid)) {
          chainIdCheck()
          return true
        }
          await window.ethereum.enable().then(async () => {
            // User has allowed account access to DApp...
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
          });
      
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      //console.log("hgfjhgdfgxdfg",Web3.utils.toHex(43113));
      const chainId = await web3.eth.getChainId();
      if (parseInt(chainId) != parseInt(configchainid)) {
        chainIdCheck()
        return true
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }
    // Non-DApp Browsers
    else {
      //alert('No Dapp Supported Wallet Found');
      ////console("No Dapp Supported Wallet Found")
    }
  } catch (e) {
    ////console("accountDetails type id1 last",e)
  }
  //console.log("web3................................",web3);
  return web3;

}



export const WalletConnect = async (type,configchainid) => {
  var web3
  //Create WalletConnect Provider
  ////console("Wallet connect");
  const provider = new WalletConnectProvider({
    rpc: {
      56: "https://bsc-dataseed1.ninicoin.io",
    },
    network: 'binance',
    chainId: 56,
  }
  );
  await provider.enable().then(function (error, result) {
   // //console('error: ' + error);
   // //console("accountInfo", result);
    
  })
    .catch(e => {
      //try again
    });
  web3 = new Web3(provider);
  const accounts = await web3.eth.getAccounts();
  ////console("Account : ", accounts[0]);
  const account = accounts[0].toString().toLowerCase();
  localStorage.setItem("accountInfo", account)
  localStorage.setItem('walletConnectType', type)
  ////console("accountInfo", account);
  // localStorage.setItem("provider",JSON.stringify(provider))
  return web3;
}


// async function connect_Wallet(type) {
//   if (type == 'wc') {
//     var provider = new WalletConnectProvider({
//       rpc: {
//         1: Config.BNBPROVIDER,
//         // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/"
//       },
//       network: 'mainnet',
//       chainId: 1,
//       // chainId: 97,
//     }
//     );
//     return provider;
//   }

//   else if (type == 'mt' || type == 'math' && window.ethereum.providers) {
//     try{
//       //console("szderhgsydrh", window.ethereum.providers)
//       var provider =  window.ethereum.providers.find((provider) => provider.isMetaMask);
//       }
//       catch(eror)
//       {
//         //console("incatch",eror)
//         var provider =  window.ethereum
//       }
//       return provider;	
//   }

// }

const configChange = async(configchainid)=>
{
  var Config ={}

  if(configchainid === network.ETH.Chainid)
  {
    Config=network.ETH
  }
  else if(configchainid === network.BSC.Chainid){
    Config=network.BSC
  }
  return Config
}


// const chainIdCheck = async (configchainid) => {

//   var Config = await configChange(configchainid) 

//   //console.log("inside chainIdCheck",configchainid);
//   // Check if MetaMask is installed
//   // MetaMask injects the global API into window.ethereum
//   //console.log("dfsbhfsgnj",configchainid);
//   const hexString = configchainid&&configchainid.toString(16);
//   //console.log("hexString","0x" + hexString);
//   if (window.ethereum) {
//     try {
//       // check if the chain to connect to is installed
// //console.log("ifffffffffff");

//       await window.ethereum.request({
//         method: 'wallet_switchEthereumChain',
//         params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
//       });
//       return true;
//     } catch (error) {
//       //console.log("error............",error);
//       // This error code indicates that the chain has not been added to MetaMask
//       // if it is not, then install it into the user MetaMask
//       if (error.code === 4902) {
//         try {
//           await window.ethereum.request({
//             method: 'wallet_addEthereumChain',
//             params: [
//               {
//                 chainId: "0x" + hexString,
//                 rpcUrl: Config.RPC_URL,
//               },
//             ],
//           });
//           return true;
//         } catch (addError) {
//           //console.log("err in nested catch",addError);
//           console.error(addError);
//         }
//       }
//       console.error(error);
//     }
//   } else {
//     // if no window.ethereum then MetaMask is not installed
//     //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
//     return false;

//   }
// }



const chainIdCheck = async (configchainid) => {

  var Config = await configChange(configchainid) 

  //console.log("inside chainIdCheck",configchainid);
  // Check if MetaMask is installed
  // MetaMask injects the global API into window.ethereum
  const hexString = configchainid&&configchainid.toString(16);
  //console.log("hexString","0x" + hexString);
  if (window.ethereum) {
    try {
      // check if the chain to connect to is installed
//console.log("ifffffffffff");

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x"+hexString }], // chainId must be in hexadecimal numbers
      });
      return true;
    } catch (error) {
      //console.log("error............",error);
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: "0x" + hexString,
                rpcUrl: Config.RPC_URL,
              },
            ],
          });
          return true;
        } catch (addError) {
          //console.log("err in nested catch",addError);
          console.error(addError);
        }
      }
      console.error(error);
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
    return false;

  }
}



export const getServiceFees = async (configchainid) => {
  //console.log("FDbdfb",configchainid);
  var Config = await configChange(configchainid) 
//console.log("Configgetservicefeee", Config.TradeContract);
  var rpcObj = new Web3(Config.BNBPROVIDER)

  
  if(rpcObj){
    try{
      var marketObj = new rpcObj.eth.Contract(
        market,
        Config.TradeContract
      );
      var servicefees = await marketObj.methods.getServiceFee().call()
      //console.log("servicefee",servicefees,servicefees[0],servicefees[1]);
     var buyerFee = servicefees[0]
     var  sellerFee = servicefees[1]
     var baseRoyalty = servicefees[2]

    var fee={
      buyerFee,sellerFee,baseRoyalty
    }
      return fee;
    }
    catch(e){
      //console("service fees catch blok running",e)
    }
  }
}
