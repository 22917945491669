import React,
{ useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button} from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
// myside

import { getSearchList } from "actions/v1/user";

import ConnectWallet from './separate/Connect-Wallet';

import { useSelector } from "react-redux";


import config from '../lib/config';
import isEmpty from "lib/isEmpty";
import TokenCard from "./separate/TokenCard"
import UserCard from './separate/UserCard'
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';


const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();

  const classes = useStyles();
  const { ...rest } = props;




  const Wallet_Details = useSelector(state=>state.wallet_connect_context);

  const [searchItem, setSearchItem] = useState([])
  const [UsersearchItem, setUserSearchItem] = useState([])
  const [LikedTokenList, setLikedTokenList] = useState([]);


  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
 
  const [Service_Fee, set_Service_Fee] = useState(0);
  
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});


  useEffect(() => {
    searchCall();
    //console.log("hmbmmihbbhjb",props.location.search);
  }, [props.location.search])

  const searchCall = async () => {
    if (isEmpty(props.location.search))
      return false;
    const search = props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const keyword = params.get('search')

    let postData = {
      keyword: keyword,
      limit: parseInt(config.limitMax)
    }
    var data = await getSearchList(postData);

    if (data && data.searchlist && data.searchlist.items && data.searchlist.items.list) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }
    // ////////console..log('searchlist',data.searchlist)
  }
  // const AfterWalletConnected = async () => {
  //   // ////////console..log('searchlist',data.searchlist)
  // }
  // async function GetUserBal() {
  //   await WalletForwardRef.current.GetUserBal();
  // }

 
  return (
    <div className="inner_header">
      


      <WalletRef
        ref={WalletForwardRef}
 
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}
        // UserAccountAddr={Wallet_Details.UserAccountAddr}
        // UserAccountBal={Wallet_Details.UserAccountBal}
        // Accounts={Wallet_Details.Accounts}
        //GetUserBal={GetUserBal}
      />
      <BurnRef
        ref={BurnForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}
        // UserAccountAddr={Wallet_Details.UserAccountAddr}
        // UserAccountBal={Wallet_Details.UserAccountBal}
        // Accounts={Wallet_Details.Accounts}
        //GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_UserAccountBal={Set_UserAccountBal}
        // Set_AddressUserDetails={Set_AddressUserDetails}
        // Set_Accounts={Set_Accounts}
        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
        Accounts={Wallet_Details.Accounts}
        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        //GetUserBal={GetUserBal}
        Service_Fee={Service_Fee}
        set_Service_Fee={set_Service_Fee}

      />

      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
      />
      <ReportNowRef

        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />




      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>

        <div className="container-fluid container-theme mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav items_tab_outer text-center">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2 text-center" id="nav-tab" role="tablist">
                <a className="nav-link active" id="items-tab" data-toggle="tab" href="#items" role="tab" aria-controls="all" aria-selected="true">
                    <span className="tab_head p-2">Items</span>
                  </a>

                 <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="following" aria-selected="false">
                    <span className="tab_head p-2">Users</span>
                  </a>
                  
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="items" role="tabpanel" aria-labelledby="items-tab">
                <div className="proposal_panel_overall">
                  {searchItem.length===0 ?
                    <div className="text-center py-5 ">
                      <p className="not_found_text">Loading</p>
                      <p className="not_found_text_sub">Please wait a moment</p>
                      <div className="mt-3">
                             {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}
                      
                      </div>
                    </div>
                    :
//                     <div className=" col-lg-3 col-md-4 col-sm-6 pb-5">
//                       {
//                         searchItem.map((item,index) => 
//                         {
//                           return(
//                             (isEmpty(item.tokenowners_current) !== true )?
//                             <div className="">
                     
                          //   <TokenCard
                          //   item={item}
                          //   Set_item={Set_item}
                          //   LikedTokenList={LikedTokenList}
                          // hitLike={LikeForwardRef.current&&LikeForwardRef.current.hitLike}     
                          //   PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          //   PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          //   PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          //   Set_Bids={Set_Bids}
                          //   Bids = {item.myBid}
                          //   Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          //   Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          //   Set_MyTokenBalance = {Set_MyTokenBalance}
                          //   Set_MyTokenDetail={Set_MyTokenDetail}
                          //   Set_AllowedQuantity={Set_AllowedQuantity}
                          //   Set_YouWillPay = {Set_YouWillPay}
                          //   Set_YouWillPayFee = {Set_YouWillPayFee}
                          //   Set_YouWillGet = {Set_YouWillGet}
                          //   // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                          //   Burn_Click={BurnForwardRef.current.Burn_Click}
                          //   CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          //   SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          //   ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          // />
//                            </div>
//  : ("")
//                           )}
//                       )}
                     
                    
//                     </div>




<div className=" m-0 ma_no_gap myitemcards">
<>
  <div className="row">

  {
         searchItem.map((item,index) => 
                                 {
                                   return(
                                     (isEmpty(item.tokenowners_current) !== true )?
      <div className="col-lg-3 col-md-4 col-sm-6">
   <TokenCard
                            item={item}
                            Set_item={Set_item}
                            LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current&&LikeForwardRef.current.hitLike}     
                            PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                            PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                            PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                            Set_Bids={Set_Bids}
                            Bids = {item.myBid}
                            Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                            Set_MyTokenBalance = {Set_MyTokenBalance}
                            Set_MyTokenDetail={Set_MyTokenDetail}
                            Set_AllowedQuantity={Set_AllowedQuantity}
                            Set_YouWillPay = {Set_YouWillPay}
                            Set_YouWillPayFee = {Set_YouWillPayFee}
                            Set_YouWillGet = {Set_YouWillGet}
                            // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                            Burn_Click={BurnForwardRef.current.Burn_Click}
                            CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                            SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                            ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          />
      </div>
       : ("")
       )
     })}
  </div>
</>

</div>






                  }
                  </div>
                </div>


                <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                <div className="proposal_panel_overall">
                  {UsersearchItem.length===0?
                    <div className="text-center py-5">
                      <p className="not_found_text">No users found</p>
                      <p className="not_found_text_sub">Please wait a moment</p>
                      <div className="mt-3">
                        <Button className="create_btn">Browse Marketplace</Button>
                      </div>
                    </div>:
                    <div className="followers_overall mt-5">
                      <div className="row">
                        {UsersearchItem.map((User)=>{return(
                        <div className="col-12 col-md-6 col-xl-4 mb-4">
                        <UserCard
                        User={User}
                        />
                         </div>
                        )})}
                      </div>
                    </div>}
                  </div>
                </div>
            
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
     </div>
  );
}
