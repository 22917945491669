
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import sales from "../../src/assets/images/sales.png";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ActivityCard from './separate/activityTab'
import { ActivityCall } from 'actions/v1/report'

import { useSelector } from "react-redux";


import config from 'lib/config'
import { Button } from "@material-ui/core";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'purchase': { page: 1, list: [], onmore: true } });
  const [CatName, setCatName] = useState('purchase');

  const [expanded, setExpanded] = React.useState('panel1');
  const [Activity_Item, Set_Activity_Item] = useState([])
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [load_more, Set_load_more] = useState({});
  // useEffect(() => {
  // }, [Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountBal,CatName])
  async function catChange(name) {
      setCatName(name);
  }
  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);
    ActivityTab({
      page: CatBasedTokenList[CatName].page + 1
    });
  }

  useEffect(() => {
    
    if (typeof CatBasedTokenList[CatName] === 'undefined') {
      //console.log("tested for category", CatBasedTokenList[CatName]);
      CatBasedTokenList[CatName] = { page: 1, list: [], onmore: true };
      
      setCatBasedTokenList(CatBasedTokenList);
      ActivityTab({ CatName: CatName, page: 1 });
    }
  }, [CatName])
  useEffect(() => {
   
      ActivityTab({ CatName: CatName, page: 1 });
  
  }, [])
  const ActivityTab = async (data) => {
   // //console.log("sohmhnjmgjhnmci1", data,CatName)
    var name = CatName
    var reqdata = {
      tabName: name,
      currAddr: Wallet_Details.UserAccountAddr,
      limit: 9,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
    }
   // //console.log("fdbhdgfbhfdgfefr",reqdata)

    var activitys = await ActivityCall(reqdata)
    // //console.log("AllActivitiefgjgfnjhs", activitys)
    if (activitys && activitys.data && activitys.data.list && (activitys.data.list).length > 0) {
      // //console.log("AllActivities", activitys.data.list)
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(activitys.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
      // setCatBasedTokenList(activitys.data.list)
      // Set_load_more(activitys.data)
    }
    else {
      Set_Activity_Item([])
    }

  }



  return (
    <div className="inner_header">



      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader activity"}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="">
                  <h2 className="inner_title">Activity</h2>
           
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container-fluid container-theme container-lg">
        <div className="customDrop">

            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="customDropdown">
                       
                      Filter</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 250 }}>
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >All</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >My Activity</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"  >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Mint</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid Accept</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Purchase</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> 
                                  
                                  <li className="px-3"   >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Put on sale</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Cancel price</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Token Like</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Follow</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                    
                        </div>
               
             
              
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                 
                      <div className="followers_overall py-3">
                     
                          {
                          
                              <ActivityCard
                                
                              />
                            
                          }


                       

                      </div>
                      

                  </div>
              

                </div>


              </div>
           
        </div>

        <div className="text-center mt-4 w-100 ">
               
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore}  style={{ display: "flex" }}>
                    Load More <span>
                    
                    </span>

                  </Button>
                </div>
              </div>
      </div>
      <Footer />
    </div>
  );
}







