import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Whatisnft(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">What is NFT ?</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
      Definition
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
    <p>NFT is an abbreviation for Non-Fungible Token. NFTs are digital objects that represent both tangible
and intangible items in the physical world.</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      The Characteristics of NFTs
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>To understand what an NFT is, consider the three characteristics of an NFT:</p>
      <div className="accordionparalist">
                          <h2> Digital Representation</h2>  
                          <p>NFTs are not just digital currencies like other cryptocurrencies, but are actually digital objects
created by blockchain technology that represent both tangible and intangible items in the physical
world.
</p>
</div>
<div className="accordionparalist">
                        <p>An Example of Non-fungibility
</p>
                          <p>If Phoster scores a spectacular goal in a world cup, he may decide to create 1000 digital pictures of
his great goal via a blockchain and market them to his numerous fans. 
</p>
                          <p>For these 1000 digital pictures to be regarded as NFTs, each of them must be digitally different even
though they may all look the same.
</p>
                          <p>What gives each of the 1000 pictures of Phoster’s world-class goal that uniqueness is the signature
that Phoster encodes in them. Each of the pictures carries a signature that is different from the
others. 
</p>
                          <p>Thus, you cannot just exchange one of those digital assets with another just as you can easily
exchange one dollar with another dollar or one Bitcoin with another Bitcoin. That means Phoster’s
1000 digital pictures are non-fungible.
</p>
</div>
<div className="accordionparalist">
                          <h2>Uniqueness</h2>  
                          <p>Physical money and other cryptocurrencies are “fungible,” meaning they can be traded or
exchanged for one another. They are also equal in value – one dollar is always worth another dollar;
one Bitcoin is always equal to another Bitcoin. 
</p>
                          <p>Crypto’s fungibility makes it a trusted means of conducting transactions on the blockchain.
</p>
                          <p>NFTs are different. Each NFT has a digital signature that makes it impossible for NFTs to be
exchanged for or be equal to one another. This character is referred to as non-fungible. 
</p>
</div>
<div className="accordionparalist">
                          <h2> Non-divisibility</h2>  
                          <p>Basically, an NFT cannot be divided into smaller pieces like other cryptos. For example, Bitcoin (BTC)
can be divided into 8 decimal places (0.00000001 BTC). The smallest unit of Bitcoin is called a
satoshi; thus, 1 satoshi = 0.00000001 BTC.
</p>
                          <p>Likewise, Ethereum (ETH), the second largest crypto by market capitalisation, can be divided into 18
decimal places (the smallest unit 1 wei = 10-18 ETH). Also, Eda Token (EDA) can be divided into 18
decimal places.
</p>
                          <p>But not so with NFTs. Each NFT is a completely non-divisible asset.
</p>
</div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Ownership Fractionalisation
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Despite that an NFT is not divisible, several people can come together and pay for a certain NFT. This
is referred to as Ownership Fractionalisation.
</p>
      <p>In other words, an NFT can have joint ownership, and any profits realised from trading such NFT are
shared among the joint owners in proportion to the size of their ownership right.
</p>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
