import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function nfttc(props) {
  // const classes = useStyles();
  const { ...rest } = props;
  // const [expanded, setExpanded] = React.useState('panel100');
  // const [faqlist,setfalist]=useState ([])
  // const handleChangeFaq = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  // useEffect(()=>{
  //   faqlistfunc()
  // },[])
  // const faqlistfunc=async()=>{
  //   var fql=await faqlists()
  //   //console.log("ksaldjjsadkls",fql.data.soci)
  //   if(fql&&fql.data&&fql.data.soci){
  //     setfalist(fql.data.soci)
  //   }
  // }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className="inner_pageheader faq">
        <div className="bg_red_1">
          <div className="container-fluid container-theme container-lg">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center">
                  <h2 className="inner_title">NFT Terms & Conditions</h2>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container-fluid container-theme container-lg mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} className="m-auto">
              <div className="faq_panel">
                      <Accordion className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>1.0 Acceptance and Modifications of NFT Terms</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>Welcome to EdaFace NFT Marketplace owned and operated by Emerging Digital Age (Pty) Ltd (“EDA,” the
                    “Company”, “we”, “us”, or “our”), a digital asset company, duly incorporated under the South African
                    Companies Act with registration number: 2021/818038/07.</p>
                            <p>These Terms and Conditions (“NFT Terms”) constitute a legally binding agreement between the Company
                        and each registered or unregistered end user (each, a “User”, “you” or “your”) of the EdaFace NFT
                        Marketplace located at nft.edaface.com or such other URL as may be designated by the Company from
                        time to time, as well as any mobile apps or other related services or applications thereto (collectively, the
                        “EdaFace NFT Marketplace”).</p>
                        <p>EdaFace NFT Marketplace is considered part of EdaFace (“EDA Services”) as defined in the Terms of
                        Use, Disclaimers, Listing Terms and Conditions, Privacy Policy, and all legal requirements of EDA (“EDA
                        Legals”). Therefore, you are encouraged to carefully study EDA Legals, which are deemed an integral part of
                        these NFT Terms and shall have the same legal effect. Your use of EDA Services including Eda Token is
                        deemed your acceptance of these supplementary terms.</p>
                        <p>By accessing and using EdaFace NFT Marketplace (including by purchasing or bidding on any items herein),
                            you are deemed to have read, accepted, executed, and agreed to be bound by these NFT Terms (including
                            the EDA Legals). We may change or amend EdaFace NFT Marketplace or these NFT Terms and EDA Legals
                            at any time at our sole and absolute discretion. Any changes to these NFT Terms will be in effect as of the
                            “Last Revised” date referred to at the top of this page. You acknowledge and agree that the form and
                            nature of EdaFace NFT Marketplace, and any part of it, may change from time to time without prior notice
                            to you, and that we may add new or remove existing features and change any part of EdaFace NFT
                            Marketplace.</p>
                            <p>IF ANY PROVISION OF THESE NFT TERMS OR THE EDA LEGALS OR ANY FUTURE CHANGES ARE
                            UNACCEPTABLE TO YOU, DO NOT USE OR CONTINUE TO USE EDAFACE NFT MARKETPLACE. YOUR
                            CONTINUED USE OF EDAFACE NFT MARKETPLACE FOLLOWING THE POSTING OF ANY NOTICE OF ANY
                            CHANGE TO THESE TERMS OFSERVICE SHALL CONSTITUTE YOUR ACCEPTANCE AND AGREEMENT TO SUCH
                            CHANGE.</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-5"> 
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>2.0 Overview of EdaFace NFT Marketplace</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>EdaFace NFT Marketplace provides you with the opportunity to create (or as we call it, mint), sell, purchase, bid
on, collect, trade, showcase and otherwise transact digital blockchain art, gaming, and collectible non-fungible
tokens (“NFT”) that may represent or be linked with certain digital media and art (“NFT Media”) or represent
or be linked with certain tangible or intangible items in the physical world (“NFT Media”).</p>
                            <p>We facilitate transactions between buyer and seller of an NFT, but we are not a party to any agreement between
buyer and seller of an NFT on EdaFace NFT Marketplace. We collect revenue on EdaFace NFT Marketplace via

Page 2 of 9
transaction fees and other applicable fees which we display when you interact with EdaFace NFT Marketplace.
For the avoidance of doubt, NFTs transacted on EdaFace NFT Marketplace are considered “Digital Assets” as
defined in the EDA Legals. The Company reserves the right to (but is not required or obligated to be) take any
action in relation to any disputes arising from purchases via EdaFace NFT Marketplace, including in
connection with any auctions or other purchase methods.</p>
                                <h3>2.1 For NFT Sellers:</h3>
                                <p>By minting, providing, or selling an NFT through EdaFace NFT Marketplace, you hereby represent and
                                    warrant that you own all legal right, title, and interest in all intellectual property rights to the NFT Media linked
                                    or associated with such NFT, or you are legally authorized by the intellectual property owner to mint, provide or
                                    sell the NFT on EdaFace NFT Marketplace. Except for NFTs minted on EdaFace NFT Marketplace, in order to list
                                    any NFT for sale on EdaFace NFT Marketplace, you must first deposit the NFT for sale for custody with the
                                    Company until such time as the NFT is sold or you decide to remove the NFT from EdaFace NFT Marketplace. For
                                    clarity, the Company has no obligation or liability to you for keeping, storing, or helping you recover any NFT
                                    Media associated with your NFTs.</p>
                                <h3>2.2 For NFT Buyers:</h3>
                                <p>When you purchase an NFT, you own the NFT that is associated with certain NFT Media, but you do not own
                                any intellectual property rights in such NFT Media except for the license grants expressly set forth herein. In
                                certain cases, we may help to evaluate or provide you with information about a seller of an NFT. However, such
                                information is provided for informational purposes only. You bear full responsibility for verifying the
                                authenticity, legitimacy, and identity of any NFT you purchase on EdaFace NFT Marketplace. We make no
                                representations, guarantees, or promises about the identity, legitimacy, legality, decency, quality, or
                                authenticity of any NFT on EdaFace NFT Marketplace. Notwithstanding any sale clearing period that may be
                                implemented for the sale of any NFTs, you acknowledge that the Company is notrequired or obligated to
                                adjudicate or decide on any disputes in connection with any NFTs sold on EdaFace NFT Marketplace.</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion className="mt-5">                  
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>3.0 License to Your Content</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>In connection with your use of EdaFace NFT Marketplace, you may be able to post, upload, or submit content to
be made available through EdaFace NFT Marketplace, including NFT Media that is tied to NFTs you wish to sell
on EdaFace NFT Marketplace as a seller, and any other content associated with your NFTs (“Your Content”).
You retain all rights to Your Content you post, upload, submit, or otherwise make available through EdaFace NFT
Marketplace, except for rights expressly granted herein. In order to operate EdaFace NFT Marketplace, we
must obtain from you certain license rights in Your Content so that actions we take in operating EdaFace NFT
Marketplace are not considered legal violations. Accordingly, by using EdaFace NFT Marketplace and uploading
Your Content or otherwise made Your Content available, you grant us a license to access, use, host, cache, store,
copy, reproduce, transmit, display, publish, distribute, adapt, and modify (for technical purposes, e.g., making
sure content is viewable on smartphones as well as computers and other devices) Your Content in any and all
media or distribution methods (now know or later developed) but solely as required to be able to operate
and provide services of EdaFace NFT Marketplace. You agree that this license includes the right for us to
provide, promote, and improve EdaFace NFT Marketplace and to make Your Content available to other
companies, organizations, or individuals for the distribution, promotion, or publication of Your Content on other
media and services. You agree that these rights and licenses are royalty free, transferable, sub-licensable,
worldwide, and irrevocable (for so long as Your Content is stored with us), and include a right for us to make
Your Content available to, and passthese rights along to, otherswith whom we have contractual relationships
related to the provision of EdaFace NFT Marketplace, and solely for purpose of providing EdaFace NFT
Marketplace, and to otherwise permit access to disclose Your Content to third parties if we determine such
access is necessary to with our legal obligations.</p>
                            <p>As part of the comply foregoing license grant, you agree that the other Users of EdaFace NFT Marketplace
shall have the right to comment on and/or tag Your Content and/or to use, publish, display, modify or include a
copy of Your Content as part of their own use of EdaFace NFT Marketplace; except that the foregoing shall
not apply to any of Your Content that you post privately for non-public display on EdaFace NFT Marketplace.
By posting or submitting Your Content to EdaFace NFT Marketplace, you represent and warrant that you have,
or have obtained, all rights, licenses, consents, permissions, power and/or authority necessary to grant the
rights granted herein for Your Content. You agree that Your Content will not contain material subject to
copyright or other proprietary rights, unless you have the necessary permission</p>
                                
                                <p>If you sell an NFT through EdaFace NFT Marketplace, you grant to the buyer of the NFT a worldwide, non-
exclusive, non-transferable, royalty-free license to use, copy, and display the NFT Media for such purchased

NFT, solely for the following purposes: (a) for the buyer’s own personal use; (b) as part of a marketplace that
permits the purchase and sale of such NFTs, provided that the marketplace cryptographically verifies each
NFT’s owner’s rights to display the NFT Media for their NFTs to ensure that only the actual owner can display
the NFT Media; or (c) as part of a third-party website or application that permits the inclusion, involvement,
or participation of your NFT, provided that the website/application cryptographically verifies each NFT’s
owner’s rights to display the NFT Media for their NFTs to ensure that only the actual owner can display the
NFT Media, and provided that the NFT Media is no longer visible once the owner of the NFTs leaves the
website/application (the “NFT Purchase License”).</p>

                                <p>If you are a buyer of NFTs, then you acknowledge and agree that the NFT Purchase License set forth above
only lasts as long as you are the valid owner and holder of the NFT associated with the licensed NFT Media.
If you sell ortransfer the NFT to another person, thisNFT Purchase License will transfer to such other owner
or holder of the NFT, and you will no longer have the benefits of such NFT Purchase License. Unless otherwise
specified by the sellerof an NFT in writing, your purchase of an NFT does not give you the right to publicly
display,perform, distribute, sell or otherwise reproduce the NFT or its related NFT Media for any
commercial purpose.</p>
<p>If you sell an NFT, you agree that you will not have any claims against the Company for any breach of these NFT
Terms by a purchaser, including if they make commercial use of therelated NFT Media in breach of these
NFT Terms.</p>
<p>We have the right to remove or refuse to post any of Your Content, including NFTs, (a) for any or no reason in
our sole discretion; and (b) take any action with respect to Your Content that we deem necessary or appropriate
in our sole discretion, including if we believe that Your Content violates these NFT Terms, infringes any
intellectual property right of any person or entity, threatens the personal safety of Users of EdaFace NFT
Marketplace or the public, or could create liability for the Company or other Users.</p>
                          </div>
                        </AccordionDetails>
                       </Accordion>
                       <Accordion className="mt-5"> 
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>4.0 Specific Terms for the Premium Platform and Creators</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>As part of EdaFace NFT Marketplace, the Company offers a premium platform (the “Premium Platform”)
which only allows creators that are invited or otherwise approved by the Company (“Invited Creators”) to mint
and sell NFTs (the “Creator’s NFTs”) associated with their original NFT Media (the “Creator’s NFT Media”).</p>
                            <p>The Company may enter into an addendum with any Invited Creator (a “Creator Addendum”) setting forth
terms for using thePremium Platform as an Invited Creator. If there is a conflict between a Creator Addendum
and these NFT Terms, the provisions of the Creator Addendum shall take precedence for such Invited Creator.</p>
                                
                                <p>The Invited Creator hereby grants to the Company a perpetual, irrevocable, and exclusive right and license to
use, reproduce, and display the Creator’s NFT Media in connection with the promotion of the Creator’s NFT
Media, the Creator’s NFTs and EdaFace NFT Marketplace. For clarity, unless otherwise specified in a Creator
Addendum, the exclusive license granted above means that Invited Creator cannot itself, or grant to any other
party any right to, use, reproduce, display the Creator’s NFT Media in connection with non-fungible tokens or
any other blockchain collectibles, platforms or services.</p>

                                <p>In order for the Company to exercise its rights to the Creator’s NFT Media, Invited Creator will provide the
Company with high-resolution images and other embodiments of such NFT Media as reasonably requested by
the Company. The Company shall have the sole control over the promotion and marketing of the Creator’s NFTs,
including the sole discretion to select certain Creator’s NFTs or Invited Creators to participate in events hosted
by the Company. As reasonably requested by the Company, Invited Creator will support the Company in the
promotion or marketing of the Creator’s NFTs through participation in the marketing activities as agreed upon
by the parties in a Creator Addendum. In connection with the promotion of the Creator’s NFTs, the Invited
Creator hereby grants the Company a right to use the name, image, photo, biography, signature, and likeness of
the Invited Creator solely in connection with the marketing and promotion of the Creator’s NFTs.</p>
<p>Invited Creator will not engage in any promotion or marketing of the Company, EdaFace NFT Marketplace, or
any Creator’s NFTs in a manner that is misleading or deceptive or not in compliance with applicable law.
Invited Creator must disclose any material connection between Invited Creator and the Company in any such
promotion in a clear and conspicuous manner, including in close proximity to any such marketing statements.
Invited Creator will not promote or market the Creator’s NFTs in a manner intended to give buyers the
impression that such NFTs are investment products or that they can expect capital appreciation or derive
profits from the purchase of such NFTs, or indicate that such NFTs may be characterized as securities or
any other form of regulated investment product in any applicable jurisdiction.</p>
<p>If requested by the Company, Invited Creator will reasonably cooperate with the Company to validate the
authenticity of the Creator’s NFTs and the Creator’s NFT Media.</p>
<p>Invited Creator acknowledges and agrees that Company will charge a platform fee which is 10% (or other
percentage as provided in a Creator Addendum) of the purchase price for any sale of the Creator’s NFTs on
the Premium Platform.</p>
<p>For the avoidance of doubt, any use of or interaction with the Premium Platform by any User (including an
Invited Creator) is governed by terms in this Section 4.0 and the rest of these NFT Terms not in direct
contradiction with the terms in this Section 4.0.</p>
                          </div>
                        </AccordionDetails>
                       </Accordion>
                       <Accordion className="mt-5"> 
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>5.0 Specific Terms for the Standard Platform and Initial Sale</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>As part of EdaFace NFT Marketplace, the Company offers a standard platform (the “Standard Platform”) which
allows any registered Users to create NFT collections (subject to paying an associated smart contract
deployment fee) and mint and sell NFTs (the “Standard NFTs”) associated with the User’s NFT Media.
Creators of Standard NFTs can also set their own royalty fee for their NFT collections.</p>
                            <p>For an initial sale of a Standard NFT (i.e., the first instance a newly minted Standard NFT is sold by the creator to
a buyer), the transaction proceeds from the sale of the Standard NFT (the “Transaction Proceeds”) shall be
frozen in the seller’s account for a pre-specified period of time (the “Initial Sale Clearing Period”) after
which the Transaction Proceeds will be released to the seller.</p>
                                <p>The Company may extend the Initial Sale Clearing Period for a further period of time (the “Extended
Clearing Period”) provided that:</p>
  <ul>
    <li>(a). within the Initial Sale Clearing Period, a bona fide complaint is raised by the buyer or a third party for
claims that relate to intellectual property infringement, the identity, legitimacy, authenticity, and/or validity of
legal title of the Standard NFT, violation of the EDA Legals , these NFT Terms and/or violation of the terms of the
NFT sale as agreed between the seller and the buyer. For any claim raised by the buyer, the buyer shall furnish
proof that the Standard NFT at issue remains in their account and agrees to have the NFT frozen pending
resolution of the dispute. The buyer or the third party submitting a complaint shall agree that EdaFace may
supply a copy of this complaint to the seller, including the buyer/third party’s identity and contact details.</li>
<li>(b). The Company suspects the Standard NFT, the Standard NFT transaction, the seller’s activity in relation to the
Standard NFT and/or the Transaction Proceeds to be in violation of the applicable terms of sale (including
the EDA Legals and these NFT Terms) or any applicable laws and regulations.</li>
  </ul>
  <p>After the Extended Clearing Period, the Company may only continue to freeze if legal proceedings or
investigations by the authorities have been commenced against the seller (and where such legal proceedings or
investigations require the Transaction Proceeds to be frozen), or in such other circumstances that the Company
deems appropriate in its sole discretion. For the avoidance of doubt, the Company reserves the right to
freeze and take such other action in respect of the Transaction Proceeds (being Digital Assets or funds in a
EdaFace User’s Account) in accordance with the EDA Legals, regardless of whether the situation is expressly
provided for under this Section S. In such circumstances, the Company has the discretion to extend the
freeze for any further duration pending the resolution of the dispute. If the Company does not receive
proof that such a formal legal action or complaint has been filed within the Extend Clearing Period, the
Company may be entitled to release the Transaction Proceeds to the seller.</p>
<p>If the complainant and the seller are able to resolve the dispute at any stage, the Company will require written
confirmation of the resolution of the dispute from all parties before it may proceed to release the Transaction
Proceeds to the seller.</p>
<p>In no circumstances shall the Company bear any liability or responsibility for any act or omission done in relation
to the freezing (or omission to freeze) of the Transaction Proceeds, and there shall be no freezing as part of
any secondary sale of Standard NFTs.</p>
<p>In addition, by minting a Standard NFT, you acknowledge and agree that you have read and accept the “NFT
Minting Rules”, which includes examples of inappropriate content and our right at our sole discretion to,
among other things, delist or otherwise remove your NFT collection(s) or Standard NFTs from EdaFace NFT
Marketplace.</p>
<p>For the avoidance of doubt, any use of or interaction with the Standard Platform by any User is governed by
terms in this Section and the rest of these NFT Terms not in direct contradiction with the terms in this
Section</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>6.0 Copyright Policy</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>The Company may, but is not obligated to, monitor the NFTs, NFT Media, and Your Content uploaded to
EdaFace NFT Marketplace for any infringement of a third party’s intellectual property rights. However, the
Company cannot undertake to review all such content before it is posted on EDA Service, and cannot ensure
prompt removal of objectionable content after it has been posted. Accordingly, the Company assumes no
liability for any action regarding transmissions, communications, or content provided by any user or third
party. The Company will take down NFTs in response to Digital Millennium Copyright Act takedown notices
and/or other intellectual property infringement claims and will terminate a seller’s access if the seller is deemed
to be a repeat infringer. If you believe that any text, graphics, photos, audio, videos or other materials or works
uploaded, downloaded or appearing on the Services have been copied in a way that constitutes copyright
infringement, you may submit a notification to our legal department, by providing the following
information in writing:</p>
                            <ul>
                                <li>(a). identification of the copyrighted work that is claimed to be infringed;</li>
                                <li>(b). identification of the allegedly infringing material that is requested to be removed,including a description
            of where it is located on EdaFace NFT Marketplace;</li>
            <li>(c). information for our copyright agent to contact you, such as an address, telephone number, and e-mail
            address;</li>
            <li>(d). a statement that you have a good faith belief that the identified, allegedly infringing use is not
authorized by the copyright owners, its agent, or the law;</li>
            <li>(e). a statement that the information above is accurate, and under penalty of perjury, that you are the
copyright owner or the authorized person to act on behalf of the copyright owner; and</li>
<li>(f). the physical or electronic signature of a person authorized to act on behalf of theowner of the copyright
or of an exclusive right that is allegedly infringed.</li>
                            </ul>
                            <p>Notices of copyright infringement claims should be sent via email to EdaFace Legal Unit email address:
            legal@edaface.com. It is our policy, in appropriate circumstances and at our discretion, to disable or terminate
            the accounts of Users who repeatedly infringe copyrights or intellectual property rights of others.</p>
            <p>Any User of EdaFace NFT Marketplace who has uploaded or posted materials identified as infringing as
described above may supply a counter-notification. When we receive a counter- notification, we may reinstate
the posts or material in question, in our sole discretion. To file a counter-notification with us, you must provide a
written communication (by email) that sets forth:</p>
            <ul>
                <li>(i). your name, address, telephone number, e-mail address, and physical or electronic signature;</li>
                <li>(ii). identification of the material that has been removed or to which access has been disabled and the
            location at which the material appeared before it was removed or access to it was disabled; and</li>
            <li>(iii). a statement under penalty of perjury that you have a good faith belief that the material was removed
            or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
            </ul>
            <p>Please note that you will be liable for damages if you materially misrepresent that content or an activity is not
            infringing the copyrights of others.</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">    
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>7.0 Restrictions on Marketing and Promotional Activities and Logo Use</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>Any marketing and promotional activities, whether in digital, print, or any other form, that you may conduct
in connection with your use of EdaFace NFT Marketplace are subject to our review and prior written
approval. We will only allow marketing and promotional activities to be referred to strictly as collaboration with
EdaFace NFT (but not with EdaFace). You should submit any such plan relating to marketing and promotional
activities and materials for our review and provide any information that we reasonably request. The plan should
contain all key information, including but not limited to details of the relevant NFT projects, all planned media
channels or distributions, relationships between different counterparties, etc.</p>
                           
                            <p>No mention of token-related topics or any implication relating to token issue or listing, or collaboration with
EdaFace may be included. You must not create and/or publish any marketing or promotional materials that
may: (a) be deemed false and misleading; (b) breach or infringes the rights of any third parties, including
intellectual property rights; (c) breach any applicable laws and regulations; (d) breach any of our guidelines or
instructions that may be made available to you; and (e) bring into disrepute or damage the goodwill of EdaFace
and/or NFT Marketplace. We reserve the right to request changes or suspension to such plan and activity as
we deem necessary and reject any plan and activity in its entirety at our absolute discretion.</p>
            <p>Subject to further guidelines that may be made available to you, we may permit you to use the EdaFace Marks,
at our sole discretion, for the sole purposes of marketing or promoting your NFTs on EdaFace NFT
Marketplace. Terms of such guidelines made available to you shall be incorporated into these terms by
express reference herein. You must not use EdaFace’s name, logo, trade names, and any other marks (“EdaFace
Marks”) without our prior written approval.</p>
<p>You agree to indemnify us against all liabilities, costs, expenses, damages and losses (including but not
limited to any direct, indirect or consequential losses, loss of profit, loss of reputation, loss of goodwill and
all interest, penalties and legal costs (calculated on a full indemnity basis), and all other professional costs
and expenses) suffered or incurred by us arising out of or in connection with any such marketing and
promotional activities that you conduct in connection with your use of EdaFace NFT Marketplace.</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">    
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>8.0 Verification and Payment</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>When you make purchases through EdaFace NFT Marketplace, including, without limitation, any purchase for
NFTs, you must provide and maintain valid payment information in connection with your EdaFace Account (as
defined in the EDA Legals) with us. You represent and warrant that you are authorized to use the payment
method you use via EdaFace NFT Marketplace to make any purchase. You authorize us to charge your
payment method for the total amount of your purchase price. Your order may be suspended or canceled for
any reason, including if the payment method cannot be verified, is invalid, or is otherwise not acceptable. Other
payment terms for purchases are set forth in the relevant sections of the EDA Legals.</p>
                           
                            <p>We have no liability to you or any third party for any claims or damages that may arise as a result of any
payments or transactions that you engage in via EdaFace NFT Marketplace, or any other payment or
transactions that you conduct via EdaFace NFT Marketplace. WE DO NOT PROVIDE REFUNDS FOR ANY
PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH EDAFACE NFT MARKETPLACE — WHETHER FOR NFTS
OR ANYTHING ELSE.</p>
            <p>Subject to further guidelines that may be made available to you, we may permit you to use the EdaFace Marks,
at our sole discretion, for the sole purposes of marketing or promoting your NFTs on EdaFace NFT
Marketplace. Terms of such guidelines made available to you shall be incorporated into these terms by
express reference herein. You must not use EdaFace’s name, logo, trade names, and any other marks (“EdaFace
Marks”) without our prior written approval.</p>
<p>You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and
assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental
authority associated with your use of EdaFace NFT Marketplace or NFTs, except for income taxes levied on us
as a result of such purchases of NFTs.</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">    
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>9.0 Assumption of Risks</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>YOU ARE STRONGLY ADVISED TO DO YOUR OWN RESEARCH. You accept and acknowledge that (i) the value
of an NFT is subjective; prices of an NFT are subject to volatility and fluctuations in the price of
cryptocurrency can also materially and adversely affect NFT prices; (ii) a lack of use or public interest in NFTs
could negatively impact the potential utility of NFTs; (iii) the regulatory regime governing NFTs is uncertain, and
new regulations or policies may materially adversely affect the utility of NFTs; and (iv) there are risks associated
with purchasing items associated with content created by third parties through peer-to-peer transactions,
including but not limited to, the risk of purchasing counterfeit items, mislabeled items, items that are
vulnerable to metadata decay, items on smart contracts with bugs, and items that may become untransferable.
You represent and warrant that you have done sufficient research before making any decisions to sell, buy,
transfer, or otherwise interact with any NFTs. You further acknowledge and agree it is your sole responsibility to
carry out all necessary due diligence for all your activities relating to NFTs, and you represent and warrant that
you have not and are not relying on, and shall have no remedies, in respect of any statement or representation
made by the Company and/or EdaFace (as defined in the EDA Legals) in relation to any sale, buy, transfer or
interaction otherwise with any NFTs.</p>
                           
                            <p>Any purchase or sale you make, accept or facilitate outside of EdaFace NFT Marketplace of an NFT will be
entirely at your risk. You acknowledge that you have obtained sufficient information to make an informed
decision to purchase an NFT, including carefully reviewing the code of the smart contract and the NFT and fully
understand and accept the functions of the same. We do not control or endorse purchases or sales of NFTs
outside of EdaFace NFT Marketplace. We expressly deny and disclaim any liability to you and deny any
obligation to indemnify you or hold you harmless for any losses you may incur by transacting, or facilitating
transactions, in NFTs outside of EdaFace NFT Marketplace.</p>
            <p>Certain parts of EdaFace NFT Marketplace may display, include or make available content, data, information,
applications, or materials from third parties (“Third Party Materials”). By using EdaFace NFT Marketplace,
you acknowledge and agree that the Company is not responsible for examining or evaluating the content,
accuracy, completeness, availability, timeliness, validity, copyright compliance, legality, decency, quality, or
any other aspect of such Third Party Materials. We do not warrant or endorse and do not assume and
will not have any liability or responsibility to you or any other person for any third-party services, Third
Party Materials, or any other materials, products, or services of third parties.</p>
<p>If you have a dispute with one or more users, YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES
OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
WITH SUCH DISPUTES. IN ENTERING INTO THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS
(WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THIS RELEASE
TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOUR AT THE TIME
OF AGREEING TO THIS RELEASE.</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>10.0 Limitation of Liability</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY BE LIABLE
TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES RELATED TO THE LOSS OF REVENUE, LOSS OF PROFIT, LOSS
OF BUSINESS OR ANTICIPATED SAVING, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF DATA, WHETHER
CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF PARTIES
HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND (B) FOR ANY OTHER CLAIM, DEMAND, OR
DAMAGES WHATSOEVER RESULTING FROM OR ARISING OUTOF OR IN CONNECTION WITH THESE TERMS OF
THE DELIVERY, USE, OR PERFORMANCE OF THE SERVICE. ACCESS TO, AND USE OF, THE SERVICE,
PRODUCTS OR THIRD—PARTY SITES, AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU
WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS
OF DATA RESULTING THEREFROM.</p>
                           
                            <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM
AGGREGATE LIABILITY OF THE COMPANY ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS,
THE ACCESS TO AND USE OF THE SERVICE, CONTENT, OR NFTS EXCEED 100 United States Dollars.</p>
            
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>11.0 Termination</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>If you breach any of the provisions of these NFT Terms, we reserve the right, with or without notice and in
our sole discretion, to suspend, disable, terminate, or delete your account and/or your ability to access or
use EdaFace NFT Marketplace (or any part of the foregoing) at any time and for any or no reason, and you
acknowledge and agree that we shall have no liability or obligation to you in such event and that you will not
be entitled to a refund of any amounts that youhave already paid to us.</p>
                          </div>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion className="mt-5">
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                          <div className="accordian_head">
                            <h2>12.0 Contact</h2>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>General questions or comments about EdaFace NFT Marketplace or these NFT Terms should be sent by
email to support@edaface.com or by contacting the customer support team via Contact Us at
&nbsp;<a href="https://edaface.com/contact" target="_blank">https://edaface.com/contact</a>.</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>
              </div>
            </GridItem>
            {/* <GridItem xs={12} sm={6} md={6}>
              <div className="faq_panel">
                {faqlist.map((item, ind) => {
                  if (ind % 2 !== 0) {
                    // //alert(ind%2)
                    return (

                      <Accordion expanded={expanded === 'panel' + (ind + 1)} onChange={handleChangeFaq('panel' + (ind + 1))}>
                        <AccordionSummary expandIcon={<Icon />} aria-controls="panel10bh-content" id="panel4bh-header">
                          <div className="accordian_head"><h2>{item.question}</h2></div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordian_para">
                            <p>{ReactHTMLParser(item.answer)}</p>
                          </div>
                        </AccordionDetails>
                      </Accordion>


                    )
                  }
                })}
              </div>
            </GridItem> */}
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
