import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import Web3 from 'web3';

import '@metamask/legacy-web3';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import config from '../../lib/config';
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { toast } from 'react-toastify';
toast.configure();
var Front_URL = config.Front_URL;

export const ShareNowRef = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const Wallet_Details = useSelector(state=>state.wallet_connect_context);

    const [item, Set_Item] = React.useState({});
    const [onwer_price, set_onwer_price] = React.useState({});

    useImperativeHandle(
        ref,
        () => ({
            async ShareSocial_Click(items, onwer_price) {
            //console.log("ShareSocial_Click",onwer_price,items)


//                 var accountDetailsss=''
//                 //console.log("dfbhsfdbn",items,Wallet_Details.networkConfiguration.Chainid,items.tokenowners_current[0].SelectedNetwork);
                
//                 if(Wallet_Details.networkConfiguration.Chainid==items.tokenowners_current[0].SelectedNetwork)
//                 {
//                     accountDetailsss=true
//                 }
//                 else{
//                     var accountDetailsss =await  switchNetwork(items.tokenowners_current[0].SelectedNetwork)
//                 }
    
// //console.log("dafbfsbhnsf",accountDetailsss)

    // if(accountDetailsss!='')
    // {



                if (items) {
                    Set_Item(items)
                    set_onwer_price(onwer_price)
                    window.$('#share_modal').modal('show');

                }
            // }
        }
        }),
    )


    // async function switchNetwork(configdata)
    // {
    //   var type = ""
    // var networkConfiguration ={}
    // if(configdata)
    // {
    //     if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
    
    //       type = "MetaMask"
    //     }
    //     else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
    //       type = "WalletConnect"
    //     }
    
    //  window.$('#connect_modal').modal('hide');
    //     //console.log("connecttype............", type)
    //     var accountDetails = await connectWallet(type, configdata.Chainid)
    //     //console.log("accountDetailsin create page......................", accountDetails)
    //     //  const id=toast.loading("Wallet Connecting...")
    //  var web3 = new Web3(window.ethereum);
    //   //console.log("dfghrtfh",web3);
    //   if(window.ethereum.isMetaMask == true){
    //     const chainId = await web3.eth.getChainId();
    //     //console.log("fghdtgj",chainId);
    
    //     if(chainId===network.ETH.Chainid)
    //     {
    //           networkConfiguration=network.ETH
    //     }
    //     else if(chainId===network.BSC.Chainid){
    //       networkConfiguration=network.BSC
    //     }
    
    
    //   }
    //  // setPriceoption()
    
    //     if (accountDetails != '') {
    //       //console.log("Wallet Connecting...increate", accountDetails.web3._provider);
    //       dispatch({
    //         type: Account_Connect,
    //         Account_Detail: {
    //           UserAccountAddr: accountDetails.accountAddress,
    //           UserAccountBal: accountDetails.coinBalance,
    //           WalletConnected: "true",
    //           Wen_Bln: accountDetails.tokenBalance,
    //           Accounts: accountDetails.accountAddress,
    //           providerss: accountDetails.web3._provider,
    //           networkConfiguration:networkConfiguration
    //         }
    //       })
    //     }
    //   }
    
    
    
    //   //   var ReqData = {
    //   //     addr: String(accountDetails.accountAddress).toLowerCase()
    //   // }
    //   // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    //   // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
    //   //     return Resp.data.data.User
    //   // } else {
    //   //     return null
    //   // }
    
    
    
    
    
    // return accountDetails
    // }


    return (
        <div>
            <div className="modal fade primary_modal" id="share_modal" tabIndex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="share_modalLabel">Share link to this page</h5>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">

                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body  px-0">

                            <div className="row justify-content-center mx-0">
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">

                                    <TwitterShareButton
                                            title={`${item.tokenName}  NFT`}
                                            url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
                                          
                                        >
                                            <i className="fab fa-twitter"></i>
                                            <p>Twitter</p>


                                        </TwitterShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">

                                    <TelegramShareButton
                                            title={`${item.tokenName}  NFT`}
                                            url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
                                        
                                        >

                                            <i className="fab fa-telegram-plane"></i>
                                            <p>Telegram</p>

                                        </TelegramShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">
                                    <FacebookShareButton
                                            quote={`${item.tokenName} NFT`}
                                            // title={`${item.tokenName}  NFT`}
                                            url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
                                        
                                        //     via={`${config.Front_URL}`}
                                        >
                                            <i className="fab fa-facebook-f"></i>
                                            <p>Facebook</p>
                                        </FacebookShareButton>

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 px-1">
                                    <div className="text-center icon_div">
                                    <WhatsappShareButton
                                           title={`${item.tokenName}  NFT`}
                                           url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
                                         >
                                            <i className="fab fa-whatsapp"></i>
                                            <p>Whatsapp</p>
                                        </WhatsappShareButton>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

