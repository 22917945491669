import React, {
    forwardRef,
    useImperativeHandle,useState,useEffect
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';

import config from '../../lib/config';

import { BurnField,checkOtherPlatformDetais1155} from '../../actions/v1/token';
import { toast } from 'react-toastify';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import {getReceipt} from '../../actions/v1/getReceiptFunc';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";


toast.configure();
let toasterOption = config.toasterOption;

export const BurnRef = forwardRef((props, ref) => {

    const history = useHistory()
    const dispatch = useDispatch();
    const Wallet_Details = useSelector(state=>state.wallet_connect_context);
    const [burnLoading, setBurnLoading] = useState('empty');
    const [burnError, setburnError] = useState('');

     const [ValidateError, Set_ValidateError] = useState({});
    const[Items,Set_Items]=useState({})
    const [noofitems,setnoofitem]=useState(0)
 const[MyTokenDetail,set_MyTokenDetail]=useState({})


 useImperativeHandle(
    ref,
    () => ({
        async Burn_Click(item, MyTokenDetail) {
           var chainid = item.tokenowners_current[0].SelectedNetwork
            var accountDetailsss=''
            console.log("dfbhsfdbn",item,Wallet_Details.networkConfiguration.Chainid,item.tokenowners_current[0].SelectedNetwork);
            
            if(Wallet_Details.networkConfiguration.Chainid==item.tokenowners_current[0].SelectedNetwork)
            {
                accountDetailsss=true
            }
            else{
                console.log("burn else",chainid);
                var accountDetailsss =await  switchNetwork(chainid)
            }
// setTimeout(async() => {
    console.log("vhmfghmghjm",chainid , Wallet_Details.networkConfiguration.Chainid);
    if(chainid === Wallet_Details.networkConfiguration.Chainid)
    {
    console.log("dfbsfgfgdsfgb");
                if (Wallet_Details.UserAccountAddr!=="") {
                    var web3=new Web3(Wallet_Details.providerss)
                    var data = {
                        Singleaddress: Wallet_Details.networkConfiguration.singleContract,
                        multipleContract: Wallet_Details.networkConfiguration.multipleContract
                      }
                    var balance = await checkOtherPlatformDetais1155(item,MyTokenDetail,item.type,web3,data);
                     console.log('sdvbalsdvancev>>>vdfvdfv>>>>dsv>>',balance)
                    if (balance === 0) {
                        toast.warning("You won't buy at this moment please refresh you data",toasterOption);
                        setTimeout(() => {
                        history.push("/")
                        }, 1000);
                        return false;
                    }
                    else{
    
                    props.Set_HitItem(item);
                    Set_Items(item)
                    setnoofitem(MyTokenDetail.balance)
                    set_MyTokenDetail(MyTokenDetail)
                    Set_ValidateError({});
                    window.$('#burn_token_modal').modal('show');
                }}
                else {
                    window.$('#connect_modal').modal('show');
                }
            }


// }, 1000);

    }
    }),
)

async function switchNetwork(configdata)
{
  var type = ""
var networkConfiguration ={}
if(configdata)
{
    if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

      type = "MetaMask"
    }
    else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
      type = "WalletConnect"
    }

 window.$('#connect_modal').modal('hide');
    console.log("connecttype............", type,configdata)
    var accountDetails = await connectWallet(type, configdata)
    console.log("accountDetailsin create page......................", accountDetails)
    //  const id=toast.loading("Wallet Connecting...")
 var web3 = new Web3(window.ethereum);
  console.log("dfghrtfh",web3);
  if(window.ethereum.isMetaMask == true){
    const chainId = await web3.eth.getChainId();
    console.log("fghdtgj",chainId);

    if(chainId===network.ETH.Chainid)
    {
          networkConfiguration=network.ETH
    }
    else if(chainId===network.BSC.Chainid){
      networkConfiguration=network.BSC
    }


  }
 // setPriceoption()

    if (accountDetails != '') {
      console.log("Wallet Connecting...increate", accountDetails.web3._provider);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          UserAccountAddr: accountDetails.accountAddress,
          UserAccountBal: accountDetails.coinBalance,
          WalletConnected: "true",
          Wen_Bln: accountDetails.tokenBalance,
          Accounts: accountDetails.accountAddress,
          providerss: accountDetails.web3._provider,
          networkConfiguration:networkConfiguration
        }
      })
    }
  }



  //   var ReqData = {
  //     addr: String(accountDetails.accountAddress).toLowerCase()
  // }
  // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
  // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
  //     return Resp.data.data.User
  // } else {
  //     return null
  // }





return accountDetails
}

async function FormSubmit(data,MyTokenDetail) {
    console.log("DFhbgdfhbd");
    var receipt=null;
        var handle=null;
    if (Wallet_Details.UserAccountBal < 0) {
        toast.error('Enter vaid balance');
        return false;
    }
    if(Wallet_Details.providerss) {
        var web3 = new Web3(Wallet_Details.providerss)
        if(
            web3
        ) {
            setBurnLoading('processing');
            var CoursetroContract=null;
            var contractCall=null;
            try{
            if(Items.type===721){
            var CoursetroContract = new web3.eth.Contract(
                SINGLE,
                Items.contractAddress
            );
             await CoursetroContract.methods
            .burnNFT(
               
               data.tokenCounts
           )
           .send({ from: Wallet_Details.UserAccountAddr })
           .on('transactionHash',async (transactionHash) => {
            handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                clr1();
              }, 8000)
        })
        }
            else if(Items.type===1155){
                console.log("gfnjcgmjgh",data.tokenCounts,noofitems,"hfgxcufgxgxc",   MULTIPLE,
                Items.contractAddress);
                var CoursetroContract = new web3.eth.Contract(
                    MULTIPLE,
                    Items.contractAddress
                );  
            await CoursetroContract.methods
            .burnNFT(data.tokenCounts,noofitems)
           .send({ from: Wallet_Details.UserAccountAddr })
            
            .on('transactionHash',async (transactionHash) => {
                handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash)
                    clr1();
                  }, 8000)
            })
        }
        else{
            
        }
    }
    catch(error) {
        setBurnLoading('try');
        console.log('error : ', error);
        toast.error('Order not placed', toasterOption)
    }
        
            async function clr1(){
   
                if(receipt!=null){
                    clearInterval(handle);
                     if(receipt.status===true){   
            
                ////////console.log('result : ', result);
      var postData = {
                    tokenOwner: Wallet_Details.UserAccountAddr,
                    tokenCounts: data.tokenCounts,
                    blockHash: receipt.blockHash,
                    transactionHash: receipt.transactionHash,
                    contractAddress:data.contractAddress,
                    type:data.type,
                    balance:noofitems,
                    currAddr:Wallet_Details.UserAccountAddr,
                    quant:MyTokenDetail.balance
      
      
                }
                setBurnLoading('done');
      ////////console.log('postDatafrominfo',postData);
      var updateBurnField = await BurnField(postData)
      if(updateBurnField){
        toast.success('Burned successfully',toasterOption)
        document.getElementById('closeburn').click() 
        // history.push('/')
        history.push("/")
      }
    }
}
            
        
  
}
}
}
}


var {
    // item,
    UserAccountAddr,
    // UserAccountBal,
    // Service_Fee
} = props;

useEffect(() => {
    Set_ValidateError({});
}, []);


const inputChange = (e) => {
    setburnError('')
    if (e.target && e.target.value)
// ////////console.log("lalalalalalallala",MyTokenDetail)
if(MyTokenDetail.balance>=e.target.value  &&Number(e.target.value) !== 0 ){
setnoofitem(e.target.value)
  setBurnLoading('init');}
else if(Number(e.target.value) === 0){
  setBurnLoading('zero');
  setburnError('zero')
}
else if(e.target.value === "" ){
  setBurnLoading('empty');
  setburnError("empty")

}
else if(e.target.value === undefined ){
  setBurnLoading('empty');
  setburnError("empty")

}
else{
  setBurnLoading('errors');
  setburnError("errors")
  
}

}






    return (
   
	<div className="modal fade primary_modal" id="burn_token_modal" tabIndex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div className="modal-content">
        <div className="modal-header text-center">
        <h5 className="modal-title" id="burn_token_modalLabel">Burn token</h5>

        <button type="button" id="closeburn" className="close"  data-bs-dismiss="modal" aria-label="Close" 

          disabled = { (burnLoading==='processing') }

        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body px-0">
            <form className="bid_form">
    <div className="px-4">
                    <p className="checkout_text_light_white text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
                </div> 
                <div className="px-4">
                    <p className="checkout_text_light_white">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
                </div>  
                <div className="px-4 mt-4 pt-2">               
                    <input 
                        id = "burn" 
                        name="burn" 
                        className="form-control"
                        onChange = {(e) => { inputChange(e)}}
                    />
                                                            {
                                                            burnError==='errors' && <span className="text-danger">Check Balance</span>||
                                                            burnError==='zero'&& <span className="text-danger">Qty can't be Zero</span>||
                                                            burnError==='empty'&& <span className="text-danger">Qty can't be Empty"</span>
                                                            }

                    <div className="text-center mt-3">
                        <Button 
                            className="burn_btn_red primary_btn btn-block" 
                            onClick={(() => FormSubmit(Items, MyTokenDetail))}
                            disabled = { (burnLoading === 'done' || burnLoading==='processing' || burnLoading === 'zero'||burnLoading === 'errors'||burnLoading === 'empty') }
                            >
              {burnLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                {burnLoading === 'init' && 'Continue'}
                                {burnLoading === 'processing' && 'In-progress...'}
                                {burnLoading === 'done' && 'Done'}
                                {burnLoading === 'try' && 'Try Again'}
              {burnLoading === 'errors' && 'Try Again'}
              {burnLoading === 'zero' && 'Try Again'}
              {burnLoading === 'empty' &&'Try Again'}
              
                            </Button>

                        <Button className="btn_outline_grey cancel_btn btn-block"  data-bs-dismiss="modal" aria-label="Close">Cancel</Button>

                    </div>
                </div>
            </form>
        </div>
    </div>
    </div>
</div>
)
})

