//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import range from "../../src/assets/images/dollar.png";
import collect from "../../src/assets/images/category.png";
import sales from "../../src/assets/images/sales.png";
import Collection from "../../src/assets/images/collection.png";
import filter from "../../src/assets/images/sort.png";
// mysid
import TokenCard from './separate/TokenCard'
import {
  GetCategoryAction
} from '../actions/v1/token';
import isEmpty from "lib/isEmpty";
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';


toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Art(props) {

  const classes = useStyles();


  // const [TokenImages, setTokenImage] = useState({});
  // const [CategoryOption, setCategoryOption] = useState([]);







  // async function GetCategoryCall() {
  //   var resp = await GetCategoryAction()
  //   if (resp && resp.data && resp.data.list) {
  //     var CategoryOption = [];
  //     var ind = null
  //     resp.data.list.map((item, index) => {
  //       ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
  //       CategoryOption.push({
  //         name: 'TokenCategory',
  //         value: item._id,
  //         label: item.name
  //       })
  //     })
  //     if (ind > -1) {
  //       delete CategoryOption[ind]
  //     }
  //     setCategoryOption(CategoryOption)
  //   }
  // }
  





  const { ...rest } = props;
  const { Param_category } = useParams()
 


  return (

    <div>

      <div className="home_header">


        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 recentlyadded explore">
          <div className="container-fluid container-theme container-lg">
            <div className="flex_block_mob scrollheight align-items-center justify-content-center mb-5">
              <h1 className="title_text_white">Art NFT</h1>
              <p className="artnft">The Home of Digital Arts</p>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="row">

                    <div className="col-lg-10 col-sm-10 col-12 col-md-10 explore_enf">



                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={Collection} className="filteradd" />
                            Collections </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  
                                    
                                     
                                        <li className="px-3" >
                                          <div className="media">
                                            <div className="media-body">
                                              <div>
                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >collection name</p>

                                              </div>

                                            </div>
                                          </div>

                                        </li>

                                      
                                    

                                </ul>
                   
                              </Scrollbars>


                            </div>
                          ]}
                        />
                      </div>

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={sales} className="filteradd" />
                            Sales Type </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
             
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={range} className="filteradd" />
                            Price Range </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 100 }} className="nto_scrol_div">
                  
                                <ul className="explorebg">
                                
                                  <li className="px-3"
                                  >
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                       
                                </ul>
                  
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={filter} className="filteradd" />
                            Filter & Sort </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Recently</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"
                                    >
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                              
                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              
            </div>
            <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className="m-0">
                    <div className="eploregrid">
                     <div className="row">
                          
                          {[...Array(30)].map(() =>(
                            <div className="col-lg-3 col-md-4 col-sm-6 pb-5">
                                  {/* <TokenCard
                                  /> */}
                                  </div>))}
                          
                     </div>
                    </div>
                </div>

              </div>

            </div>



          </div>
         
              <div className="text-center mt-4 w-100 ">
               
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore"   style={{ display: "flex" }}>
                    Load More <span>
                   
                    </span>

                  </Button>
                </div>
              </div>
            </div>
        </section>


        <div id="logo_overlay" className="logo_ovelay">
          <Footer />
        </div>
      </div>
    </div>

  );
}
