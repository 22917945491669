import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import {faqlists} from '../actions/v1/report'
import ReactHTMLParser from 'react-html-parser'

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #a30726;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #a30726;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Globalrush(props) {
  const classes = useStyles();
  const { ...rest } = props;
 

  return (
    <div className="inner_header howItWorks">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader resourcesswe"}>
        <div className="bg_red_1">
        <div className="container-fluid container-theme">

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title">The Global Rush for NFTs</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container mt-3">
        <div class="accordion" id="accordionExample">
  
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      The Uniqueness of NFTs
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
   
      <div className="accordionparalist">
                         
                          <p>NFTs are minted by a blockchain. Since they are on blockchains, it means anyone can view them
online for free
</p>
                          <p>One reason is that NFTs grant an exclusive ownership right. The unique data of NFTs makes it easy to
verify their ownership and transfer tokens from one owner to another. Moreover, the creator’s
signature gives originality to each NFT. 

</p>
</div>

      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Great Promise to All Industries
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>In other words, NFTs can confirm not only ownership but also authenticity. Plagiarism and illegal
production will become a thing of the past. Meaning the days of proper remuneration for artistic
and scientific works have arrived!

</p>
      <p>Indeed, these hold great promise to all industries and they are what drive the frenzied rush for NFT
acquisition.</p>
      <p>Gaming NFTs are already prominent as part of the metaverse technology, which has rapidly grown in
value to be more than 10 billion USD market capitalization.
</p>
      <p>If you are a soccer fan, imagine getting an original artistic image of one of the great goals scored by
the famous Brazilian player, Edson Arantes do Nascimento, popularly known as Pelé, during his
professional career! Through NFT technology, that is possible!</p>
<div className="accordionparalist">
                        <i>Hurray!!!</i>
                          <p>NFTs are digital representations of the physical world! Meaning the days of wealth from artistic
works have arrived! Don’t be left behind!

</p>
</div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
