import React, {useState,useRef,useEffect} from "react";
import { Link,useLocation } from "react-router-dom";
import $ from 'jquery';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Bannerimg from "../assets/images/bannerheade.png";
// import BSC from "../assets/images/BNB12.png";
import TokenCard from '../views/separate/TokenCard'

import Profile from "../assets/images/profile_img.png";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// mysid
import ReactHtmlParser from 'react-html-parser';

import { Button } from "@material-ui/core";
import isEmpty from '../lib/isEmpty'
import '@metamask/legacy-web3'
import {
  CollectiblesList_Home,
  topCreatorsApi,
  getBuyerSeller,
  gallarycollection,
  Hotcollections
} from '../actions/v1/token';
import { getcmslistinhome, Bannerpromotion, gall } from '../actions/v1/report'
import axios from 'axios';
import config from '../lib/config';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { useDispatch, useSelector } from "react-redux";
import {getnewpromotion}from "../actions/v1/user"
const dashboardRoutes = [];



export default function Home(props) {



  const hoursMinSecs = {hours:1, minutes: 20, seconds: 40}
  

  var state = {
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      767: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1199: {
        items: 5,
      },
      1400: {
        items: 5
      }

    },

  };
  var liveauctions = {
    dots: false,
    infinite: true,
    autoplay:true,
    smartSpeed: 400,
    //loop:true,
    margin:60,
    nav:true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    speed: 1500,
    
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:4
        }
    }

  };
  // const classes = useStyles();
  const { ...rest } = props;



  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $('header').addClass('fixedTop');
    } else {
      $('header').removeClass('fixedTop');
    }
  });




  const [responsiveone] = React.useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    }

  })

  
  var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {

      return <span>Waiting for Owner To Accept</span>
    } else {
      return <span> <span className="hourds">{formatTime(hours)}</span> : <span className="hourds">{formatTime(minutes)}</span> : <span className="hourds">{formatTime(seconds)}</span> </span>;
    }
  };
  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }
  const options={
    dots: false,
    infinite: true,
    autoplay:true,
    smartSpeed: 400,
   
    margin:60,
    nav:true,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    speed: 1500,
    
      responsive:{
          0:{
              items:1
          },
          600:{
              items:3
          },
          1000:{
              items:5
          }
      }
   
}









// const Wallet_Details = useSelector(state => state.wallet_connect_context);

// // useEffect(() => {

// //   if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.BiddingToken_USD)
// //     //console.log("dxzhbvshdfbghkfsjdbghkjfsdbg", Wallet_Details.tokenAddress.BiddingToken_USD)

// // }, [Wallet_Details.tokenAddress])



// var LikeForwardRef = useRef();
// var PutOnSaleForwardRef = useRef();
// var PurchaseNowForwardRef = useRef();
// var BurnForwardRef = useRef();
// var CancelOrderForwardRef = useRef();
// var WalletForwardRef = useRef();
// var ReportForwardRef = useRef();
// var ShareForwardRef = useRef();
// var PlaceABidForwardRef = useRef();



// const [buyerSeller, setBuyerSeller] = useState([]);
// const [Bids, Set_Bids] = useState([]);
// const [Time, setTime] = useState(7);
// const [gallarycoll, Setgallarycoll] = useState({})
// const [hotcol, Sethotcoll] = useState({})
// const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
// const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
// const [Promotion_List, Set_Promotion_list] = useState({})
// const [Promotion_Banner, Set_Promotion_Banner] = useState({})
// const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
// const [Categorylist, setCategorylist] = useState([]);
// const [topcreatorsection, settopcreatorsection] = React.useState([]);
// const [YouWillPay, Set_YouWillPay] = useState(0);
// const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
// const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
// const [YouWillGet, Set_YouWillGet] = useState(0);
// const [convertVal, setConvertVal] = React.useState(0);
// const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
// const [CatName, setCatName] = useState('All');
// const [AllowedQuantity, Set_AllowedQuantity] = useState({});
// const [MyTokenDetail, Set_MyTokenDetail] = useState({});
// const [LikedTokenList, setLikedTokenList] = useState([]);
// const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
// const [LoadDisable, Set_LoadDisable] = useState(false);
// const [TokenList, setTokenList] = useState([]);
// const [Creatorcount, setCreatorcount] = useState(0);
// const [item, Set_item] = useState({});
// const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
// const [CatBasedTokenList, setCatBasedTokenList] = useState({
//   'loader': false, 'All': {
//     page: 1, list: [],
//     onmore: true
//   }
// });




const location = useLocation();

const Wallet_Details = useSelector(state => state.wallet_connect_context);
//console.log("DFbhsfgnsfgn",Wallet_Details);

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  // const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    'loader': false, 'All': {
      page: 1, list: [],
      onmore: true
    }
  });

  //const [Page, setPage] = useState(1);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  // const [WalletConnected, Set_WalletConnected] = useState('false');
  const [providerss, set_providers] = useState(null)
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  // const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [Promotion, Set_Promotion_list] = useState([])
  const [Promotion_Banner, Set_Promotion_Banner] = useState({})
  const [gallarycoll, Setgallarycoll] = useState({})
  const [hotcol, Sethotcoll] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});

  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [buyerSeller, setBuyerSeller] = useState([]);
  const [buyersellerDrop, setbuyersellerDrop] = useState('buyer');
  const [closemodel, setclosemodel] = useState(false);
  const [Time, setTime] = useState(7);
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [imgfilename, Set_imgfilename] = useState("");
  const [imgpath, setimgpath] = useState("");
  const [promotiondetail, setpromotiondetail] = useState("");


useEffect(() => {
  //console.log("dsgvsdrg",Wallet_Details);
   //console.log("sdbvdsafbgf",location)
  Galarycoll();
   Hotcoll();
   getInit();
  Timefun();
   TokenListCall();
   getBuyerSellerFun('buyer');

}, [Time])



async function getInit() {
  
  getcmslistinhomes();
  getcmslistinhomes1();
  promotionData();
  CategoryListCall();
  topCreatorsFUnc();
  BannerPro();
  timeAuctionFUnc();
  recentNftFUnc();
  
  // ;}

}

const getcmslistinhomes = async () => {
  var reqdata = {
    load: 'home'
  }
  var convers = await getcmslistinhome(reqdata);
  if (convers && convers.data) {
    setgetcmslistinhome1(convers.data)
  }
}
const getcmslistinhomes1 = async () => {
  var reqdata = {
    load: 'tophome'
  }
  //console.log("hkmgh,j.kjg.hkl",reqdata);

  var convers = await getcmslistinhome(reqdata);
  //console.log("frtjtfrjtey",convers);
  if (convers && convers.data) {
    setgetcmslistinhome12(convers.data)
  }
}



const promotionData = async () => {
  // var test = await getnewpromotion();
  //  //console.log("Dsajdashdjasgdaskdkagshdghasgkd", test)
  // if (test && test.userValue?.data) {
  //   Set_Promotion_list(test.userValue.data)
  // }

  var promotion = await getnewpromotion()
console.log("fgndfgndfgnh",promotion?.data?.data?.promotion[0]?.image,promotion?.data,promotion?.data?.length===1);
if(promotion?.data?.data?.promotion?.length===1)
{
  var file= promotion?.data?.data?.promotion[0]?.image
  Set_imgfilename(promotion?.data?.data?.promotion[0]?.image)
  var path = `${config.Back_URL}/newpromotion/${file}`
  setimgpath(path)
  setpromotiondetail(promotion?.data?.data?.promotion[0])
}
}

const BannerPro = async () => {
  var test = await Bannerpromotion();
  //  //console.log("Dsajdashdjasgdaskdkagshdghasgkd", test)
  if (test && test.userValue) {
    // //console.log("hfijwedifghi", test.userValue);
    Set_Promotion_Banner(test.userValue)
  }
}

async function CategoryListCall() {
  axios
    .get(`${config.vUrl}/token/category/list`)
    .then(response => {
      if (response && response.data && response.data.list) {
        setCategorylist(response.data.list);
      }
    })
    .catch(e => console.log(e))
}

const topCreatorsFUnc = async () => {
  var topCraete = await topCreatorsApi();
   //console.log("hjvbkjhbvkj,ubgjkhub", topCraete)
  if (topCraete && topCraete.data) {
    
    settopcreatorsection(topCraete.data.data)
  }
}
const timeAuctionFUnc = async () => {
  //console.log("DFhdsgfh",Wallet_Details.UserAccountAddr);
  var currAddr = Wallet_Details.UserAccountAddr;

  var payload = {
    limit: 6, from: 'Time', currAddr: currAddr
  }
  var resp = await CollectiblesList_Home(payload);
  if (resp && resp.data && resp.data.from === 'time-auction-token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
    //console.log("dfhbsxdfh",resp.data.list);
    Set_Time_Auction_List(resp.data.list)
  }
  else {
    Set_Time_Auction_List([])
  }
}



const recentNftFUnc = async () => {
  var currAddr = Wallet_Details.UserAccountAddr

  var payload = {
    limit: 10, from: 'recent', currAddr: currAddr
  }
  var resp = await CollectiblesList_Home(payload);
  if (resp && resp.data && resp.data.from === 'recent-token-collectibles-list-home' && resp.data.list && ((resp.data.list).length !== 0)) {
    // //console.log("all come", resp.data)
    Set_Recent_Nft_List(resp.data.list)
  }
  else {
    Set_Recent_Nft_List([])
  }
}


const Galarycoll = async () => {
  var collectiongal = await gallarycollection();
   //console.log("gallarycollectioncomming", collectiongal?.data?.data?.collections)
  Setgallarycoll(collectiongal?.data?.data?.collections)
}



const Hotcoll = async () => {
  var Arg = {
    Timed: Time,
  };
  //console.log("skjdgasfabnkrfj",Arg);
  var collectiongal = await Hotcollections(Arg);
   //console.log("gallarycollectioncomming.......", collectiongal?.data?.data?.collections)
  Sethotcoll(collectiongal?.data?.data?.collections)
}

async function Timefun (timefunn) {
  // //console.log("nhkjdcbvdjshb",timefunn);
  if(timefunn > 0 ){
  setTime(timefunn)
  }
    }


    async function TokenListCall(data = {}) {

      var currAddr = Wallet_Details.UserAccountAddr
      var name = CatName;
      if (data.CatName) {
        name = data.CatName
      }
      var payload = {
        limit: 10,
        page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
        currAddr: currAddr,
        CatName: name,
        from: 'Home',
      }
      Set_LoadDisable(true)
      // //console.log("loadMore>>>", payload, CatBasedTokenList[name], CatBasedTokenList)
      CatBasedTokenList.loader = true;
      setCatBasedTokenList(CatBasedTokenList);
      var resp = await CollectiblesList_Home(payload);
      CatBasedTokenList.loader = false;
      setCatBasedTokenList(CatBasedTokenList);
      Set_LoadDisable(false)
      if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
        setTokenList(TokenList.concat(resp.data.list));
        setCreatorcount(resp.data.list.length);
        if (typeof CatBasedTokenList[name] == 'undefined') {
          CatBasedTokenList[name] = { page: 1, list: [] };
        }
        CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
        setCatBasedTokenList([]);
        setCatBasedTokenList(CatBasedTokenList);
      }
      else {
        CatBasedTokenList[name].onmore = false;
        setCatBasedTokenList([]);
        setCatBasedTokenList(CatBasedTokenList);
      }
  
    }

    const getBuyerSellerFun = async (filter) => {
      var filterobj = {}
      ////console.log('filterbuyerseller',filter);	
      if (!isEmpty(filter)) {
        if (filter === 'seller')
          filterobj.buyerSellerFilter = filter
        if (filter === 'buyer')
          filterobj.buyerSellerFilter = filter;
        //   if (filter === 'today')
        //     filterobj.buyerSellerTimeFilter = filter;
        //   if (filter === 'yesterday')
        //     filterobj.buyerSellerTimeFilter = filter;
      }
      const response = await getBuyerSeller(filterobj);
      ////console.log("BuyerSeller........>>>>",filterobj);
      if (response && response.data && response.data.success) {
        setBuyerSeller(response.data.list);
      }
    }


    const onLoadMore = () => {
      // Set_LoadDisable(true)
  
      CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
      setCatBasedTokenList(CatBasedTokenList);
  
      TokenListCall({
        page: CatBasedTokenList[CatName].page + 1
  
      });
      // Set_LoadDisable(false)
  
    }







  return (

    <div>
      <div className="home_header">


      <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        {/* <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={Wallet_Details.WalletConnected}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          AddressUserDetails={Wallet_Details.AddressUserDetails}
          // Accounts={Wallet_Details.Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        /> */}
        {/* <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal} 
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        /> */}
        {/* <WalletRef
          ref={WalletForwardRef}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountBal={Set_UserAccountBal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        /> */}
        <LikeRef

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
        // MyItemAccountAddr={MyItemAccountAddr}
        // WalletConnected={Wallet_Details.WalletConnected}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        {/* <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}


          // WalletConnected={Wallet_Details.WalletConnected}

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        <ReportNowRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        /> */}







        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><a href="https://edaface.com/"  target="_blank">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></a></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}

          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        {
          // (Promotion_Banner && Promotion_Banner.length > 0) &&
          <section className="banner_head">
            <div className="container-fluid container-theme container-lg">
              <div className="row">
              <div className="col-md-6 col-12">
                {/* <h2><span>Discover</span> Digital art & Collect NFTs</h2> */}
               {/* <p>EdaFace is a user interface aggregator that brings all the various functionalities of the crypto industry onto a single platform! EdaFace is a user interface aggregator that brings all the various functionalities of the crypto industry onto a single platform!</p> */}
               <div className="d-flex align-items-center justify-content-center">               
               <div className="homebnns">
               {ReactHtmlParser(getcmslistinhome12.answer)}
                </div>
                </div>
              </div>

{            
              <div className="col-md-6 col-12">
                <div className="baner_image">
                  {/* <div className="ethactiosban">
               
   {
                    (isEmpty(Promotion)===true) 
                    ? 
                    <img src={Bannerimg} className="img-fluid"/>
  
                    :

                    (
                      Promotion&&Promotion.map((Promotion_List)=>
                  
                      {
                        //console.log("aditionalimggg",Promotion_List,Promotion_List.image);
    return(
                    <Link to={Promotion_List?.tokenDetails?.tokenOwner&&"/info/" + Promotion_List.tokenDetails.tokenOwner+'/'+Promotion_List.tokenDetails.contractAddress+'/'+Promotion_List.tokenDetails.tokenCounts}>
                        <> {
                        
  
                          (Promotion_List.image && Promotion_List.image !== "" &&
                            (String(Promotion_List.image).split('.').pop() === "mp4") ||
                            (String(Promotion_List.image).split('.').pop() === "webm") ||
                            (String(Promotion_List.image).split('.').pop() === "WEBM") ||
                            (String(Promotion_List.image).split('.').pop() === "ogv") ||
                            (String(Promotion_List.image).split('.').pop() === "OGV")) &&
                          <video
                            id="my-video"
                            className="img-fluid"
                            autoPlay
                            playsInline
                            loop
                            muted
                            preload="auto"
                            alt="video"
                          // poster={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                          // data-setup="{}"
                          >
                            <source
                              src={Promotion_List.image ? (Promotion_List.image === "" ? `${config.IPFS_IMG}/${Promotion_List.ipfsimage}` : `${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${Promotion_List.image}`) : `${config.IPFS_IMG}/${Promotion_List.ipfsimage}`}
  
                              // src={Promotion_List.ipfsimage&&Promotion_List.ipfsimage!=""?`${config.IPFS_IMG}/${Promotion_List.ipfsimage}`:`${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${Promotion_List.image}`} 
                              type="video/mp4" />
                          </video>}
  
                          {
                            (Promotion_List.image && Promotion_List.image !== "" && (String(Promotion_List.image).split('.').pop() === "mp3" || String(Promotion_List.image).split('.').pop() === "aac" || String(Promotion_List.image).split('.').pop() === "AAC" || String(Promotion_List.image).split('.').pop() === "FLAC" || String(Promotion_List.image).split('.').pop() === "flac")) &&
  
                            <>
                              <img src={config.AudioImg} className="img-fluid" alt="text"/>
  
                              <audio controls controlsList="nodownload"
                                poster={require('../assets/images/audio.png')} muted
                                alt='audio'
                                className="img-fluid"
                                playsInline loop
                                type="audio/mp3"
                                autostart="off"
  
                                //  src={Promotion_List.ipfsimage&&Promotion_List.ipfsimage != "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${Promotion_List.image}`}
                                src={Promotion_List.image ? (Promotion_List.image === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${Promotion_List.image}`) : `${config.IPFS_IMG}/${Promotion_List.ipfsimage}`}
  
                              >
                              </audio>
                            </>
                          }
  
                          {
                            Promotion_List.image &&
                            Promotion_List.image !== "" && ((
                              String(Promotion_List.image).split('.').pop() === "webp"
                              || String(Promotion_List.image).split('.').pop() === "WEBP"
                              || String(Promotion_List.image).split('.').pop() === "gif"
                              || String(Promotion_List.image).split('.').pop() === "jpg"
                              || String(Promotion_List.image).split('.').pop() === "JPG"
                              || String(Promotion_List.image).split('.').pop() === "JPEG"
                              || String(Promotion_List.image).split('.').pop() === "jpeg"
                              || String(Promotion_List.image).split('.').pop() === "png"
                              || String(Promotion_List.image).split('.').pop() === "PNG"))
                            &&
                            <img
                              // src={Promotion_List.ipfsimage&&Promotion_List.ipfsimage!=""? `${config.IPFS_IMG}/${Promotion_List.ipfsimage}`:`${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${item.image}`}
                              src={Promotion_List.image ? (Promotion_List.image !== "" ?`${config.Back_URL}/nftImg/${Promotion_List.tokenCreator}/${Promotion_List.image}`:`${config.IPFS_IMG}/${Promotion_List.ipfsimage}`) : `${config.IPFS_IMG}/${Promotion_List.ipfsimage}`}
                              alt="Collections" className="img-fluid " />
                          }
  
                        </>
                      </Link>
                      
                        
                      ) }  )   )

                  }

                  </div> */}

{console.log('lskflskfs',imgpath)}
<div className={["mp4", "webm", "WEBM", "OGV", "ogv"].includes(imgfilename?.split('.')?.pop())? "ethactiosban ethaction_video ethactiosban_pos_rel_au" :"ethactiosban ethactiosban_pos_rel_au"}>
               
               {
                                (imgpath==="") 
                                ? 
                                <img src={Bannerimg} className="img-fluid"/>
              
                                :
                                    <> {
                                    
                                      imgfilename!==""&&
                                    (imgpath !== "" && ["mp4", "webm", "WEBM", "OGV", "ogv"].includes(imgfilename?.split('.')?.pop()) ?
                                    (<video
                                    id="imgPreview"
                                    className="img-fluid"
                                    alt="img"
                                    autoPlay
                                    controls
                                    muted
                                    playsInline
                                    loop
                                    src={imgpath}
                                    type="video/mp4"
                                    />)
                                    :
                                    null
                                    )}
              
              {imgpath!=""&&(imgpath.split('.').pop() === 'mp3')
    &&
    <>
      <img src={config.AudioImg} className="img-fluid" />

      <audio id="banner_audio" controls className="audio_pos">  
  <source src={imgpath} type="audio/ogg" />
  <source src={imgpath} type="audio/mpeg" />
</audio>
      {/* <audio
      id="imgPreview"
      
      alt="audio"
      playsInline
      loop
      muted
      controls
      type="audio/*"
      autostart="off"
        
      >
        <source src={imgpath} />
      </audio> */}
      
    </>

  }
              
                                      {
                                    (imgpath !== "" && ["webp", "WEBP", "gif", "jpg", "GIF", "JPG", "JPEG", "jpeg", "png", "PNG"].includes(imgfilename?.split('.')?.pop()) ?
                                    (<img src={imgpath} id="imgPreview" alt="Collections" className="img-fluid" />)
                                    :
                                    null
                                    )
                                      }
              
                                    </>
                                 
                                  
                                
            
                              }
            
                              </div>





{console.log("ZDhhhsfhbshb",Promotion,imgfilename)} 
               { 

               (Promotion.length >0)?
               Promotion&&Promotion.map((Promotion_List)=>
                  
               {
                 console.log("ZDhsfhbshb",Promotion_List);
return(
               (Promotion_List&&Promotion_List.tokenName)&&

               
               
               <div className="banercontent">
                 


                  <Link to={Promotion_List?.userProfile?.customurl !== "" ? `/${Promotion_List?.userProfile?.customurl}` : `/user/${Promotion_List?.userProfile?.curraddress}`}
                                 data-toggle="tooltip"
                                  data-placement="top" 
                                  title={`Creator : ${Promotion_List.userProfile.name !== "" ? Promotion_List.userProfile.name : Promotion_List.userProfile.curraddress}`}
                                  >
                                    {Promotion_List.userProfile.image !== "" ? <img src={`${config.Back_URL}/images/${Promotion_List.userProfile._id}/${Promotion_List.userProfile.image}`}  /> :
                                        <div >
                                           <img src={Profile} />

                                        </div>}

                                </Link>


                  <div className="banercontentDet">
                    <h2> {Promotion_List.tokenName}</h2>
                    <p>by {Promotion_List.userProfile.curraddress.slice(0,6).concat("....")}</p>
                    {/* <p>by John</p> */}
                  </div>
                  </div>
                  
                  ) }  )  
                  :
                  <div className="banercontent">
                 


                  <Link to={""}
                                 data-toggle="tooltip"
                                  data-placement="top" 
                                 // title={`Creator `}
                                  >
                                  
                                        <div >
                                           <img src={ promotiondetail?.Profile ? `${config.Back_URL}/newpromotion/${promotiondetail?.Profile}` : Profile} />

                                        </div>

                                </Link>


                  <div className="banercontentDet">
                    <h2> {promotiondetail?.TokenName ? promotiondetail?.TokenName : "NFT Marketplace Launching"}</h2>
                    <p>by {promotiondetail?.creator ? promotiondetail?.creator : "EdaFace" }</p>
                  </div>
                  </div>
                  }



                </div>
                
       
                </div>
                
   
        }
              </div>
            </div>
          </section>
        }

{(gallarycoll && gallarycoll.length > 0) &&
        <section className="bid_section section bid_section_section_1 hotcollect">
          <div className="container-fluid container-theme container-lg">
            <h2 className="mb-5 title_text_white mb-5 pb-5">Hot Collections</h2>

            <OwlCarousel className='owl-theme' {...options}>


            {gallarycoll.length > 0 &&
                gallarycoll.map((itemgallary) => {
                  //console.log("Sfjndghjmghm",itemgallary);
                  return (

                  <div className="hotcollect_body">
<Link to={'/collection/' + itemgallary.userAddress + '/' + itemgallary.customurl+'/'+itemgallary.collectionAddress}>

                  <>
                    
                    <div className="hotcollectimgsoruce">
                    {
                            (itemgallary.imageName === "" ||itemgallary.imageName === undefined)?
                            <img src={require("../assets/images/hotcollectionone.png")} class="hotcolectimg" alt="Shape" />
                            :
                            <img src={`${config.Back_URL}/collectionLogo/${itemgallary.userAddress}/${itemgallary.imageName}`} alt="Shape" className="hotcolectimg" />
                          }
                          {/* {
                            (itemgallary.imageName !== ""||itemgallary.imageName !== undefined) &&

                            <img src={`${config.Back_URL}/collectionLogo/${itemgallary.userAddress}/${itemgallary.imageName}`} alt="Shape" className="hotcolectimg" />

                          } */}
                    </div>
                    
                    <h2>{itemgallary.collectionName}</h2>
  
                  </>
                  </Link>
                </div>
                  )
                })}
              


            </OwlCarousel>

          </div>


        </section>
}
        {/* add class "ticket" for pink ticket image */}
        <section className="topcreators">
          <div className="container-fluid container-theme container-lg">
            <h2 className="mb-5 pb-5">Top Creators</h2>
            <div className="mt-5">
              
                <div className="topgrid">
                {topcreatorsection.length > 0 && topcreatorsection.map((item) => {
                  // //console.log("jfdxngjkdfngjkn", item)
                  return (
                <div className="topgridban">
                  <div>
                  <Link to={'/user/' + item.curraddress}>
                  {/* <img src={Profile} /> */}

                  {
                            item.image === "" &&
                            <img src={Profile}  />
                          }
                          {item.image !== "" &&

                            <img src={`${config.Back_URL}/images/${item._id}/${item.image}`}  />
                          }

                  </Link>
                  </div>
                  <div>
                      {/* <h3>lenin quies</h3> */}

                      {
                          item.name === "" &&<h3> {(item.curraddress).slice(0, 10).concat('.....')}</h3>}
                        {
                          item.name !== "" && <h3> {(item.name).slice(0, 10).concat('.....')}</h3>}

                      <p>{item.count} items</p>
                  </div>
                </div>
                  )
                })}
              </div>
              
            </div>
          </div>
        </section>

        <section className="liveauction carousel-nav pb-3">
          <div className="container-fluid container-theme container-lg">

            <div className="mt-3">
              <div className="">
                <div className="">
                  <div className="">
                    <div> <h2 className="section-head mb-5 pb-5">Live Auctions</h2></div>
                    <div className="m-0">
                    
                        <div className="">
                        <OwlCarousel className='owl-theme' {...liveauctions}>
                       
                        {(Time_Auction_List.map((item) => {
                          //console.log("Fgnhdfgnhhdfgsnf",Wallet_Details.UserAccountAddr);
                            return (
                              (isEmpty(item.tokenowners_current) !== true)?

                              <TokenCard
                            
                              item={item}
                              Set_item={Set_item}
                              LikedTokenList={LikedTokenList}
                              setLikedTokenList={setLikedTokenList}
                              hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                              // PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                              // PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                              // PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                              Set_Bids={Set_Bids}
                              Bids={item.myBid}
                              Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                              Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                              Set_MyTokenBalance={Set_MyTokenBalance}
                              Set_MyTokenDetail={Set_MyTokenDetail}
                              Set_AllowedQuantity={Set_AllowedQuantity}
                              Set_YouWillPay={Set_YouWillPay}
                              Set_YouWillPayFee={Set_YouWillPayFee}
                              Set_YouWillGet={Set_YouWillGet}
                              // Burn_Click={BurnForwardRef.current.Burn_Click}
                              // CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                              // SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                              // ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                              setConvertVal={setConvertVal}
                              convertVal={convertVal}
                              curraddress={Wallet_Details.UserAccountAddr!=""?Wallet_Details.UserAccountAddr:""}
                            />
                      : ("")
                      )
                    }))
                    }
                       </OwlCarousel>
                        </div>
                        
                    </div>

                  </div>

                </div>
              </div>


            </div>

          </div>
        </section>
      





        <section className="liveauction carousel-nav pb-3">
        <div className="container-fluid container-theme container-lg">

<div className="mt-3">
  <div className="">
    <div className="">
      <div className="">
        <div><h2 className="section-head mb-5 pb-5">Recently Added NFT</h2></div>
        <div className="m-0">
        
            <div className="auctions">

            {
            (CatBasedTokenList
                            && CatName
                            && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                            && CatBasedTokenList[CatName].list.length > 0)
                            ? (CatBasedTokenList[CatName].list.map((item) => {
                              return (
                                (isEmpty(item.tokenowners_current) !== true )?
                                <TokenCard
                                item={item}
                                Set_item={Set_item}
                                 LikedTokenList={LikedTokenList}
                                 setLikedTokenList={setLikedTokenList}
                                 hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                               
                                // PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                // PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                // PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                // Burn_Click={BurnForwardRef.current.Burn_Click}
                                // CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                // SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                // ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                                curraddress={Wallet_Details.UserAccountAddr!=""?Wallet_Details.UserAccountAddr:""}
                              />
              : ("")
              )
            })) :("")
          }
            </div>
            
        </div>

      </div>

    </div>
  </div>


</div>

</div>


          <div className="text-center mt-5 w-100 ">

            <div id="spinButs" className="overflow-hidden">
              <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore} disabled={LoadDisable} style={{ display: "flex" }}>
                Load More <span>

                </span>

              </Button>

            </div>
          </div>

        </section>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  )
}
