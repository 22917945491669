import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useParams } from "react-router-dom";
import Web3 from 'web3';
import Profile from 'assets/images/no_profile2.png'
import Select from 'react-select'
import { useSelector } from "react-redux";
import isEmpty from '../lib/isEmpty';
import '@metamask/legacy-web3';
import config from '../lib/config';
import { addUserCollection, CollectionValidationAction } from '../actions/v1/user'
import {
  getUserCollectiondata
} from '../actions/v1/token';
import { toast } from 'react-toastify';
import ERC721 from "../ABI/SINGLE"
import ERC1155 from "../ABI/MULTIPLE"


toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


const initialFormValue = {
  'userAddress': "",
  'collectionAddress': "",
  'collectionName': "",
  'customurl': "",
  'collectionType': '',
  'profileImg': '',
  'coverImg': ''
}



export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;



  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const history = useHistory();

  var { name, form } = useParams();

  const [validateError, setValidateError] = useState({});
  const [imageVal, setImageVal] = useState('')
  const [onchangeimg, setOnchangeimg] = useState('')
  const [coverval, setcoverval] = useState('')
  const [coverimg, setcoverimg] = useState('')
  const [formValue, setFormValue] = useState(initialFormValue);
  const [disablebtn, setDisablebtn] = useState(0)
  const [CategoryOption, setCategoryOption] = useState([{ value: 'SINGLE', label: 'Single / Poly-Multiple' }, { value: 'MULTIPLE', label: 'Multiple' }]);
  const [NFTType, setNFTType] = useState('')




  const {
    collectionName,
    customurl,
    collectionType,
   profileImg,
    coverImg
  } = formValue


  useEffect(() => {

    CollectionProfile();

  }, [Wallet_Details.UserAccountAddr])

  async function CollectionProfile() {



    // //console.log("fsdbghdf")
    let formData = {};
    var payload = {
      collectionName: name,
      from: "addCollection"
    }
    // //console.log("zdgzdhg", payload)
    var collectionInfo = await getUserCollectiondata(payload);
    // //console.log("aeryhstrfj", collectionInfo)
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {

      //console.log("Collection Informations", collectionInfo.data.data.collections);
      var collectionData = collectionInfo.data.data.collections
      collectionData.map((item) => {
        // //console.log("ADHFgsdfh", item)

        // formData['collectionAddress'] = item.userAddress;
        formData['collectionName'] = item.collectionName;
        formData['customurl'] = item.customurl;
        formData['collectionType'] = item.collectionType;
        //formData['profileImg'] = item.imageName;
        //formData['coverImg'] = item.coverimage; 
        formData['userAddress'] = Wallet_Details.UserAccountAddr;


        // setcoverimg(item.coverimage)
        setNFTType(item.collectionType)
        //console.log("zdfbsdfbdsz", item.imageName === null, item.coverimage === '');
        //console.log("dgfnsfgnjsgf",item.imageName,item.imageName != '' || item.imageName !== undefined);
        if (item.imageName != '' || item.imageName !== undefined) {
          var profileimage = config.Back_URL + '/collectionLogo/' + item.userAddress + '/' + item.imageName;
          setOnchangeimg(profileimage)
        }
        if (item.coverimage != '' || item.coverimage != undefined) {

          var coverimage = config.Back_URL + '/coverphoto/' + item.userAddress + '/' + item.coverimage;
          setcoverimg(coverimage)
        }
        setFormValue(formData)

      })
      // //console.log("fdsbhsfgnh", formData.collectionName)
    }
  }









  const handleFile1 = (event) => {
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setcoverval(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setcoverimg(reader.result);


        }
      }
    }
    //console.log("Dfbhrsfrtgjh11", id, files[0])
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    //console.log("Dfbhrsfrtgjh22", formData)
  }


  const handleFile = (event) => {
    setDisablebtn(0)
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    setDisablebtn(0)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setImageVal(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
          // setImageVal1(file.name)

        }
      }
    }
    //console.log("Dfbhrsfrtgjh", id, files[0], formValue)
    let formData = { ...formValue, ...{ [id]: files[0] } };
    // //console.log("Dfbhrsfrtgjh22", formData)
    setFormValue(formData);
  }

  const onChange = (e) => {
    setDisablebtn(0)
    const { id,value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const selectChange = (e) => {

    if (e && e.label && e.value) {
      
      const { id, value } = e
      let formData = { ...formValue, ...{ ['collectionType']: value } }
      //console.log("dhtsdrth", e)
      setFormValue(formData)


    }
  }


  const handleFormSubmit = async (e) => {
if(Wallet_Details.UserAccountAddr==="")
{
  toast.warn("please connect your wallet")
}
else{
  try{
  //console.log("Ghmjfghkjmfhj");
    const userAddress = Wallet_Details.UserAccountAddr
    const collectionType = NFTType
    let reqdata = {
      formValue,
      userAddress,
      Single:  Wallet_Details.networkConfiguration.singleContract,
      Multiple: Wallet_Details.networkConfiguration.multipleContract
    }
//console.log("gdfnjdfj",reqdata,form);
    var validate = await Validate(reqdata)
    //console.log("ghnjgfhkmjghj",validate);

    if (isEmpty(validate) == false) {
      setDisablebtn(1)
      toast.error("please fill the required field", toasterOption);
    }

    else if (form != "usercollection") {

      const resp = await CollectionValidationAction(reqdata)

      var errors = resp.data;
      var errorsSize = Object.keys(errors).length;
      if (errorsSize != 0 || errorsSize > 0) {
        setDisablebtn(1)
        setValidateError(errors);

      }

      else {
        setDisablebtn(3)
//console.log("GJrgtykhjyuk",reqdata);
        var responsedata = await addUserCollection(reqdata)
        //console.log("dfbgvsdfbsdfb",responsedata);
        if (responsedata) {
          setValidateError("");

          toast.success("Collection Added successfully", toasterOption)
          history.push("/my-items")

        }
      }

    }
    else if (form == "usercollection") {
      setDisablebtn(3)

      var responsedata = await addUserCollection(reqdata)
      if (responsedata) {
        setValidateError("");
        toast.success("collection Updated successfully", toasterOption)
        history.push("/create")
      }
    }
  }
  catch(err)
  {
    //console.log("gfnjfghnfghm",err);
  }
  }
  }

  const Validate = async (data) => {

    var validateError = {};

    let imageSize = 5000000;

    // let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;


    if (onchangeimg != "") {
      //console.log("epwopeopwope", data.formValue.coverImg, onchangeimg, onchangeimg.split('.').pop())
      if (data.formValue.profileImg != "" || data.formValue.profileImg != undefined) {
        if (imageSize < (data?.formValue?.profileImg)?.size) {
          validateError.image = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data?.formValue?.profileImg)?.name)) {
          validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if (coverimg != "") {
      //console.log("epwopeoerhtergpwope", data.formValue.coverImg, coverimg, coverimg.split('.').pop())
      if (data.formValue.coverImg != "" || data.formValue.coverImg != undefined) {
        if (imageSize < (data.formValue.coverImg).size) {
          validateError.image1 = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.formValue.coverImg).name)) {
          validateError.image1 = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if ((data.formValue.collectionName) === '') {
      validateError.names = "Collection Name is Required"
    }
    if ((data?.formValue?.collectionName) === '') {
      validateError.names = "Collection Name is Required"
    }
    if (data?.formValue?.customurl === '') {
      validateError.customurl = "Custom url is Required"
    }

    if (data?.formValue?.collectionType === '') {
      validateError.Category = "Category is Required"
    }
    //console.log("ghmjghkmghjkm,hg",data?.formValue?.collectionAddress,Wallet_Details,data?.formValue?.collectionType);
    if(data?.formValue?.collectionAddress)
    {
      try{
const web3 = new Web3(Wallet_Details.networkConfiguration.BNBPROVIDER)
    var ConnectContract = await new web3.eth.Contract(
      Number(data?.formValue?.collectionType)=="SINGLE" ? ERC721 : ERC1155,data?.formValue?.collectionAddress
  );

    //console.log('coooonnnnn',ConnectContract)
    var contractowner = await ConnectContract.methods.owner().call()



    //console.log("ghmfghmfhmfhmj",contractowner.toLowerCase() , Wallet_Details.UserAccountAddr.toLowerCase(),contractowner.toLowerCase() !== Wallet_Details.UserAccountAddr.toLowerCase());
    if(String(contractowner.toLowerCase()) !== String(Wallet_Details.UserAccountAddr.toLowerCase())){
      validateError.CollectionContractAddress = "Owner Address is InValid"
    }
  }catch(err)
  {
    //console.log("fgbnhdfgnhbdf",err);
  }
  }
    //console.log("validateError", validateError);
    setValidateError(validateError)
    return validateError;
  }



  return (
    <div className="inner_header collection">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Add Collections</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <h2 className="user_profileim">Profile</h2>
              <div className="holder">
                <div className="profile_edit_panel">
                {onchangeimg === '' &&
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  }
                  {onchangeimg != '' &&
                    <img src={onchangeimg ? onchangeimg : null} alt={onchangeimg ? onchangeimg.name : null} id="imgPreview" className="img-fluid" />
                  }
                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input
                    type="file"
                    name="photo"
                    id="profileImg"
                    required="true"
                    className="photo"
                    onChange={(e) => handleFile(e)}
                  />
                {validateError.image && <span className="text-danger">{validateError.image}</span>}
                </div>


              </div>
              <div className="mt-3">
                <h2 className="user_profileim">Cover Image</h2>
                <div className="profile_banner">
               
                    {/* <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" /> */}
                  
                    {coverimg === '' &&
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  }
                  {coverimg != '' &&
                    <img src={coverimg ? coverimg : null} alt={coverimg ? coverimg.name : null} id="imgPreview" className="img-fluid" />
                  }


                  <div className="profile_edit_banner">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    id="coverImg"
                    className="photo"
                    onChange={(e) => handleFile1(e)}
                  
                  />
                   {validateError.image1 && <span className="text-danger">{validateError.image1}</span>}
                </div>


              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                {
                    (form != "usercollection") &&
                 
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="name">Collection Name</label>
                      <input type="text"
                       
                        className="form-control primary_inp"
                        id="collectionName"
                        placeholder="Enter your Collection Name"
                        onChange={onChange}
                        />
                   {validateError.names && <span className="text-danger">{validateError.names}</span>}
                      {validateError.collectionName && <span className="text-danger">{validateError.collectionName}</span>}
                    </div>
                  
                }
                 {  (form != "usercollection") &&
                    <div className="form-group col-md-6">

                      <label className="primary_label" htmlFor="collectionType">Category</label>
                      <Select
                      
                        className="form-control primary_inp typecontrol"
                        onChange={selectChange}
                        name="collectionType"
                        options={CategoryOption}
                        maxMenuHeight={220}
                     
                        label="Select or type name"
                        placeholder="Select or type name"
                      />
                  {validateError.Category && <span className="text-danger"><br />{validateError.Category}</span>}

                    </div>
                  
                 }


                 {
 (form != "usercollection") &&
<div className="form-group col-md-6">
<label className="primary_label" htmlFor="collectionAddress">Collection Address   (optional)</label>
<input type="text"
  className="form-control primary_inp"
  id="collectionAddress"
  placeholder="Enter your Collection Address"
  onChange={onChange} />
{validateError.CollectionContractAddress && <span className="text-danger">{validateError.CollectionContractAddress}</span>}

</div>
    }
          
{  (form != "usercollection") &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="customurl">Custom Url</label>
                      <input type="text"
                        value={customurl}
                        className="form-control primary_inp"
                        id="customurl"
                        onChange={onChange}
                        placeholder="Enter your Custom Url"
                     />
                       {validateError.customurl && <span className="text-danger">{validateError.customurl}</span>}

                      
                    </div>
                  
}

                  <GridItem xs={12} sm={3} md={3}>
                  </GridItem>
                </div>
                <div className="mt-3">
         
                    {/* <Button className="create_btn" >Add Collections</Button> */}
                    {
                    (form != "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Add Collections</Button>
                  }
                  {
                    (form == "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Update 
                    </Button>
                  }
               
          
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
