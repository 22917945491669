import React, {
    useEffect,
    forwardRef,
    useState,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button} from '@material-ui/core';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import SINGLE from '../../ABI/SINGLE.json';
import MULTIPLE from '../../ABI/MULTIPLE.json';
import config from '../../lib/config';

import {

    Transfer_Complete_Action,

    checkOtherPlatformDetais1155
} from '../../actions/v1/token';

import { toast } from 'react-toastify';
import isEmpty from 'lib/isEmpty';
import {getReceipt} from '../../actions/v1/getReceiptFunc'

import { useSelector, useDispatch } from "react-redux";
import { network } from "../../views/network"
import { connectWallet, WalletConnect } from '../../views/hooks/useWallet';
import { Account_Connect, Account_disConnect } from "actions/redux/action";

    toast.configure();
    let toasterOption = config.toasterOption;
    

    
    export const TransferRef = forwardRef((props, ref) => {
    
        const dispatch = useDispatch();


        const history = useHistory();

        const Wallet_Details = useSelector(state => state.wallet_connect_context);
        const [FormSubmitLoading, Set_FormSubmitLoading] = useState('init');
        const [ValidateError, Set_ValidateError] = useState({});
        const [TokenPrice, Set_TokenPrice] = useState(0);
        const [New_TokenOwners, Set_TokenOnwers] = useState("");
        const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
        const [Owner_Details, Set_Owner_Details] = React.useState({});
        const [NoOFItems, Set_NoOFItems] = React.useState(1);
        const [items, set_item] = React.useState({});
        const [selectednet, set_selectednet] = React.useState(0);

  
        const inputChange = (e) => {
            if(e && e.target && e.target.name) {
              var value = e.target.value;
              switch(e.target.name) {
                case 'TokenPrice':
                    if(String(value).toLowerCase().split('Edaface')[0] == ''){
                        if(String(value).toLowerCase().split('Edaface').length == 2){
                            Set_TokenOnwers(String('0x'+String(value).toLowerCase().split('Edaface')[1]))
                        }
                        ItemValidation({New_TokenOwners:value});
                    }
                    else{
                        Set_TokenOnwers(String(value).toLowerCase());
                        ItemValidation({New_TokenOwners:value});
                    }
                  break;
                  case 'NoOFItems':
                    Set_NoOFItems(value);
                    ItemValidation({NoOFItems:value});
                  break;
                default:
                    
                  // code block
              }
            }
        }
    
        const ItemValidation = async (data={}) => {
            var ValidateError = {};
    
            var Chk_TokenOwners = (typeof data.New_TokenOwners!='undefined')?data.New_TokenOwners:New_TokenOwners;
        
            if(Chk_TokenOwners === '') {
                ValidateError.New_TokenOwners = '"Token Owner" is not allowed to be empty';
            }
            else if(data.NoOFItems === '') {
                ValidateError.NoOFItems = '"No of items " is not allowed to be empty';
            }
            else if(data.NoOFItems === 0) {
                ValidateError.NoOFItems = '"No of items" is not allowed to be zero';
            }
            else if(data.NoOFItems > Owner_Details.balance) {
                ValidateError.NoOFItems = '"No of items" is not greater than balance';
            }
            else {
                    delete ValidateError.New_TokenOwners;
                    Set_FormSubmitLoading('start')   
            }
            Set_ValidateError(ValidateError);
            return ValidateError;
        }
    
        async function FormSubmit(){
            Set_FormSubmitLoading('start');
            var errors = await ItemValidation();
            var errorsSize = Object.keys(errors).length;
            if(errorsSize !== 0) {
                Set_FormSubmitLoading('error');
                toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
                return false;
            }
    
            var receipt=null;
            var handle=null;
            var CoursetroContract = null;
            if(Wallet_Details.UserAccountAddr!=="") {
                var web3 = new Web3(Wallet_Details.providerss)
                if( web3 ) {
             
                try{
                        Set_FormSubmitLoading('processing');
                if(items.type===721){
                         CoursetroContract = new web3.eth.Contract(
                            SINGLE,
                                items.contractAddress
                            );
                            //item.ContractAddress,item.ContractType,Quantity,Address,owner.TokenId
                            await CoursetroContract.methods
                            .TransferNFT(
                               // Wallet_Details.UserAccountAddr,
                                New_TokenOwners,
                                items.tokenCounts,
                            )
                            .send({ from: Wallet_Details.UserAccountAddr })
                            .on('transactionHash',async (transactionHash) => {
                                handle = setInterval(async () => {
                                    receipt = await getReceipt(web3, transactionHash)
                                    clr1();
                                  }, 8000)
                            })
                }
                else{
                         CoursetroContract = new web3.eth.Contract(
                                MULTIPLE,
                                items.contractAddress
                            );
                           await CoursetroContract.methods
                            .TransferNFT(
                            //Wallet_Details.UserAccountAddr,
                              New_TokenOwners,
                              items.tokenCounts,
                              NoOFItems 
                              )
                            .send({ from: Wallet_Details.UserAccountAddr})
                            .on('transactionHash',async (transactionHash) => {
                                handle = setInterval(async () => {
                                    receipt = await getReceipt(web3, transactionHash)
                                    clr1();
                                  }, 8000)
                            })
                }
                
                }
          
                catch(e){
                    Set_FormSubmitLoading('try');
                    //console.log('error : ', e);
                    toast.error('Order not placed', toasterOption)
                }
                
               
                async function clr1(){
       
                    if(receipt!==null){
                        clearInterval(handle);
                        if(receipt.status===true){   
                        Set_FormSubmitLoading('done');
                        //////console.log('result : ', result);
                        var postData = {
                            tokenOwner: Owner_Details.tokenOwner, // old owner
                            UserAccountAddr: New_TokenOwners, // new owner
                            tokenCounts: items.tokenCounts,
                            tokenType: items.type,
                            NoOfToken:  NoOFItems,
                            transactionHash: receipt.transactionHash,
                            tokenBid:true,
                            tokenPrice:Owner_Details.previousPrice,
                            contractAddress:Owner_Details.contractAddress,
                            tokenCreator:Owner_Details.tokenCreator,
                            SelectedNetwork :selectednet
                        }
                        var Resp = await Transfer_Complete_Action(postData);
                        if (Resp.data) {
                             toast.success("Transferred  successfully", toasterOption)
                            window.$('#Transfer_modal').modal('hide');
                            setTimeout(() => { history.push("/my-items"); }, 8000);
                        }
                                    
                }  }}
            }
        }
    }
    
        var {
            item,
            UserAccountAddr,
            UserAccountBal
        } = props;
    
        useEffect(() => {
            Set_ValidateError({});
        }, []);
    
//         useImperativeHandle(
//             ref,
//             () => ({
//                 async Transfer_Click(item,ownerdetail) {
                   
// var chainid =item.tokenowners_current[0].SelectedNetwork

//                     var accountDetailsss=''
//                     //console.log("dfbhsfdbn",item,Wallet_Details.networkConfiguration.Chainid,item.tokenowners_current[0].SelectedNetwork);
                    
//                     if(Wallet_Details.networkConfiguration.Chainid==item.tokenowners_current[0].SelectedNetwork)
//                     {
//                         accountDetailsss=true
//                     }
//                     else{
//                         var accountDetailsss =await  switchNetwork(chainid)
//                     }
        
//     //  setTimeout(async() => {
       
        
//         if(chainid === Wallet_Details.networkConfiguration.Chainid)
//         {
            // set_selectednet(Wallet_Details.networkConfiguration.Chainid)


//                 if (Wallet_Details.UserAccountAddr!=="") {
//                     var web3=new Web3(Wallet_Details.providerss)
//                     var data = {
//                         Singleaddress: Wallet_Details.networkConfiguration.singleContract,
//                         multipleContract: Wallet_Details.networkConfiguration.multipleContract
//                       }
//                     var balance = await checkOtherPlatformDetais1155(item,ownerdetail,item.type,web3,data);
//                     //console.log('acbalanadvcce>>csd>>>>>c>',balance)
//                     if (balance === 0) {
//                         toast.warning("You won't buy at this moment please refresh you data",toasterOption);
//                         setTimeout(() => {
//                         history.push("/")
//                         }, 1000);
//                         return false;
//                     }
//                     else{
//                     props.Set_HitItem(item);
//                     set_item(item)
//                     Set_TokenPrice(ownerdetail.tokenPrice);
//                     Set_TokenPrice_Initial(ownerdetail.tokenPrice);
//                     Set_Owner_Details(ownerdetail)
//                     Set_ValidateError({});


//                     // const SetApproveStatus = async (data,Addr) => {
//                     //     try {
//                     //         var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155,Addr)
//                     //         var contract_Method_Hash = await
//                     //             ConnectContract
//                     //                 .methods
//                     //                 .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
//                     //                 .send({
//                     //                     from: accountAddress
//                     //                 }).on('transactionHash', (transactionHash) => {
//                     //                     return transactionHash
//                     //                 })
//                     //         const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
//                     //         var need_data = {
//                     //             status: receipt.status,
//                     //             HashValue: receipt.transactionHash,
//                     //         }
//                     //         return need_data;
                
//                     //     }
//                     //     catch (e) {
//                     //         //console(data, e)
//                     //         return false
//                     //     }
//                     // }

            
                
// //                         try {
                           
// //                             var ConnectContract = await new web3.eth.Contract(item.type == '721' ? SINGLE : MULTIPLE,item.contractAddress)
// //                             var contract_Method_Hash = await
// //                                 ConnectContract
// //                                     .methods
// //                                     .isApprovedForAll(Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract)
// //                                     .call()
// //                             //console.log("contract_Method_Hash",contract_Method_Hash)
                
// //                         }
// //                         catch (e) {
// //                      //console.log("Sdgfbsfgbn",e);
// //                         }
                    
// // if(contract_Method_Hash === true)
// // {
//                     window.$('#Transfer_modal').modal('show')
                    
// // }        
// // else{
// //     // window.$('#price_item_modal').modal('show');
// //    var status = await ApproveCall(item,ownerdetail)
// //    //console.log("fbnsfgn",status);
// // }
                    
//                     ;}}
//                     else{
//                         window.$('#connect_modal').modal('show');
//                     }
//                 }

//     //  }, 1000);
//                 }
//             }),

            
//         )

useImperativeHandle(
    ref,
    () => ({
        async Transfer_Click(item,ownerdetail) {
           
var chainid =item.tokenowners_current[0].SelectedNetwork

            var accountDetailsss=''
            //console.log("dfbhsfdbn",item,Wallet_Details.networkConfiguration.Chainid,item.tokenowners_current[0].SelectedNetwork);
            
            if(Wallet_Details.networkConfiguration.Chainid==item.tokenowners_current[0].SelectedNetwork)
            {
                accountDetailsss=true
            }
            else{
                var accountDetailsss =await  switchNetwork(chainid)
            }

//  setTimeout(async() => {


if(chainid === Wallet_Details.networkConfiguration.Chainid)
{

    set_selectednet(Wallet_Details.networkConfiguration.Chainid)


        if (Wallet_Details.UserAccountAddr!=="") {
            var web3=new Web3(Wallet_Details.providerss)
            var data = {
                Singleaddress: Wallet_Details.networkConfiguration.singleContract,
                multipleContract: Wallet_Details.networkConfiguration.multipleContract
              }
            var balance = await checkOtherPlatformDetais1155(item,ownerdetail,item.type,web3,data);
            //console.log('acbalanadvcce>>csd>>>>>c>',balance)
            if (balance === 0) {
                toast.warning("You won't buy at this moment please refresh you data",toasterOption);
                setTimeout(() => {
                history.push("/")
                }, 1000);
                return false;
            }
            else{
            props.Set_HitItem(item);
            set_item(item)
            Set_TokenPrice(ownerdetail.tokenPrice);
            Set_TokenPrice_Initial(ownerdetail.tokenPrice);
            Set_Owner_Details(ownerdetail)
            Set_ValidateError({});


            // const SetApproveStatus = async (data,Addr) => {
            //     try {
            //         var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155,Addr)
            //         var contract_Method_Hash = await
            //             ConnectContract
            //                 .methods
            //                 .setApprovalForAll(Wallet_Details.networkConfiguration.TradeContract, true)
            //                 .send({
            //                     from: accountAddress
            //                 }).on('transactionHash', (transactionHash) => {
            //                     return transactionHash
            //                 })
            //         const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            //         var need_data = {
            //             status: receipt.status,
            //             HashValue: receipt.transactionHash,
            //         }
            //         return need_data;
        
            //     }
            //     catch (e) {
            //         //console(data, e)
            //         return false
            //     }
            // }

    
        
                try {
                   
                    var ConnectContract = await new web3.eth.Contract(item.type == '721' ? SINGLE : MULTIPLE,item.contractAddress)
                    var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .isApprovedForAll(Wallet_Details.UserAccountAddr, Wallet_Details.networkConfiguration.TradeContract)
                            .call()
                    //console.log("contract_Method_Hash",contract_Method_Hash)
        
                }
                catch (e) {
             //console.log("Sdgfbsfgbn",e);
                }
            
if(contract_Method_Hash === true)
{
            window.$('#Transfer_modal').modal('show')
            
}        
else{
// window.$('#price_item_modal').modal('show');
var status = await ApproveCall(item,ownerdetail)
//console.log("fbnsfgn",status);
}
            
            ;}}
            else{
                window.$('#connect_modal').modal('show');
            }
        }

//  }, 1000);
        }
    }),

    
)


        async function ApproveCall(items,ownerdetail) {
            var receiptt = null;
            var handlee = null;
            if (Wallet_Details.providerss == null) {
                toast.warning("OOPS!..connect Your Wallet", toasterOption);
                return false;
            }
            var web3 = new Web3(Wallet_Details.providerss);
            var currAddr = Wallet_Details.UserAccountAddr
    
            if (currAddr === "") {
                toast.warning("OOPS!..connect Your Wallet", toasterOption);
                return false;
            }
            // setApproveCallStatus('processing');
            try {
                var MultiContract = new web3.eth.Contract(
                    (items.type === 721 ? SINGLE : MULTIPLE),
                    items.contractAddress
                );
                //console.log("dfbhadfbhadfsbh",MultiContract,Wallet_Details.networkConfiguration.TradeContract,ownerdetail.tokenOwner);
                await MultiContract.methods.setApprovalForAll(
                    Wallet_Details.networkConfiguration.TradeContract,
                    true
                ).send({
                    from: ownerdetail.tokenOwner,
                }).on('transactionHash', async (transactionHash) => {
                    if (transactionHash !== "") {
                        handlee = setInterval(async () => {
                            receiptt = await getReceipt(web3, transactionHash)
                            clr();
                            //return receiptt

                        }, 8000)
                    }
                })
            }
            catch (error) {
                //console.log("FSnhbsfgnsfh",error);
                toast.error("Approve failed", toasterOption);
                //setApproveCallStatus('tryagain');
            }
            async function clr() {
                if (receiptt != null) {
                  //  clearInterval(handlee)
                    window.$('#Transfer_modal').modal('show')

                    // toast.success("Approve Successfully", toasterOption);
                   // setApproveCallStatus('done');
                }
            }
        }
    

        async function switchNetwork(configdata)
        {
          var type = ""
        var networkConfiguration ={}
        if(configdata)
        {
            if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {
        
              type = "MetaMask"
            }
            else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
              type = "WalletConnect"
            }
        
         window.$('#connect_modal').modal('hide');
            //console.log("connecttype............", type)
            var accountDetails = await connectWallet(type, configdata.Chainid)
            //console.log("accountDetailsin create page......................", accountDetails)
            //  const id=toast.loading("Wallet Connecting...")
         var web3 = new Web3(window.ethereum);
          //console.log("dfghrtfh",web3);
          if(window.ethereum.isMetaMask == true){
            const chainId = await web3.eth.getChainId();
            //console.log("fghdtgj",chainId);
        
            if(chainId===network.ETH.Chainid)
            {
                  networkConfiguration=network.ETH
            }
            else if(chainId===network.BSC.Chainid){
              networkConfiguration=network.BSC
            }
        
        
          }
         // setPriceoption()
        
            if (accountDetails != '') {
              //console.log("Wallet Connecting...increate", accountDetails.web3._provider);
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  UserAccountAddr: accountDetails.accountAddress,
                  UserAccountBal: accountDetails.coinBalance,
                  WalletConnected: "true",
                  Wen_Bln: accountDetails.tokenBalance,
                  Accounts: accountDetails.accountAddress,
                  providerss: accountDetails.web3._provider,
                  networkConfiguration:networkConfiguration
                }
              })
            }
          }
        
        
        
          //   var ReqData = {
          //     addr: String(accountDetails.accountAddress).toLowerCase()
          // }
          // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
          // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
          //     return Resp.data.data.User
          // } else {
          //     return null
          // }
        
        
        
        
        
        return accountDetails
        }

        return (
            <div className="modal fade primary_modal" id="Transfer_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="accept_modalLabel">Transfer Token</h5>

                            <button type="button" className="close"  data-bs-dismiss="modal" aria-label="Close" id="close9">

                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <div className="img_accept text-center">
                            {
                                item && item.image && (String(item.image).split('.').pop() === "mp4"||
                                (String(item.image).split('.').pop() === "webm")||
                                (String(item.image).split('.').pop() === "WEBM")||
                                (String(item.image).split('.').pop() === "ogv")||
                                (String(item.image).split('.').pop() === "OGV")
                                ) &&
                                <video
                                id="my-video"
                                className="img-fluid accept_img"
                                autoPlay muted playsInline loop
                                preload="auto"
                           
                                >   
                                <source 
                                src={item.additionalImage?(item.additionalImage===""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`):`${config.IPFS_IMG}/${item.ipfsimage}`}
                                
                        
                                 type="video/mp4" />
                                </video>
                                }
 {
                            item.image!==""&&(String(item.image).split('.').pop() === "mp3"||String(item.image).split('.').pop() === "aac"||String(item.image).split('.').pop() === "AAC"||String(item.image).split('.').pop() === "FLAC"||String(item.image).split('.').pop() === "flac") &&
 <>
                            <img src={config.AudioImg}   className="img-fluid"/>
                            <audio controls controlsList="nodownload"
                             poster={'assets/images/audio.png'}        
                              alt='audio'
                               playsInline loop muted
                               type="audio/mp3"
                               autostart="off"
         
                               src={(item.additionalImage?(item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`): `${config.IPFS_IMG}/${item.ipfsimage}`)}
                             >
                             </audio></>
                             }
                          {item && item.image && ( String(item.image).split('.').pop() === "webp"||String(item.image).split('.').pop() === "WEBP"||String(item.image).split('.').pop() === "jpg"||String(item.image).split('.').pop() === "JPG"||String(item.image).split('.').pop() === "jpeg"||String(item.image).split('.').pop() === "JPEG"||String(item.image).split('.').pop() === "png"||String(item.image).split('.').pop() === "PNG") &&
                           
                           <img
                           src={item.additionalImage?(item.additionalImage===""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`): `${config.IPFS_IMG}/${item.ipfsimage}`} 
                            
                           // src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} 
                           alt="Collections" className="img-fluid accept_img" />
                           }
                           
                            </div>
                            <p className="text-center accept_desc px-3" >
                                <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Place Order for</span>
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break">name</span>
                                <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} >for</span><br />
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                   
                                    {/* <span className="word_brak_text_inline_new">name</span>
                                     <span className="word_brak_text_inline_new">owner</span> */}
                                          <span className="word_brak_text_inline_new">{!isEmpty(item.tokenOwnersInfo)&&item.tokenOwnersInfo.name!==""&&item.tokenOwnersInfo.name}</span>
                                    {!isEmpty(Owner_Details) && <span className="word_brak_text_inline_new">{Owner_Details.tokenOwner}</span>}
                           
                                </span>
                            </p>
                            <form className="bid_form" action="#">
                                <div className="bor_bot_modal mb-3 px-4 ">
                                    <div className="mx-0 pb-3"></div>
                                    <label htmlFor="qty">Enter Reciever Address</label>
                                    <div className="mb-3 input_grp_style_1">
                                        <input
                                            type="text"
                                            className="form-control primary_inp text-center"
                                            name="TokenPrice"
                                            id="TokenPrice"
                                            onChange={inputChange}
                                            placeholder="0x0..."
                                            autoComplete="off"
                                        />
                                         {ValidateError.New_TokenOwners && <span className="text-danger">{ValidateError.New_TokenOwners}</span>}
                                    </div>
                                </div>
                               { Owner_Details.balance!==1 &&<div className="bor_bot_modal mb-3 px-4 ">
                                    <div className="mx-0 pb-3"></div>
                                    <label htmlFor="qty">Enter No Of Tokens</label>
                                    <div className="mb-3 input_grp_style_1">
                                        <input
                                            type="text"
                                            className="form-control primary_inp text-center"
                                            name="NoOFItems"
                                            id="NoOFItems"
                                            onChange={inputChange}
                                            placeholder="0"
                                            autoComplete="off"
                                        />
                                            {ValidateError.NoOFItems && <span className="text-danger">{ValidateError.NoOFItems}</span>}
                                    </div>
                                </div>}
                            </form>
                           
                           
                            <form className="px-4">
                                <div className="text-center">
                                    <Button
                                        type="button"
                                        className="create_btn btn-block"  
                                        onClick={(FormSubmitLoading==='start'||FormSubmitLoading==='try')&&(() => FormSubmit())}
                                        disabled={(FormSubmitLoading==='processing'||FormSubmitLoading==='done'||FormSubmitLoading==='init'||FormSubmitLoading==='error')}
                                    >
                                        {FormSubmitLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                       {FormSubmitLoading === 'processing'&&'In-Progress'} 
                                       {FormSubmitLoading === 'init'&&'Start'} 
                                       {FormSubmitLoading === 'start'&&'Start'} 
                                       {FormSubmitLoading === 'done'&&'Done'} 
                                       {FormSubmitLoading === 'try'&&'Try-Again'} 
                                       {FormSubmitLoading === 'error'&&'Error in Entered Price'}  
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    
    